import { useMediaQuery } from "@mui/material";
import { useMemo, useState } from "react";
import useSWR from "swr";
import { convertTblFromData, convertTickerTblFromData } from ".";
import * as gtag from "../../lib/google/gtag";
import get5YPriceData from "../../lib/stock/_get5YPriceData";
import getStockTabs from "../../lib/stock/_getStockTabsData";
import chart from "../api/decorators/chart/chart";
import { dataConstants } from "../constants";
import { roundNum } from "../helpers";
import { formatCount } from "./D3/helper";

const toggleFullscreen = (el) => {
  if (!document.fullscreenElement) {
    el.requestFullscreen().catch((err) => {
      alert(`Error attempting to enable fullscreen mode: ${err.message} (${err.name})`);
    });
  } else {
    document.exitFullscreen();
  }
};


export const viewFullScreen = (selector) => {
  toggleFullscreen(document.querySelector(selector));
};

// export const fullScreenThis = (selector) => {
//   let requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullscreen || docEl.msRequestFullscreen;
//   let exitFullscreen = docEl.exitFullscreen || docEl.mozCancelFullScreen || docEl.webkitCancelFullScreen || docEl.msExitFullscreen;
//   if (!requestFullScreen) {
//     requestFullScreen.call(docEl)
//   } else {
//     document.exitFullscreen()
//   }
// };

// export const viewFullScreen = (selector) => {
//   toggleFullscreen(document.querySelector(selector));
// };

const baseValue = {};

const settings = (baseline = baseValue, payload = {}) => ({
  ...baseline,
  ...payload,
});


export const getTheme = (baseline = baseValue, payload = {}) => {
  const { type, chart_settings, series_settings = {}, icon } = payload || {};

  const { pallete, gradient } = payload.pallete ? JSON.parse(payload.pallete) : {};

  return {
    type,
    chartSettings: settings(undefined, JSON.parse(chart_settings)),
    pallete,
    gradient,
    seriesSettings: JSON.parse(series_settings),
    icon,
  };
};

export const getMetrics = (baseline = baseValue, payload = {}) => {
  const { data } = payload || {};
  const parseMetrics = (baseline = baseValue, payload = {}) => {
    const { c, r, v } = payload || {};
    return {
      code: c,
      recordDate: r,
      value: v,
    };

  };
  return {
    ...payload,
    data: data && data?.length > 0 ? data?.map(d => parseMetrics(undefined, d)) : undefined,
  };
};
// switch (d.type) {
//   case dataConstants.STOCK_SECTION_TYPES.CHART:
//     return d.tabData && d.tabData.length > 0 ? chart(undefined, d.tabData[0]).getChart : undefined;
//   case dataConstants.STOCK_SECTION_TYPES.TICKER_TABLE:
//     return d.tabData && d.tabData.length > 0 ? convertTickerTblFromData({ ...d.tabData[0], tickers: d.tabData[0].tickers ? JSON.parse(d.tabData[0].tickers) : d.tabData[0].tickers }) : undefined;
//   case dataConstants.STOCK_SECTION_TYPES.TABLE_DATA:
//     const tabData = d.tabData && d.tabData.length > 0 ? convertTblFromData(d.tabData) : undefined;
//     return tabData;
//   default:
//     return d.tabData;
// }
export const getSeries = (baseline = baseValue, payload = {}, type, crv_code) => {
  if (payload.seq) {
    const { c, seq, v, d } = payload || {};
    return {
      code: c,
      seq: seq,
      value: v,
      desc: d,
    };
  } else {
    switch (type) {
      case "Stock_Data":
        return {
          code: payload?.c,
          price: payload?.p,
          record_date: payload?.r,
          rn: payload?.rn,
          type: "Stock_Data",
        };
      case "Series_Data":
        return {
          code: payload?.c,
          value: payload?.v,
          recordDate: payload?.r,
        };
      case "Price_Data":
        return {
          code: payload?.c,
          value: payload?.p,
          recordDate: payload?.r,
          rn: payload?.rn,
        };;
      case "stock_data":
        const { rn } = payload || {};
        return {
          code: payload?.c || crv_code,
          value: payload?.v,
          recordDate: payload?.r,
          rn,
        };;
      default:
        return {
          code: payload?.c,
          value: payload?.v,
          recordDate: payload?.r,
          desc: payload?.desc,
        };;
    }
  }
};

// export const getSeries = (baseline = baseValue, payload = {}, type, crv_code) => {
//   if (payload.seq) {
//     const { c, seq, v, d } = payload || {};
//     return {
//       code: c,
//       seq: seq,
//       value: v,
//       desc: d,
//     };
//   } else if (type === "Stock_Data") {
//     const { c, p, r, rn } = payload || {};
//     return {
//       code: c,
//       price: p,
//       record_date: r,
//       rn: rn,
//       type: "Stock_Data",
//     };
//   } else if (type === "Series_Data") {
//     const { c, v, r } = payload || {};
//     return {
//       code: c,
//       value: v,
//       recordDate: r,
//     };
//   } else if (type === "Price_Data") {
//     const { c, p, r, rn } = payload || {};
//     return {
//       code: c,
//       value: p,
//       recordDate: r,
//       rn: rn,
//     };
//   } else if (type === "stock_data") {
//     const { v, r, rn } = payload || {};
//     return {
//       code: payload?.c || crv_code,
//       value: v,
//       recordDate: r,
//       rn,
//     };
//   } else {
//     const { c, r, v, desc } = payload || {};
//     return {
//       code: c,
//       value: v,
//       recordDate: r,
//       desc: desc,
//     };
//   }
// };

export const getURL = () => {
  const url =
    process?.env?.NEXT_PUBLIC_URL && process.env.NEXT_PUBLIC_URL !== ''
      ? process.env.NEXT_PUBLIC_URL
      : 'https://www.grufity.com';
  return url.includes('https') ? url : `https://${url}`;
};

export const postData = async ({ url, stripe_customer_id }) => {
  const res = await fetch(url, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'same-origin',
    body: JSON.stringify({ stripe_customer_id: stripe_customer_id })
  });
  if (!res.ok) {
    // console.log('Error in postData', { url, stripe_customer_id, res });
    throw Error(res.statusText);
  }
  if (res.ok) {
    gtag.event({
      action: "manage_subscription",
      customer_id: stripe_customer_id || '',
    });
  }
  return res.json();
};

export const createCheckout = async ({ url, priceID, email, coupon, oldUser }) => {
  const res = await fetch(url, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'same-origin',
    body: JSON.stringify({ priceID: priceID, email: email, coupon: coupon, oldUser: oldUser })
  });
  if (!res.ok) {
    throw Error(res.statusText);
  }
  if (res.ok) {
    gtag.event({
      action: "stripe_checkout",
      customer_email: email || '',
      coupon: coupon || "",
      oldUser: oldUser || false,
      line_items: [
        {
          price: priceID || '',
          quantity: 1
        }
      ],
    });
  }
  return res.json();
}

export const getStocksJSONData = async ({ url, alphabet }) => {
  const res = await fetch(url, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'same-origin',
    body: JSON.stringify({ alphabet }),
  });

  if (!res.ok) {
    console.error('fetch stock data error:', res.statusText)
  }

  return res.json();
};

export const getFundsJSONData = async ({ url, alphabet }) => {
  const res = await fetch(url, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'same-origin',
    body: JSON.stringify({ alphabet }),
  });

  if (!res.ok) {
    console.error('fetch stock data error:', res.statusText)
  }

  return res.json();
};

export const searchTicker = async ({ url, searchString }) => {
  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      credentials: 'same-origin',
      body: JSON.stringify({ searchString }),
    });

    if (!res.ok) {
      console.error(`fetch stock data error: ${res.status} - ${res.statusText}`);
      return [];
    }

    return await res.json();
  } catch (error) {
    console.error('An unexpected error occurred while fetching stock data:', error);
    return [];
  }
};

export const searchFund = async ({ url, searchString }) => {
  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      credentials: 'same-origin',
      body: JSON.stringify({ searchString }),
    });

    if (!res.ok) {
      console.error(`fetch fund data error: ${res.status} - ${res.statusText}`);
      return [];
    }

    return await res.json();
  } catch (error) {
    console.error('An unexpected error occurred while fetching fund data:', error);
    return [];
  }
};

export const fetchSubscriptionDetails = async (customerId) => {
  try {
    const res = await fetch(`/api/get-subscription-details?customerId=${customerId}`);

    if (res.ok) {
      const subscriptionDetails = await res.json();
      return subscriptionDetails;
    } else {
      const { error } = await res.json();
      throw new Error(error);
    }
  } catch (err) {
    console.error(err);
    throw new Error('Failed to fetch subscription details');
  }
}

export const checkSubscription = async (email) => {
  try {
    const res = await fetch('/api/check-subscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    if (!res.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await res.json();
    return data;
  } catch (err) {
    console.error('Error:', err);
    throw new Error('Failed to fetch subscription details');
  }
};

export const fetchInvoiceHistory = async (customerId) => {
  try {
    const res = await fetch(`/api/fetch-invoice-history?customerId=${customerId}`);

    if (res.ok) {
      const invoiceDetails = await res.json();
      return invoiceDetails;
    } else {
      const { error } = await res.json();
      throw new Error(error);
    }
  } catch (err) {
    console.error(err);
    throw new Error('Failed to fetch invoice details');
  }
}

export const fetchDefaultCard = async (customerId) => {
  try {
    const res = await fetch(`/api/default-card?customerId=${customerId}`);

    if (res.ok) {
      const cardDetails = await res.json();
      return cardDetails;
    } else {
      const { error } = await res.json();
      throw new Error(error);
    }
  } catch (err) {
    console.error(err);
    throw new Error('Failed to fetch card details');
  }
}

export const scrollIntoView = () => {
  const path = window.location.hash
  if (path && path.includes("#") && typeof window != "undefined") {
    setTimeout(() => {
      const id = path.replace("#", "")
      const el = window.document.getElementById(id)
      const r = el?.getBoundingClientRect()
      window.top.scroll({
        // top: pageYOffset + r.top,
        top: r?.top - 100,
        behavior: "smooth",
      })
    }, 1000)
  }
}

export const sortByDate = (a, b) => {
  return new Date(b.frontmatter.date) - new Date(a.frontmatter.date)
}

export const usePagination = (data, itemsPerPage) => {
  const [currentPage, setCurrentPage] = useState(1);
  const maxPage = Math.ceil(data.length / itemsPerPage);

  function currentData() {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return data.slice(begin, end);
  }

  function next() {
    setCurrentPage(currentPage => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage(currentPage => Math.max(currentPage - 1, 1));
  }

  function jump(page) {
    const pageNumber = Math.max(1, page);
    setCurrentPage(currentPage => Math.min(pageNumber, maxPage));
  }

  return { next, prev, jump, currentData, currentPage, maxPage };
}

export const calculatePagination = (totalBlogs, currentPage, blogsPerPage) => {
  const totalPages = Math.ceil(totalBlogs / blogsPerPage);
  const currentPageIndex = currentPage - 1;
  const from = currentPageIndex * blogsPerPage;
  if (from >= totalBlogs) {
    return 0;
  }
  return from;
}

export const createBlogMetaTag = (tags, slug, title, description) => {
  return `<meta name=\"robots\" content=\"index,follow\">\n<link rel=\"canonical\" href=\"${process.env.NEXT_PUBLIC_URL}/blogs/${slug}\">\n<title>${title}</title>\n<meta name=\"description\" content=\"${description}\" >\n<meta name=\"keywords\" content=\"${title}, blogs, financial blog, charts, financial metrics, fund holders, income statement, balance sheet, insider trading\">\n\n<meta name=\"twitter:card\" content=\"summary_large_image\">\n<meta name=\"twitter:creator\" content=\"Grufity\">\n<meta name=\"twitter:site\" content=\"@Grufity\">\n<meta name=\"twitter:title\" content=\"${title}\">\n<meta name=\"twitter:description\" content=\"${description}\">\n<meta name=\"twitter:image\" content=\"https://www.grufity.com/assets/svg/favicon.png\">\n\n<meta property=\"og:title\" content=\"${title}\">\n<meta property=\"og:image\" content=\"https://www.grufity.com/assets/svg/favicon.png\">\n<meta property=\"og:image:alt\" content=\"${title}\">\n<meta property=\"og:url\" content=\"${process.env.NEXT_PUBLIC_URL}/blogs/${slug}\">\n<meta property=\"og:description\" content=\"${description}\">\n<meta property=\"og:type\" content=\"article\">\n`
}

// Third-grade students = 150 words per minute (wpm)
// Eighth grade students = 250 wpm
// Average college student = 450 wpm
// Average "high-level exec" = 575 wpm
// Average college professor = 675 wpm
// Speed readers = 1,500 wpm
// World speed reading champion = 4,700 wpm
// Average adult = 300 wpm

export const readingTime = (content) => {
  // const text = document.getElementById("article").innerText;
  const wpm = 5;
  const words = content?.trim().split(/\s+/).length;
  const time = Math.ceil(words / wpm);
  // document.getElementById("time").innerText = time;
  return time ? `${time} minute` : null
}


export const timeout = (delay) => {
  return new Promise(res => setTimeout(res, delay));
}


export const parseTabData = payload => {
  // const { getStockTabs } = payload || {};
  let keyMap = {
    c: 'code',
    v: 'value',
    r: 'recordDate'
  };

  const _getMetrics = (baseline = baseValue, payload = {}) => {
    const { data } = payload || {};
    const parseMetrics = (baseline = baseValue, payload = {}) => {
      const { c, r, v } = payload || {};
      return {
        code: c,
        recordDate: r,
        value: v,
      };

    };
    return {
      ...payload,
      data: data && data?.length > 0 ? data?.map(d => parseMetrics(undefined, d)) : undefined,
    };
  };

  const getTabData = d => {
    switch (d.type) {
      case dataConstants.STOCK_SECTION_TYPES.CHART:
        return d.tabData && d.tabData.length > 0 ? chart(undefined, d.tabData[0]).getChart : undefined;
      case dataConstants.STOCK_SECTION_TYPES.TICKER_TABLE:
        return d.tabData && d.tabData.length > 0 ? convertTickerTblFromData({ ...d.tabData[0], tickers: d.tabData[0].tickers ? JSON.parse(d.tabData[0].tickers) : d.tabData[0].tickers }) : undefined;
      case dataConstants.STOCK_SECTION_TYPES.TABLE_DATA:
        return d.tabData && d.tabData.length > 0 ? convertTblFromData(d.tabData) : undefined;
      case "SERIES":
        // return d?.tabData && d?.tabData?.length > 0 ? d?.tabData?.map(d => getSeries(undefined, d)) : undefined;
        return d?.tabData && d?.tabData?.length > 0 ? d?.tabData?.map((obj) => {
          return Object.keys(obj).reduce((acc, key) => {
            if (keyMap[key]) {
              acc[keyMap[key]] = obj[key];
            } else {
              acc[key] = obj[key];
            }
            return acc;
          }, {})
        }) : undefined;
      case "METRICS":
        return d?.tabData && d?.tabData?.length > 0 ? d?.tabData?.map(d => _getMetrics(undefined, d)) : undefined;
      default:
        return d.tabData;
    }
  }

  return {
    ...payload,
    tabData: getTabData(payload),
    settings: payload?.settings ? JSON.parse(payload?.settings ?? "") : payload?.settings,
  };
}

export const getParsedSectionData = async (ticker, sectionCode, tabCode, subTab) => {
  try {
    const { getStockTabs: data } = await getStockTabs(ticker, tabCode, subTab);
    const section = data?.find(d => d.section_code === sectionCode);
    return section ? parseTabData(section) : null;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};

// export const fetchTabData = (ticker, sectionCode, tabCode, subTab) => {
//   const [data, setData] = useState(null);
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const result = await getParsedSectionData(ticker, sectionCode, tabCode, subTab);
//         setData(result);
//       } catch (error) {
//         console.error('Error fetching data:', error);
//         setData(null);
//       }
//     };

//     fetchData();
//   }, [ticker, sectionCode, tabCode, subTab]);

//   return data;
// };

const fetcher = async (ticker, sectionCode, tabCode, subTab) => {
  return await getParsedSectionData(ticker, sectionCode, tabCode, subTab);
};

export const useFetchTabData = (ticker, sectionCode, tabCode, subTab) => {
  const { data, error, isValidating } = useSWR(
    ticker ? [ticker, sectionCode, tabCode, subTab] : null,
    fetcher,
    {
      refreshInterval: 12 * 3600000, // 12 hours in milliseconds,
      revalidateOnFocus: false,
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        if (error.status === 404) return;
        if (retryCount >= 10) return;
        setTimeout(() => revalidate({ retryCount }), 12 * 3600000);
      },
    }
  );

  return {
    data,
    loading: isValidating,
    error,
  };

  // const [data, setData] = useState(null);
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const result = await getParsedSectionData(ticker, sectionCode, tabCode, subTab);
  //       setData(result);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //       setData(null);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, [ticker, sectionCode, tabCode, subTab]);

  // // const parsedData = useMemo(() => {
  // //   if (data) {
  // //     return data;
  // //   }
  // //   return null;
  // // }, [data]);

  // // return { data: parsedData, loading };

  // return { data, loading };
};

export const getParsedFVData = async (ticker, fromDate) => {
  try {
    const { getStockSummary: data } = await get5YPriceData(ticker, fromDate);
    return data && data?.stock_data && data?.stock_data?.length > 0 ? data?.stock_data : null;
  } catch (error) {
    console.error('Error fetching data:', error);
    return null;
  }
};

const fvFetcher = async (ticker, fromDate) => {
  return await getParsedFVData(ticker, fromDate);
};

export const useFVTabData = (ticker, fromDate) => {
  const { data, error, isValidating } = useSWR(
    ticker ? [ticker, fromDate] : null,
    fvFetcher,
    {
      refreshInterval: 12 * 3600000, // 12 hours in milliseconds,
      revalidateOnFocus: false,
      onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
        if (error.status === 404) return;
        if (retryCount >= 10) return;
        setTimeout(() => revalidate({ retryCount }), 12 * 3600000);
      },
    }
  );

  return {
    data,
    loading: isValidating,
    error,
  };

  // const [data, setData] = useState(null);
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const result = await getParsedFVData(ticker, fromDate);
  //       setData(result);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //       setData(null);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchData();
  // }, [ticker, fromDate]);

  // return { data, loading };
};

export const getHeroContent = (payload, page) => {
  // let data = payload && payload.length > 0 ? parseTabData(payload[0]) : null;
  // data = data && data?.settings ? data?.settings?.hero : null

  // return data && data.length > 0 ? data.find(d => d.page === page) : null;

  try {
    return !payload || !payload.length
      ? null
      : parseTabData(payload[0])?.settings?.hero?.find(d => d.page === page) || null;
  } catch (error) {
    console.error("Error getting hero content:", error);
    return null;
  }
}
export const keyMap = {
  c: 'code',
  v: 'value',
  r: 'recordDate'
};

const formatNumberWithCommas = (number) => {
  // return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  if (typeof number === "string") {
    number = parseInt(number);
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

const addCommasToNumberString = (numString) => {
  if (!numString) {
    return '';
  }

  let stringWithoutCommas = numString.replace(/,/g, '');
  let parts = stringWithoutCommas.split('.');
  let integerPart = parts[0];
  let decimalPart = parts.length > 1 ? `.${parts[1]}` : '';
  let formattedIntegerPart = integerPart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${formattedIntegerPart}${decimalPart}`;
}

const formatDate = (date) => {
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  };
  return new Intl.DateTimeFormat('en-US', options).format(new Date(date));
}

export const downloadCSV = (data, columns, fileName, useformatDate, _) => {
  const csvData = [];
  const headers = columns.map(col => {
    let name = col?.name;
    if (typeof name === "string" && name.includes(",")) {
      name = `"${name}"`;
    }
    return name;
  }).join(',');
  csvData.push(headers);

  data.forEach(row => {
    const rowData = columns.map(col => {
      let value = row[col?.field];
      if (col?.field === 'dateFiled' && useformatDate) {
        value = formatDate(value);
      }
      if (typeof value === "string" && col?.field === 'pctClass') {
        value = value + "%";
      }
      if (typeof value === "string" && col?.field === 'aggAmt') {
        value = addCommasToNumberString(value);
      }
      if (typeof value === "number" && col?.isPct && (!col?.peerValue || !col?.isSectorValue)) {
        value = value?.toFixed(2) + "%";
      } else if (col?.mult) {
        value = col?.isPct ?
          value ? `${roundNum(value * col?.mult)}%` : '-' :
          (col?.peerValue || col?.isSectorValue) ?
            value ? formatCount(Math.sign(value) * value, true, 1, Math.sign(value)) : '-' :
            (typeof value === "string" && (col?.field === 'sector' || col?.field === 'industry')) ?
              value + "" :
              value ? `${roundNum(value * col?.mult)}` : '-';
      }
      if (typeof value === "number" && (col?.peerValue || col?.isSectorValue)) {
        value = (col?.peerValue || col?.isSectorValue) && col?.tooltip ?
          value ? formatCount(Math.sign(value) * value, true, 1, Math.sign(value)) : '-'
          :
          value ? formatCount(Math.sign(value) * value, true, 1, Math.sign(value)) : '-'
        // value = value?.toFixed(2);
      }
      if (typeof value === "number" && !col?.peerValue) {
        value = formatNumberWithCommas(value);
      }
      if (typeof value === "string" && value.includes(",")) {
        value = `"${value}"`;
      }
      return value;
    });
    csvData.push(rowData.join(','));
  });

  const csv = csvData.join('\n');

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.click();

};

export const downloadHTMLCSV = (fileName, htmlTable) => {
  // var parser = new DOMParser();
  // var htmlDoc = parser.parseFromString(htmlTable, 'text/html');
  // var rows = htmlDoc.getElementsByTagName("tr");

  // var csv = [];

  // for (var i = 0; i < rows.length; i++) {
  //   var row = [], cols = rows[i].getElementsByTagName("td");

  //   for (var j = 0; j < cols.length; j++) {
  //     row.push(cols[j].innerText);
  //   }

  //   csv.push(row.map(function (item) {
  //     return JSON.stringify(item);
  //   }).join(","));
  // }

  // var csvFile = new Blob([csv.join("\n")], { type: "text/csv" });
  // var downloadLink = document.createElement("a");
  // downloadLink.download = fileName;
  // downloadLink.href = window.URL.createObjectURL(csvFile);
  // downloadLink.style.display = "none";
  // document.body.appendChild(downloadLink);
  // downloadLink.click();

  var parser = new DOMParser();
  var htmlDoc = parser.parseFromString(htmlTable, 'text/html');
  var rows = htmlDoc.getElementsByTagName("tr");

  var headerRows = [];
  for (var i = 0; i < rows.length; i++) {
    var headerCells = rows[i].getElementsByTagName("th");
    if (headerCells.length) {
      var headerRow = [];
      for (var j = 0; j < headerCells.length; j++) {
        var colspan = headerCells[j].getAttribute("colspan") || 1;
        var rowspan = headerCells[j].getAttribute("rowspan") || 1;
        for (var k = 0; k < rowspan; k++) {
          if (!headerRows[i + k]) {
            headerRows[i + k] = [];
          }
          for (var l = 0; l < colspan; l++) {
            headerRows[i + k].push(headerCells[j].innerText);
          }
        }
      }
    }
  }

  var csv = [];
  for (var i = 0; i < headerRows.length; i++) {
    csv.push(headerRows[i].map((item) => JSON.stringify(item)).join(","));
  }

  for (var i = 0; i < rows.length; i++) {
    var row = [], cols = rows[i].getElementsByTagName("td");

    for (var j = 0; j < cols.length; j++) {
      row.push(cols[j].innerText);
    }

    csv.push(row.map((item) => JSON.stringify(item)).join(","));
  }

  var csvFile = new Blob([csv.join("\n")], { type: "text/csv" });
  var downloadLink = document.createElement("a");
  downloadLink.download = fileName;
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = "none";
  document.body.appendChild(downloadLink);
  downloadLink.click();
}

// .map((item) => JSON.stringify(item)).join(",")


export const downloadFinCSV = (tables, fileName) => {
  // const csvData = [];

  // tables.forEach(table => {
  //   const headers = Array.from(table.querySelectorAll('th')).map(th => th.innerText);
  //   csvData.push(headers.join(','));

  //   const rows = Array.from(table.querySelectorAll('tr')).slice(1);
  //   rows.forEach(row => {
  //     let cells = Array.from(row.querySelectorAll('td')).map(td => td.innerText);
  //     let colspanCount = 0;
  //     cells.forEach((cell, index) => {
  //       const td = row.querySelectorAll('td')[index - colspanCount];
  //       const colspan = parseInt(td.getAttribute('colspan')) || 1;
  //       colspanCount += colspan - 1;
  //       for (let i = 0; i < colspan - 1; i++) {
  //         cells.splice(index + 1, 0, '');
  //       }
  //     });
  //     csvData.push(cells.join(','));
  //     for (let i = 0; i < colspanCount; i++) {
  //       cells.push('');
  //     }
  //   });

  //   csvData.push(''); // add an empty line between tables
  // });

  // const csv = csvData.join('\n');

  // const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
  // const link = document.createElement('a');
  // link.href = URL.createObjectURL(blob);
  // link.download = fileName;
  // link.click();

  const csvData = [];

  tables.forEach(table => {
    const headerRows = Array.from(table.querySelectorAll('thead tr'));
    headerRows.forEach(headerRow => {
      // const headers = Array.from(headerRow.querySelectorAll('th')).map(th => th.innerText);
      const headers = Array.from(headerRow.querySelectorAll('th')).map(th => {
        const text = th.innerText;
        return text.includes(',') ? `"${text}"` : text;
      });
      let colspanCount = 0;
      headers.forEach((header, index) => {
        const th = index >= 0 ? headerRow.querySelectorAll('th')[index - colspanCount] : null;
        const colspan = th ? (parseInt(th.getAttribute('colspan')) || 1) : 1;
        colspanCount += colspan - 1;
        for (let i = 0; i < colspan - 1; i++) {
          headers.splice(index + 1, 0, '');
        }
      });
      csvData.push(headers.join(','));
      for (let i = 0; i < colspanCount; i++) {
        headers.push('');
      }
    });

    const rows = Array.from(table.querySelectorAll('tbody tr'));
    rows.forEach(row => {
      // let cells = Array.from(row.querySelectorAll('td')).map(td => td.innerText);
      let cells = Array.from(row.querySelectorAll('td')).map(td => {
        const text = td.innerText;
        return text.includes(',') ? `"${text}"` : text;
      });
      let colspanCount = 0;
      cells.forEach((cell, index) => {
        const td = row.querySelectorAll('td')[index - colspanCount];
        const colspan = parseInt(td.getAttribute('colspan')) || 1;
        colspanCount += colspan - 1;
        for (let i = 0; i < colspan - 1; i++) {
          cells.splice(index + 1, 0, '');
        }
      });
      csvData.push(cells.join(','));
      for (let i = 0; i < colspanCount; i++) {
        cells.push('');
      }
    });

    csvData.push(''); // add an empty line between tables
  });

  const csv = csvData.join('\n');

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
};

export const isDesktop = () => {
  const match = useMediaQuery('(min-width:600px)');
  return useMemo(() => match, [match]);
};
