// import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SsidChartRoundedIcon from '@mui/icons-material/SsidChartRounded';
// import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
// import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
// import CandlestickChartRoundedIcon from '@mui/icons-material/CandlestickChartRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
// import ViewCompactRoundedIcon from '@mui/icons-material/ViewCompactRounded';
// import CottageRoundedIcon from '@mui/icons-material/CottageRounded';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import WorkspacePremiumRoundedIcon from '@mui/icons-material/WorkspacePremiumRounded';
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded';
import FeedbackRoundedIcon from '@mui/icons-material/FeedbackRounded';
import BugReportRoundedIcon from '@mui/icons-material/BugReportRounded';
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded';
import ScheduleRoundedIcon from '@mui/icons-material/ScheduleRounded';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import MonetizationOnRoundedIcon from '@mui/icons-material/MonetizationOnRounded';
import MultilineChartRoundedIcon from '@mui/icons-material/MultilineChartRounded';
import QueryStatsRoundedIcon from '@mui/icons-material/QueryStatsRounded';
import TrendingDownRoundedIcon from '@mui/icons-material/TrendingDownRounded';
import TableChartRoundedIcon from '@mui/icons-material/TableChartRounded';
// import CircleNotificationsRoundedIcon from '@mui/icons-material/CircleNotificationsRounded';
import ScatterPlotRoundedIcon from '@mui/icons-material/ScatterPlotRounded';
import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
// import FactCheckRoundedIcon from '@mui/icons-material/FactCheckRounded';
// import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
// import ContentPasteSearchRoundedIcon from '@mui/icons-material/ContentPasteSearchRounded';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import FactCheckRoundedIcon from '@mui/icons-material/FactCheckRounded';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import SettingsEthernetRoundedIcon from '@mui/icons-material/SettingsEthernetRounded';
import FactoryRoundedIcon from '@mui/icons-material/FactoryRounded';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';

module.exports = Object.freeze({
  PAGES: [
    // { name: "Home", url: "/home", icon: <HomeRoundedIcon /> },
    // { name: "Stock Ideas", url: "/stock-ideas", icon: <TipsAndUpdatesIcon />, desc: 'Expert insights, trends, and picks to elevate your investment strategy.', badge: null },
    { name: "Stocks", url: "/stock", icon: <SsidChartRoundedIcon />, desc: 'Five-Minute Due Diligence — Quickest way to perform due-diligence on any stock.', badge: null },
    { name: "Funds", url: "/funds", icon: <AttachMoneyRoundedIcon />, desc: 'Fund Holdings — Equity portfolio of 1000s of active funds such as Buffett, Carl Icahn, Cathie Woods etc.', badge: null },
    { name: "Screener", url: "/stock-screener", icon: <ManageSearchIcon />, desc: 'Customized Path to Profitable Stocks — Empower Your Investment Strategy with Our Stock Screener.', badge: null },
    // { name: "Stock Ideas", url: "/stock-ideas", icon: <FactoryRoundedIcon />, desc: 'Unlock market potential with our Stock Ideas page. Expert insights, trends, and picks to elevate your investment strategy.' ,badge:0},
    { name: "Sectors", url: "/sectors", icon: <FactoryRoundedIcon />, desc: 'Gain insights into various market sectors and industries for informed investment decisions.', subMenu: false, badge: null },
    { name: "Watchlists", url: "/watchlists", icon: <FactCheckRoundedIcon />, desc: 'Track your favorite investments with personalized stock watchlists.', badge: null },
    { name: "Economy", url: "/us-economy", icon: <TrendingUpRoundedIcon />, desc: 'Insights into the US economy, including GDP, Yield Curve, and Housing.', subMenu: true, badge: null },
    // { name: "Data Explorer", url: "/data-explorer", icon: <QueryStatsRoundedIcon />, desc: 'Explore US Macro and Stocks data using series codes with our powerful search tool.', subMenu: true, badge: null },
    // { name: "Screener", url: "/stock-screener", icon: <FactCheckRoundedIcon />, subMenu: true, longName: 'Stock screener', desc: 'Compares multiple stocks on various metrics.' ,badge:0},
    // { name: "Search Filings", url: "/search", icon: <ContentPasteSearchRoundedIcon />, longName: 'Search SEC Filings', desc: 'Search Financial Reports, Press Releases and other Fillings.', subMenu: true ,badge:0},
    // { name: "Search Filings", url: "/search", icon: <ContentPasteSearchRoundedIcon />, desc: 'Search Financial Reports, Press Releases and other Fillings.' ,badge:0},
    // { name: "Blogs", url: "/blogs", icon: <DescriptionRoundedIcon />, desc: 'In-depth coverage of financial reporting.', subMenu: true, badge: null },
    // { name: "API", url: "/docs/api?lang=r#api-reference", icon: <SettingsEthernetRoundedIcon />, desc: 'Documentation and resources for our APIs.', subMenu: true, badge: null },
  ],
  PREMPROFILE: [
    { name: "Your Profile", url: "/profile", icon: <PersonRoundedIcon /> },
    { name: "Your Premium Benefits", url: "/", icon: <ReceiptLongRoundedIcon /> },
    { name: "Manage Membership", url: "/profile", icon: <WorkspacePremiumRoundedIcon /> },
    { name: "Your Profile", url: "/profile", icon: <PersonRoundedIcon /> },
    { name: "Know Premium Benefits", url: "/", icon: <ReceiptLongRoundedIcon /> },
    { name: "Get Membership", url: "/", icon: <WorkspacePremiumRoundedIcon /> },
    { name: "Report an Issue", url: "/report-issue", icon: <ReportProblemRoundedIcon /> },
  ],
  HELPUS: [
    { name: "Help", url: "mailto:contact@grufity.com?subject=Need Help", icon: <HelpOutlineRoundedIcon /> },
    { name: "Send Feedback", url: "mailto:contact@grufity.com?subject=My Feedback", icon: <FeedbackRoundedIcon /> },
    { name: "Report Bug", url: "mailto:contact@grufity.com?subject=Reporting Bug", icon: <BugReportRoundedIcon /> },
  ],
  USERMENU: [
    { name: "Upgrade", path: '/pricing', icon: <StarRoundedIcon sx={{ fontSize: '1.2rem', color: '#ff9811' }} />, color: "#ff9811" },
    // { name: "My Profile", path: '/profile', icon: <PersonRoundedIcon sx={{ fontSize: '1.2rem' }} /> },
    // { name: "My Charts", path: '/', icon: <CandlestickChartRoundedIcon sx={{ fontSize: '1.2rem' }} /> },
    // { name: "My Wall", path: '/', icon: <ViewCompactRoundedIcon sx={{ fontSize: '1.2rem' }} /> },
    // { divider: "divider" },
    { name: "API Keys and Billing", path: '/profile', icon: <ManageAccountsRoundedIcon sx={{ fontSize: '1.2rem' }} /> },
    { name: "Send Feedback", path: '/contact', icon: <ModeCommentOutlinedIcon sx={{ fontSize: '1.2rem' }} /> },
    { divider: "divider" },
    { name: "Logout", icon: <ExitToAppRoundedIcon sx={{ fontSize: '1.2rem' }} /> },
  ],
  TOOLS: "Tools",
  SIGNIN: "Sign in",
  SIGNUP: "Sign up for free",
  LOGIN: "Login",
  USERMENUTOOLTIP: "Open Settings",
  MYACCOUNT: "My Account",

  INCREMENT: "INCREMENT",
  DECREMENT: "DECREMENT",
  GET_FUND_SUM: "GET_FUND_SUM",

  GRID_VIEW: {
    THREE_GRIDS: 1,
    TWO_GRIDS: 2,
  },
  GRID_ORDER: {
    RECENT: 1,
    POPULAR: 2,
  },
  API_STATUS: {
    NONE: 0,
    REQUEST: 1,
    SUCCESS: 2,
    ERROR: 3,
  },
  NAVS: {
    DESC_PAGE: "/stock-history",
    STOCK_SCREENER: "/stock-screener",
    STOCK: "/stock",
    WALL: "/wall",
    CHARTS: "/charts",
    GRUFITY: "/grufity",
    VIEW_CHART: "/historical-data-chart-graph",
    PINNED: "/pinned",
    // CREATE_CHART: '',
    CHART_SHARE: "/chart-share",
    CHART: "/chart",
    TERMS: "/terms",
    PRIVACY: "/privacy",
    RESEARCH: "/one-pager",
    HOME_TEMP: "/home-temp",
    DATA_EXPLORER: "/data-explorer",
    INTRO: "/intro",
    THANKS: "/thanks",
    PRICING: "/pricing",
    PAYSTATUS: "/pay-status",
    //MY_ACC: '/account',
    CONTACT: "/contact",
    SIGNIN: "/signin",
    SIGNUP: "/signup",
    FORPASS: "/forgot-password",
    USER_CONFIRM: "/user-confirmation",
    HELP: "/help",
    US_ECONOMY: "/us-economy",
    EMBED_CHART: "/embed-chart",
    BLOGS: "/blogs",
    DASHBOARDS: "/dashboards",
    // COVID19: "/world/covid19-coronavirus",
    FUNDS: "/funds",
    SECTORS: "/sectors",
    SCREENER: "/stock-screener",
    GEO_PAGES: "/world",
    HOME: "/",
  },
  // ROUTES: {
  //   HOME: "/",
  //   CONTACT: "/contact",
  //   CHARTS: "/charts/:search?",
  //   GRUFITY: "/grufity/:code?/:title?",
  //   VIEW_CHART: "/historical-data-chart-graph/:code?/:title?",
  //   WALL: "/wall/:userTab?",
  //   PINNED: "/pinned",
  //   CHART: "/chart/:code?/:data?",
  //   STOCK: "/stock/:ticker?/:tab?/:type?/:title?",
  //   TERMS: "/terms",
  //   PRIVACY: "/privacy",
  //   DESC_PAGE: "/stock-history/:ticker/:company/:type",
  //   RESEARCH: "/one-pager/:term",
  //   HOME_TEMP: "/home-temp",
  //   DATA_EXPLORER: "/data-explorer/:search?",
  //   STOCK_SCREENER: "/stock-screener",
  //   INTRO: "/intro",
  //   THANKS: "/thanks",
  //   PRICING: "/pricing/:plan?/:excel?",
  //   PAYSTATUS: "/pay-status/:status?",
  //   //MY_ACC: '/account/:userTab?',
  //   SIGNIN: "/signin/:redirectTo?/:mode?/:excel?",
  //   SIGNUP: "/signup/:redirectTo?/:mode?/:excel?",
  //   FORPASS: "/forgot-password",
  //   USER_CONFIRM:
  //     "/user-confirmation/:mode/:oobCode/:apiKey?/:continueUrl?/:lang?",
  //   HELP: "/help/:type",
  //   US_ECONOMY: "/us-economy/:tab?",
  //   EMBED_CHART: "/embed-chart/:code/:ratio",
  //   BLOGS: "/blogs/:id?/:title?",
  //   BLOGS_REDIRECT: "https://blog.grufity.com",
  //   DASHBOARDS: "/dashboards/:id?/:title?",
  //   GEO_PAGES: "/world/:code/:country/:state?/:county?",
  //   CHART_SHARE: "/chart-share/:code/:formula/:title?",
  //   FUNDS: "/funds/:cik?",
  //   SECTORS: "/sectors",
  //   COMPARE: '/compare',
  //   SCREENER: "/screener",
  // },
  // GRIDS: {
  //   GRUFITY: "getGraffitiCharts",
  //   WALL: "getWallCharts",
  //   PINNED: "getPinnedCharts",
  // },
  CHART_SETTINGS: {
    PLOTS: "plots",
    EVENTS: "vRuler",
    PERIODS: "periods",
    ANNOTATIONS: "anns",
    CHANGE: "pctChange",
    TRENDLINE: "trendline",
    STYLING: "styling",
  },
  CHART_STATE: {
    VIEW: 1,
    DETAIL: 2,
    CREATE: 3,
    EDIT: 4,
  },
  MODAL_VIEW: {
    DETAIL: 1,
    TOOL: 2,
  },
  WALL_GRID: {
    POSTED: 1,
    DRAFTED: 2,
  },
  CHART_TYPE: {
    TIMESERIES: "TIMESERIES",
    SEQUENCE: "SEQUENCE",
    CATEGORIES: "CATEGORIES",
    LIFESTORY: "LIFESTORY",
    SCATTER: "SCATTER",
    PIETIME: "PIETIME",
    PIECATEGORY: "PIECATEGORY",
    STACKED: "STACKED",
  },
  CHART_SERIES_TYPE: {
    AREA: "area",
    BAR: "bar",
    LINE: "line",
    PIE: "pie",
  },
  // PAGES: {
  //   GRUFITY: "GRUFITY",
  //   WALL: "WALL",
  //   PINNED: "PINNED",
  //   CHART: "CHART",
  //   STOCK: "STOCK",
  //   PRIVACY: "PRIVACY",
  //   TERMS: "TERMS",
  // },
  // MODAL_TYPES: {
  //   GRUFITY: "GRUFITY",
  // },
  SCALES: {
    LINEAR: "linear",
    TIME: "time",
    TEXT: "text",
    LOG: "log",
    ORDINAL: "ordinal",
  },
  CHART_LAYERS: {
    CANVAS: "canvas",
    PERIODS: "periods",
    YAXIS: "yAxis",
    XAXIS: "xAxis",
    EVENTS: "events",
    PCTCHANGE: "pctChange",
    ANNOTATIONS: "annotations",
    TRENDLINE: "trendline",
    TEXT: "text",
    EDIT: "edit",
    SELECT: "select",
    DRAW: "draw",
    LEGEND: "legend",
    CROSSHAIR: "crosshair",
  },
  // DROPDOWNS: {
  //   CIKS: "ciks",
  //   CODES: "codes",
  //   CHART_SUGGESTIONS: "chartSuggestions",
  //   EVENTS: "events",
  //   PERIODS: "periods",
  // },
  PLOT_DATA_TYPE: {
    SERIES: "SERIES",
    STOCK: "STOCK",
    USER: "USER",
    USERGROUP: "USERGROUP",
    GROUP: "GROUP",
    GEO: "GEO",
    UNKNOWN: "UNKNOWN",
  },
  // DIRECTIONS: {
  //   TOP: "top",
  //   BOTTOM: "bottom",
  //   LEFT: "left",
  //   RIGHT: "right",
  // },
  // POPOVER_TABS: {
  //   DATA: 1,
  //   SETTING: 2,
  // },
  // SETTINGS: {
  //   BGCOLOR: "bgColor",
  //   COLOR: "color",
  //   FF: "ff",
  //   FS: "fs",
  //   STROKE: "stroke",
  //   STROKEWIDTH: "strokeWidth",
  //   DASHARRAY: "dashArray",
  //   OPACITY: "opacity",
  // },
  SETTING_GROUPS: {
    TEXT: "label",
    LINE: "line",
    PERCENT: "percent",
    RECT: "rect",
    AREA: "area",
  },
  CHART_SELECTOR: {
    Pointer: { selector: "pointer", selection: true },
    Plot: { selector: "plot", selection: true },
    Template: { selector: "template", selection: true },
    Story: { selector: "story", selection: true },
    Event: { selector: "event", selection: false },
    Callout: { selector: "callout", selection: false },
    Period: { selector: "period", selection: false },
    Trendline: { selector: "trendline", selection: false },
    Change: { selector: "change", selection: false },
    Text: { selector: "text", selection: false },
    Background: { selector: "background", selection: false },
    NONE: "none",
  },
  MB_CHART_SELECTOR: {
    NONE: "none",
    PLOT: "plot",
    DETAIL: "detail",
    THEME: "theme",
    VIEW: "view",
  },
  CHART_ITEM_TABS: {
    ALL: 1,
    USER: 2,
  },
  CHART_ITEM_TYPES: {
    USER: "USER",
    MARCO: "MARCO",
  },
  CHART_NAV_ITEMS: {
    NONE: 1,
    THEME: 2,
    RATIO: 3,
  },
  ASPECT_RATIO: {
    TWITTER: 2,
    FB: 1.9,
    STANDRAD: 1.6,
    TV: 1.33,
    HD: 1.78,
    A4: 1.42,
  },
  SERIES_SEQ: {
    FORWARD: 1,
    BACK: 2,
  },
  GID: "bDf",
  API_SYMBOLS: [")", ";"],
  SERIES_SUB_TYPES_BG: {
    CASHFLOW: "#bfc946",
    BALANCESHEET: "#36cccb",
    OTHERS: "#e78900",
    INCOMESTATEMENT: "#f04e4e",
  },
  DISCOVER_TABS: {
    FORM: 1,
    SERIES: 2,
  },
  RESEARCH_PAGE_TYPES: {
    COMMODITY: 1,
    ECONOMY: 2,
    YIELD: 3,
  },
  RESEARCH_TILE_TYPES: {
    MAIN: 1,
    TILE_CHART: 2,
    TEXT: 3,
    CHART_FIG: 4,
  },
  CARD_TYPES: {
    MAIN: 1,
    STANDRAD: 2,
    TEXT: 3,
    FIG: 4,
    TABLE: 5,
  },
  COMMODITY_TABS: {
    COMMODITY: "Commodity",
    INDICES: "Indices",
    ETF: "ETF",
  },
  PERFORM_TABS: {
    GAINNER: 1,
    LOOSER: 2,
  },
  STOCK_TABS: {
    SUM: "Summary",
    RET: "Historical-Returns",
    RISKS: "Risks",
    FV: "Fair-Value",
    PEERS: "Peers",
    IT: "Insider-Transactions",
    BUY: "BUY",
    OWN: "Funds-Owners-Holders",
    COMP: "Compare",
    BS: "Balance-Sheet",
    CF: "Cashflow",
    IS: "Income-Statement",
    QA: "Question-Answer",
    CHART: "Charts",
    NOTE: "My-Notes",
    OTH: "Other-Important-Financial-Details",
    CALCM: "Ratio-Metrics",
    MOBILE_ORDER_LIMIT: 12,
  },
  FIN_TYPES: {
    BS: 'Balance Sheet',
    CF: 'Cashflow',
    IS: 'Income Statement',
  },
  STOCK_TABS_CODES: {
    SUM: "SUM",
    RET: "RET",
    RISKS: "RISKS",
    FV: "FV",
    PEERS: "PEERS",
    IT: "IT",
    BUY: "BUY",
    OWN: "OWN",
    BS: "BS",
    CF: "CF",
    IS: "IS",
    QA: "QA",
    CHART: "CHART",
    NOTE: "NOTE",
    OTH: "OTH",
    CALCM: "CALCM",
  },
  STOCK_SECTION_CODES: {
    LIFESTORY: "LifestoryChart",
    TOPFUNDS: "TopOwners",
    PROJECTION: "projection",
    SUMBS: "SUMBS",
    SUMIS: "SUMIS",
    SUMCF: "SUMCF",
    SUMISQ: "SUMISQ",
    SUMCFQ: "SUMCFQ",
    MET: "Metrics",
    MET_2: "Metrics2",
    SUMCUMRET: "sum_cumRet",
    SUMYOYET: "sum_yoyRet",
    SUMSERIES: "sum_series",
    SUMINSTHOLDS: "Inst_Holdings",
    SUMINSTHOLDSCON: "Inst_Holdings_Conc",
    SUMRETCRISIS: "Sum_Returns_Crisis_Chart",
    SUMRETCHART: "Sum_Returns_Chart",
    SUMRETDRAW: "Sum_Returns_Drawdown",
    SUMRETURNS: "Sum_Returns_CumRets",
    SUMPROJCHART: "Sum_Projs_Chart",
    SUMRISKPROFILE: "Sum_header_risk_return",
    SUMHRETDATA: "Sum_header_return_data",
    SUMHRETCHART: "Sum_header_returns_chart",
    SUMPERFDATA: "Sum_perf_vs_sp500_data",
    SUMPERFCHART: "Sum_perf_vs_sp500_chart",
    SUMINDEXDATA: "Sum_index_vs_sp500_data",
    SUMINDEXCHART: "Sum_index_vs_sp500_chart",
    TRADINGBEHAVIOR: "Sum_tradingBehavior",
    SUMPEERS: "Peers_Metrics",
    SUM_QANDA: "Sum_QandA",
    FAIRVALUECHART: "fv_chart",
    FAIRVALUEDATA: "fv_series",
    FAIRVALUESCENARIOS: "fv_scenarios",
    FVSCENARIOANALYSIS: "fv_scenario_analysis",
    FVPROFILECHEAPEXP: "fv_scenario_analysis_gauge",
    FV_QANDA: "fv_QandA",
    PEERS_MET: "Peers_Metrics",
    PEER_QANDA: "peer_QandA",
    RETURNS_YOY_DATA: "ret_yoy_data",
    RETURNS_YOY_CHART: "ret_yoy_chart",
    RETURNS_ROLL_DATA: "ret_roll_data",
    RETURNS_ROLL_CHART: "ret_roll_chart",
    RETURNS_CUMRETS: "ret_Returns_CumRets",
    RETURNS_HEADER_DATA: "ret_header_return_data",
    RETURNS_HEADER_CHART: "ret_header_returns_chart",
    RETURNS_TB_MAY: "ret_tradingBehavior_May",
    RETURNS_TB_WEEKEND: "ret_tradingBehavior_Weekend",
    RETURNS_TB_DAILY: "ret_tradingBehavior_daily",
    RETURNS_CDF_SP500_CHART: "ret_cdf_SP500",
    RETURNS_CDF_SP500_DATA: "ret_cdf_SP500_data",
    RETURNS_PERF_VS_SP500_1MDATA: "Ret_perf_vs_sp500_1m_data",
    RETURNS_PERF_VS_SP500_1MCHART: "Ret_perf_vs_sp500_1m_chart",
    RETURNS_5Y_DIST_CHART: 'ret_dist_5Y_chart',
    RETURNS_5Y_DIST_DATA: 'ret_dist_5Y_data',
    // RETURNS_QANDA: "ret_QandA",
    RETURNS_QANDA: "ret_QandA",
    RISK_LOSSPROBABLITY: "risk_LossProbability",
    RISK_HIST_DRAWDOWN_DATA: "risk_Drawdown_data",
    RISK_PROFILE_OVERTIME_DATA: "risk_profile_over_time_data",
    RISK_PROFILE_OVERTIME_CHART: "risk_profile_over_time_chart",
    RISK_PROFILE_OVERTIME_GAUGE_DATA: "risk_profile_over_time_gauge",
    RISK_HIST_DRAWDOWN_CHART: "risk_Drawdown_chart",
    RISK_AVG_DRAWDOWNS_DATA: "risk_drawdowns",
    RISK_RECENT_VOLATILITY: "risk_volatility",
    RISK_DOWNVOL_VS_SP500_DATA: "risk_downvol_SP500_data",
    RISK_DOWNVOL_VS_SP500_CHART: "risk_downvol_SP500_chart",
    RISK_VOLATILITY_VS_SP500_DATA: "risk_volatility_SP500_data",
    RISK_VOLATILITY_VS_SP500_CHART: "risk_volatility_SP500_chart",
    RISK_DRAWDOWN_VS_SP500_DATA: "risk_drawdowns_SP500_data",
    RISK_DRAWDOWN_VS_SP500_CHART: "risk_drawdowns_SP500_chart",
    RISK_DRAWDN_DIST_VS_SP500_DATA: "risk_drawdowns_dist_SP500_data",
    RISK_DRAWDN_DIST_VS_SP500_CHART: "risk_drawdowns_dist_SP500_chart",
    RISK_QANDA: "risk_QandA",
    METRICS_QANDA: "Metrics_QandA",
    INSTTRADTCHART: "Insider_trends_chart_ps",
    INSTTRADDETAIL: "Insider_detail_holding",
    INSTTRADSPEED: "Insider_detail_speed",
    INSTTRADSPEEDVAL: "Insider_detail_speedVal",
    INSTTRADMONEY: "Insider_detail_MoneyMade",
    INSTTRADRECENT: "Insider_trends_chart_rTx",
    INSTTRADSHARES: "Insider_trends_data_pie",
    INSTTRADDATA: "Insider_All_data",
    INSTTRADSELLER: "Insider_Num_sellers",
    INSDR_FINAN_QUESTIONS: "Insider_and_financial_questions",
    INSDR_QANDA: "Insider_QandA",
    INSTHOLDS: "Inst_Holdings_summ",
    INSTHOLDSCON: "Inst_Holdings_summ_Conc",
    INSTHOLDSCHART: "Inst_Holdings_Summ_Chart",
    TOPOWNERS: "TopOwners",
    CONVICTION: "Conviction",
    LATEST: "Latest",
    INSTHOLDPUT: "Inst_Hold_Other_Chart_pc",
    INSTHOLDDEBT: "Inst_Hold_Other_Chart_deb",
    INSTHOLDNET: "Inst_Hold_Other_Chart_NET",
    INSTHOLDOTHER: "Inst_Holdings_Other_Inst",
    INSTHOLDCHANGE: "Inst_Holdings_adds_change",
    INSTHOLDBREAK: "Inst_Hold_chg_chart_break",
    INSTHOLDINCDE: "Inst_Hold_chg_chart_incde",
    INSTHOLDCURN: "Inst_Hold_chg_chart_churn",
    INSTHOLD_QANDA: "inst_QandA",
    IS_QANDA: "IS_QandA",
    BS_QANDA: "BS_QandA",
    CF_QANDA: "CF_QandA",
    OTH_QANDA: "OTH_QandA",
  },
  STOCK_SECTION_TYPES: {
    CHART: "CHART",
    SERIES: "SERIES",
    FINTABLE: "FINTABLE",
    METRICS: "METRICS",
    GRID: "GRID",
    TICKER_TABLE: "TICKER_TABLE",
    TABLE_DATA: "TABLE",
  },
  // PLANS: {
  //   PRIME: {
  //     name: "prime",
  //     prices: {
  //       Normal: { Monthly: "19.99", Annually: "219.99" },
  //     },
  //   },
  //   PROFESSIONAL: {
  //     name: "professional",
  //     prices: {
  //       // Normal: { Monthly: "49.99", Annually: "549.99" },
  //       Normal: { Monthly: "4.99", Annually: "54.99" },
  //       Student: { Monthly: "24.99", Annually: "274.99" },
  //     },
  //   },
  //   PREMIUM: {
  //     name: "premium",
  //     prices: {
  //       Normal: { Monthly: "4.99", Annually: "54.99" },
  //     },
  //   },
  // },
  PLAN_MODES: {
    NORMAL: "Normal",
    STUDENT: "Student",
  },
  PLAN_CYCLES: {
    MONTHLY: "Monthly",
    QUARTERLY: "Quarterly",
    ANNUALLY: "Annually",
  },
  // USER_AUTH_CODES: {
  //   NO_EXISTS: "auth/user-not-found",
  //   WRONG_PASS: "auth/wrong-password",
  //   EXISTS: "auth/email-already-in-use",
  //   INVALID: "auth/invalid-email",
  // },
  // USER_TABS: {
  //   OVERVIEW: "overview",
  //   CHARTS: "charts",
  //   // PINNED: 'Pinned',
  //   LIKED: "liked",
  //   SERIES: "series",
  //   REPORTS: "reports",
  //   SETTING: "profile",
  // },
  // USER_LISTS: {
  //   QUESTIONS: "Questions",
  //   ANSWERS: "Answers",
  //   COMMENTS: "Comments",
  //   EVENTS: "EVENTS",
  //   PERIODS: "PERIODS",
  //   NOTES: "NOTES",
  //   CHARTS: "CHARTS",
  // },
  // // USER_MENUS: {
  // //   CHARTS: 'CHARTS',
  // //   SERIES: 'SERIES',
  // // },
  // CHART_FILTERS: {
  //   ALL: "all",
  //   WALL: "wall",
  //   PINNED: "pinned",
  // },
  // USER_COOKIES: {
  //   refreshToken: { key: "refreshToken", value: null, time: 30 * 24 * 60 * 60 },
  //   accessToken: { key: "actkn", value: null, time: 60 * 60 },
  // },
  // GA_COOKIES: {
  //   longTerm: { key: "_ga", value: null },
  //   shortTerm: { key: "_gid", value: null },
  // },
  USER_DATA_TYPES: {
    Date: "date",
    Seq: "seq",
    Cate: "desc",
  },
  // COMPARE_STATUS: {
  //   UPDATE: "Update",
  //   NO_CHANGE: "No Change",
  //   DELETE: "Delete",
  //   ADD: "Add",
  // },
  // UPLOAD_SAMPLES: {
  //   SINGLE_DATE: "singleDate",
  //   MULTI_DATE: "multiDate",
  //   SINGLE_SEQ: "singleSeq",
  //   MULTI_SEQ: "multiSeq",
  //   SINGLE_CATE: "singleCate",
  //   MULTI_CATE: "multiCate",
  // },
  // LOGIN_STATES: {
  //   SCREEN: 1,
  //   REGISTER: 2,
  //   SUCCESS: 3,
  // },
  // REGISTER_STATES: {
  //   NONE: 0,
  //   SCREEN: 1,
  //   NAME: 2,
  //   NOT_VERIFIED: 3,
  //   SUCCESS: 4,
  // },
  // REGISTER_MODES: {
  //   NONE: 0,
  //   TRIAL: 1,
  //   BUY: 2,
  //   //SUCCESS: 2,
  //   //SUCCESS: 3,
  // },
  // USER_CONFIRM_ACTIONS: {
  //   EMAIL_VERIFY: "verifyEmail",
  //   RECOVER_EMAIL: "recoverEmail",
  //   RESET_PASS: "resetPassword",
  // },
  // MESSAGE_TYPES: {
  //   SUCCESS: "success",
  //   ERROR: "danger",
  //   WARNING: "warning",
  // },
  // USER_TYPES: {
  //   FREE: "FREE",
  //   TRIAL: "TRIAL",
  //   PRIME: "PRIME",
  //   PROFESSIONAL: "PROFESSIONAL",
  //   PREMIUM: "PREMIUM",
  //   ENTERPRISE: "ENTERPRISE",
  // },
  SERIES_TYPES: {
    STOCK: "STOCK",
    MACRO: "MACRO",
    ALL: "ALL",
  },
  PLAN_TYPES: {
    // Prime: {
    //   Normal: { Monthly: "prime_monthly", Annually: "prime_yearly" },
    // },
    // Professional: {
    //   Normal: {
    //     Monthly: "professional_monthly",
    //     Annually: "professional_yearly",
    //   },
    //   Student: {
    //     Monthly: "student_professional_monthly",
    //     Annually: "student_professional_yearly",
    //   },
    // },
    Premium: {
      Normal: {
        Monthly: "premium_monthly",
        Annually: "premium_yearly",
      },
    },
  },
  // PLAN_MAPS: {
  //   Prime: "PRIME",
  //   Professional: "PROFESSIONAL",
  //   Premium: "PREMIUM",
  //   Enterprise: "ENTERPRISE",
  // },
  PLAN_QANDA: [
    {
      "q": "1What kind of payment methods do you accept?",
      "a": "We currently accept any credit or debit card with a MasterCard, Visa, Discover Network, American Express or Diners Club International. All credit card payments are securely processed through Stripe."
    },
    {
      "q": "I want you to cover a particular stock.",
      "a": "Currently, we are covering just US stocks (5000+ tickers). If you want us to cover a particular stock, just email the request to support@grufity.com or DM to twitter handles @Grufity or @grufityreports. We will onboard it if possible else we will get back to you on when we can."
    },
    {
      "q": "Could you add a particular chart or data point to the stock/economy pages?",
      "a": "Yes. We are open to suggestions. If there's a data point or chart you would like to see for all stocks then it can be added. We show only 10% of the data that we have so as to keep it succint. We do share a lot of extra data on twitter @grufity and @grufityReports when those come into focus. You can interact with us on our twitter handles. You can DM us there or email to support@grufity.com with suggestions."
    },
    {
      "q": "Can I access your data through API?",
      "a": "Not yet. But we are considering exposing our APIs."
    },
    {
      "q": "What if I want to cancel my service?",
      "a": "No problem. If you decide Grufity isn't for you, you can cancel with a single click from your account page."
    },
    {
      "q": "Can I get a refund?",
      "a": "We want you to have a good experience with us. If you are not happy, you can email us at support@grufity.com."
    }
  ],
  PLAN_COMPARISON: [
    {
      feature_icon: <ScheduleRoundedIcon />,
      feature_name: "<a href='/stock-screener'>Stock Screener</a>",
      feature_desc: "Intuitive query language to filter stocks any way you like instead of pre-canned bins. Save as many Screens you like.",
    },
    {
      feature_icon: <ScheduleRoundedIcon />,
      feature_name: "<a href='/stock/AAPL'>Five-Minute Due Diligence</a>",
      feature_desc: "Quickest way to perform due-diligence on any stock. Most comprehensive and in-depth one-pager on the internet.",
    },
    {
      feature_icon: <ApartmentRoundedIcon />,
      feature_name: "<a href='/us-economy'>US Economy</a>",
      feature_desc: "Get a hold on the US economy. <br><a href='/us-economy/us-gdp'>GDP Breakout</a>, <a href='/us-economy/us-housing-market'>Housing Industry</a>, <a href='/us-economy/us-consumer'>Consumer Strength</a>, <a href='/us-economy/us-consumer'>Retail Sales</a> etc.",
    },
    {
      feature_icon: <MonetizationOnRoundedIcon />,
      feature_name: "<a href='/funds/1067983'>Fund Holdings</a>",
      feature_desc: "Equity portfolio of 1000s of active funds such as <a href='/funds/1067983'>Buffett</a>, <a href='/funds/921669'>Carl Icahn</a>, Cathie Woods etc. <br>What stocks are the funds buying or selling? <br>Historical trend of purchase of a stock by a fund",
    },
    {
      feature_icon: <MultilineChartRoundedIcon />,
      feature_name: "<a href='/us-economy/yield-curve'>Daily Yield Curve Report</a>",
      feature_desc: "Get most comprehensive analysis on daily movement of yield curve on the internet.",
    },
    {
      feature_icon: <SsidChartRoundedIcon />,
      feature_name: "<a href='/stock'>Daily Stock Discovery</a>",
      feature_desc: "Great tool for stock discovery especially for lesser known micro-caps and mid-caps.<br>Which stocks made 52-week highs? Which stocks had the most earnings in the industry?<br> Which stocks were crushed recently? Which have been multi-baggers ?",
    },
    {
      feature_icon: <QueryStatsRoundedIcon />,
      feature_name: "<a href='/stock/AAPL'>Fund activity for stocks</a>",
      feature_desc: "Are funds buying or selling the stock? <br>What funds have sold the stock? Which opened new positions? <br>Trend of number of funds interested in the stock.",
    },
    {
      feature_icon: <TrendingDownRoundedIcon />,
      feature_name: "<a href='/stock/AAPL/Insider-Transactions'>Insider Trading Data</a>",
      feature_desc: "Quick look at the Insider Transaction as on a stock. <br>Have there been any unusual sales by insiders? <br>Are the insiders selling at a faster or slower speed than before?",
    },
    {
      feature_icon: <ManageSearchRoundedIcon />,
      feature_name: "<a href='/stock/AAPL'>Historical Returns</a>",
      feature_desc: "What are the historical Year-over-Year Returns, Rolling returns etc.?",
    },
    {
      feature_icon: <HourglassTopRoundedIcon />,
      feature_name: "<a href='/stock/AAPL'>Risk Assessment</a>",
      feature_desc: "What is the probability of a 20 or a 40% paper loss on a stock? <br>What has been the historical drawdown on the stock? <br>How is stock's volatility vs S&P500?",
    },
    {
      feature_icon: <ScatterPlotRoundedIcon />,
      feature_name: "<a href='/stock/AAPL/Ratio-Metrics'>Ratios and Metrics</a>",
      feature_desc: "History of important ratios for stocks. <br>Valuation Metrics - Trends for PE, Price-to-Sales, EV/EBT etc.</br><br>Profitability Metrics - ROE, Return-On-Assets etc.</br><br>Financial Health - Debt-to-Equity Ratio, Current Ratio etc.</br><br>Operational Performance - Asset-to-Sales Ratio, Sales-to-Inventory etc. </br>",
    },
    {
      feature_icon: <TableChartRoundedIcon />,
      feature_name: "<a href='/stock/AAPL'>Financial Reports and Data</a>",
      feature_desc: "Important tables from financial statements reported by companies. <br>Hundreds of time series per stock such as Revenue (Trailing 12 Months),  Income Growth, Assets Growth etc.",
    },
    {
      feature_icon: <NotificationsActiveIcon />,
      feature_name: "<a href='/stock/AAPL/'>Alerts on Stocks and macro movements</a>",
      feature_desc: "Alerts are activated on every stock on unusual activities such as - <br>High insider trading activity</br><br>Big loss in Income or a dip in revenue</br><br>Unusual interest from funds</br>",
    }
  ],
  // SESSION: {
  //   ID: '?session_id=',
  //   URL: '/pay-status/true'
  // },
  // STUDENT_DOC_STATE: {
  //   NONE: null,
  //   UPLOADED: "UPLOADED",
  //   VERIFIED: "VERIFIED",
  // },
  // LAST_VER_MAIL: "last-v-mail",
  // HELP_TYPES: {
  //   SEIRES_CODE: "series-code",
  // },
  CHART_BAR_TYPES: {
    DEFAULT: "default",
    SIDED: "sided",
  },
  ECO_TABS: {
    GLANCE: "macro-summary",
    HOUSING: "us-housing-market",
    GDP: "us-gdp",
    CONSUMER: "us-consumer",
    YIELD: "yield-curve",
  },
  ECO_TABS_CODES: {
    GLANCE: "GLANCE",
    HOUSING: "HOUSING",
    GDP: "GDP",
    CONSUMER: "CONSUMER",
    YIELD: "YIELD",
  },
  ECO_SECTION_CODES: {
    GLA_DATA: "glance_data",
    GLA_REAL_GDP: "glance_prod_realgdp_c",
    GLA_GDP_BREAK: "glance_prod_gdpbreak_t",
    GLA_IND_PRO: "glance_prod_indpro_c",
    GLA_INFLAT_CPI: "glance_inflation_cpi_c",
    GLA_INFLAT_PCE: "glance_inflation_pce_c",
    GLA_UEMP_U6: "glance_unemp_u6_c",
    GLA_UEMP_NON_FARN: "glance_unemp_nonfarm_c",
    GLA_UEMP_PARTRATE: "glance_unemp_partrate_c",
    GLA_RET_SALES: "glance_cons_retsales_c",
    GLA_CONSUMPTION: "glance_cons_consumption_c",
    GLA_CPI_TABLE: "glance_prod_cpi_t",
    GLA_SAVING_TREND: "glance_cons_savings_c",
    HOU_DATA: "housing_data",
    HOU_OVER_PRICE: "housing_over_price_c",
    HOU_OVER_SALES: "housing_over_sales_c",
    HOU_PERMITS: "housing_newConst_permits_c",
    HOU_OVER_OWN: "housing_over_ownership_c",
    HOU_UNDER_CONST: "housing_newConst_underconst_c",
    HOU_CONST_TYPE: "housing_newConst_type_c",
    HOU_HOME_SOLD: "housing_newHome_sold_c",
    HOU_HOME_SOLD_REG: "housing_newHome_sold_reg_c",
    HOU_HOME_SOLD_TYPE: "housing_newHome_sold_type_c",
    HOU_FORSALE_MTH: "housing_forsale_mths_c",
    HOU_FORSALE_STAGE: "housing_forsale_stage_c",
    HOU_FORSALE_MOS: "housing_forsale_mos_c",
    HOU_VACANCY_RATE: "housing_vacancy_rate_c",
    HOU_VACANCY_REG: "housing_vacancy_regional_c",
    HOU_VACANCY_RGE_OWN: "housing_vacancy_regionOwn_c",
    HOU_MFG_PRICE: "housing_mfghome_price_c",
    HOU_MFG_REG: "housing_mfghome_regional_c",
    HOU_MEG_SALES: "housing_mfghome_sales_c",
    //HOU_HOME_SOLD_SERIES: 'housing_newHome_sold_s',
    GDP_BREAK: "gdp_breakup_data",
    GDP_BREAK_CHART: "gdp_breakup_c",
    GDP_BREAK_T: "gdp_breakup_t",
    GDP_BREAK_TABLE: "gdp_breakup_table",
    GDP_HIST_PCT: "gdp_hist_pct_c",
    GDP_HIST_DOLLAR: "gdp_hist_dollar_c",
    CONSUMER_DATA: "consumer_data",
    CONS_H_REAL_CHART: "consumer_conshealth_realpce_c",
    CONS_H_REAL_DIS_CHART: "consumer_conshealth_dispinc_c",
    CONS_H_TREND: "consumer_conshealth_Trend",
    CONS_C_DUR: "consumer_consumption_durables",
    CONS_C_NON_DUR: "consumer_consumption_nondurables",
    CONS_C_TREND: "consumer_consumption_trends",
    CONS_RE_TOTAL: "consumer_retail_total",
    CONS_RE_BREAK: "consumer_retail_breakup",
    CONS_RE_TREND: "consumer_retail_trend",
    CONS_RE_TREND_SAVING: "consumer_retail_Trends_Savings",
    CONS_RE_TREND_ONLINE: "consumer_retail_Trends_Onlinevs",
    CONS_RE_TREND_HIST: "consumer_retail_Trends_HistOnline",
    YIELD_DATA: "yieldcurve_data",
    YIELD_CHART: "yieldcurve_c",
    YIELD_WEEK_CHART: "yieldcurve_finer_week_c",
    YIELD_YEST_CHART: "yieldcurve_finer_yester_c",
    YIELD_INVER_HIST_CHART: "yieldcurve_inversion_hist_c",
    YIELD_INVER_LONG_CHART: "yieldcurve_inversion_Long_c",
    YIELD_INVER_BELLY_CHART: "yieldcurve_inversion_belly_c",
    YIELD_RATES_10_CHART: "yieldcurve_rates_10_c",
    YIELD_RATES_1_CHART: "yieldcurve_rates_1_c",
    YIELD_EXP_2000_CHART: "yieldcurve_examples_2000",
    YIELD_EXP_2008_CHART: "yieldcurve_examples_2008",
  },
  // CHART_SERIES_TYPES: {
  //   line: { icon: "fa-line-chart" },
  //   area: { icon: "fa-area-chart" },
  //   bar: { icon: "fa-bar-chart" },
  // },
  FIN_DOCS_PERIOD: {
    ALL: "All",
    QUARTERLY: "Quarterly",
    ANNUAL: "Annual",
  },
  // EMBED_CARD_SIZES: {
  //   SMALL: { txt: "small", val: "320px" },
  //   STANDARD: { txt: "regular", val: "480px" },
  //   LARGE: { txt: "large", val: "800px" },
  // },
  // CUSTOM_PAGE_TYPES: {
  //   DASHBOARD: "DASHBOARD",
  //   BLOG: "BLOG",
  // },
  // PAGE_WIDGETS_SIZES: {
  //   25: "col-md-3",
  //   33: "col-md-4",
  //   50: "col-md-6",
  //   66: "col-md-8",
  //   75: "col-md-9",
  //   100: "col-md-12",
  // },
  // PAGE_WIDGET_TYPES: {
  //   CHART: "CHART",
  //   TABLE: "TABLE",
  //   TEXT: "TEXT",
  //   EMBED: "EMBED",
  //   TICKERTABLE: "TICKERTABLE",
  // },
  // PAGE_MAX_WIDTH: 992,
  // TICKER_COL_DEFINATIONS: [
  //   {
  //     name: "Ticker",
  //     code: "TICKER",
  //     value: "#ticker",
  //     type: "Static",
  //     link: "true",
  //     order: 1,
  //   },
  //   { name: "Name", code: "NAME", value: "#name", type: "Static", order: 2 },
  //   { name: "Price", code: "PRICE", value: "#price", type: "Static", order: 3 },
  //   {
  //     name: "Prev Price",
  //     code: "PREV_PRICE",
  //     value: "#prev_price",
  //     type: "Static",
  //     order: 4,
  //   },
  //   {
  //     name: "Diff",
  //     code: "DIFF",
  //     value: "#price - #prev_price",
  //     type: "Static",
  //     order: 5,
  //   },
  //   {
  //     name: "%Chg",
  //     code: "%CHG",
  //     value: "(#price - #prev_price)* 100 / #prev_price",
  //     type: "Static",
  //     order: 6,
  //   },
  //   {
  //     name: "%Chg1Y",
  //     code: "%CHG1Y",
  //     value: "(#price - #yearPrice)* 100 / #yearPrice",
  //     type: "Static",
  //     order: 7,
  //   },
  //   {
  //     name: "%ChgWuhan",
  //     code: "%CHGWUHAN",
  //     value: "(#price - #wuhanPrice)* 100 / #wuhanPrice",
  //     type: "Static",
  //     order: 8,
  //   },
  //   {
  //     name: "#date",
  //     code: "DATE",
  //     value: "(#price - #dateVal) * 100/ #dateVal",
  //     type: "Dynamic",
  //     order: 9,
  //   },
  // ],
  DEFAULT_TICKER: "AAPL",
  HEADER_DROPDOWNS: {
    RESEARCH: "RESEARCH",
    TOOLS: "TOOLS",
  },
  // COVID19_SECTIONS: {
  //   CORONA_DATA: "corona_data",
  //   CORONA_DATA_METRICS: "corona_data_metrics",
  //   US_HEAD_CASES: "us_head_cases_c",
  //   US_TOTAL_CASES: "us_cases_cum_c",
  //   US_TOTAL_DEATHS: "us_deaths_cum_c",
  //   US_TESTS: "us_tests_cum_c",
  //   US_TESTS_POSITIVE: "us_tests_positive_c",
  //   US_STATE_TABLE: "us_state_table",
  //   US_DATA_METRICS: "corona_data_metrics",
  //   US_STATES_TOP_CASES: "us_state_top_cases",
  //   US_STATES_TOP_DEATHS: "us_state_top_deaths",
  //   US_CFR: "us_cfr",
  //   US_DAILY_GROWTH: "us_daily_growth",
  //   US_DOUBLING_DAYS: "us_doubling_days",
  //   US_ACTIVE_CASES: "us_active_cases",
  //   US_HOSP_CHG: "us_hosp_chg",
  //   US_RECOVERY: "us_recovery",
  //   US_GRID: "us_grid",
  //   STATE_HEAD_CASES: "state_head_cases_c",
  //   STATE_TOTAL_CASES: "state_cases_cum_c",
  //   STATE_TOTAL_DEATHS: "state_deaths_cum_c",
  //   STATE_TOTAL_TESTS: "state_tests_cum_c",
  //   STATE_COUNTY_TABLE: "us_county_table",
  //   STATE_TESTS_POSITIVE: "state_tests_positive_c",
  //   STATE_COUNTY_TOP_CASES: "state_county_top_cases",
  //   STATE_COUNTY_TOP_DEATHS: "state_county_top_deaths",
  //   STATE_CFR: "state_cfr",
  //   STATE_DAILY_GROWTH: "state_daily_growth",
  //   STATE_DOUBLING_DAYS: "state_doubling_days",
  //   STATE_HOSP_CHG: "state_hosp_chg",
  //   STATE_CASE_RANK: "state_case_growth_rank",
  //   STATE_TEST_RANK: "state_test_growth_rank",
  //   COUNTY_HEAD_CASES: "county_head_cases_c",
  //   COUNTY_TOTAL_CASES: "county_cases_cum_c",
  //   COUNTY_TOTAL_DEATHS: "county_deaths_cum_c",
  //   COUNTY_CASES_MILL: "county_cases_1m_c",
  //   COUNTY_DEATHS_MILL: "county_deaths_1m_c",
  //   COUNTY_CFR: "county_cfr",
  //   COUNTY_DAILY_GROWTH: "county_daily_growth",
  //   COUNTY_DOUBLING_DAYS: "county_doubling_days",
  //   COUNTY_ALL_CASES_RANK: "county_all_cases_rank",
  //   COUNTY_CASE_RANK: "county_case_growth_rank",
  //   COUNTY_DEATHS_RANK: "county_death_growth_rank",
  //   STATE_CASES_GRID: "state_grid",
  // },
  TIME_UNITS: {
    YEARS: "years",
    MONTHS: "months",
    WEEKS: "weeks",
    DAYS: "days",
    HOURS: "hours",
    MINS: "minutes",
    SECONDS: "seconds",
  },
  // MODAL_TYPES: {
  //   CHART: "CHART",
  //   TABLE: "TABLE",
  // },
  // TABLE_DT_TYPES: {
  //   LINK: "LINK",
  //   SERIES: "SERIES",
  //   TABLE: "TABLE",
  //   OUTLINK: "OUTLINK",
  // },
  // US_COVID_DROPS: {
  //   TOP: "TOP",
  //   CASES: "CASES",
  //   DEATHS: "DEATHS",
  // },
  SORT_DIR: {
    ASC: "asc",
    DESC: "desc",
  },
  // GEO_LEVEL: {
  //   COUNTRY: "COUNTRY",
  //   STATE: "STATE",
  //   COUNTY: "COUNTY",
  // },
  // GEO_PAGES_CODE: {
  //   COVID19: "COVID19",
  // },
  // COVID19_TABS: {
  //   SUM: { code: "SUM", url: "" },
  //   CASES: { code: "CASES", url: "cases" },
  //   CASES_1M: { code: "CASES_1M", url: "cases-1m" },
  //   CASES_CHG_7AVG: { code: "CASES_CHG_7AVG", url: "cases-chg-7avg" },
  //   NEW_CASES_PCT: { code: "NEW_CASES_PCT", url: "cases-pct" },
  //   CASES_CFR: { code: "CASES_CFR", url: "cases-cfr" },
  //   DEATHS: { code: "DEATHS", url: "deaths" },
  //   DEATHS_1M: { code: "DEATHS_1M", url: "deaths-1m" },
  //   DEATHS_CHG_7AVG: { code: "DEATHS_CHG_7AVG", url: "deaths-chg-7avg" },
  //   DOUBLING: { code: "DOUBLING", url: "doubling-days" },
  //   TESTS_1M: { code: "TESTS_1M", url: "tests-1m" },
  //   TESTS_POSPCT: { code: "TESTS_POSPCT", url: "test-positive-pct" },
  // },
  DEVICE_SIZES: {
    SMALL: "SMALL",
    MEDIUM: "MEDIUM",
    LARGE: "LARGE",
    LARGER: "LARGER",
  },
  FUNDS_SECTIONS: {
    FUND_HOLDINGS: "fund_holdings",
    FUND_BUYS: "fund_buy",
    FUND_SELLS: "fund_sell",
    FUND_CHANGED: "fund_changed",
    FUND_LATEST: "fund_latest",
    FUND_SHR: "fund_shr",
    FUND_SHR_CHG: "fund_shr_chg",
    FUND_PORT_PCT: "fund_port_pct",
    FUND_MAIN_SERIES: "fund_main_series",
    FUND_PORT_C: "fund_port_c",
    FUND_13G: "fund_13g",
    FUND_HR: "fund_hr",
    FUND_TOP_STOCKS: "fund_topstock",
    FUND_TOP: "fund_top",
    FUNDS_SERIES: "funds_series",
    FUNDS_EQUITY_PCT: "funds_equity_pct",
    FUNDS_SEARCHES: "funds_searches",
    FUND_TICKERS: "fund_tickers",
    FUND_ADDS: "fund_added",
    FUND_REDUCED: "fund_reduced",
  },
  // GRID_CHART_PAGE_SIZES: {
  //   SMALL: 8,
  //   MEDIUM: 12,
  //   LARGE: 20,
  // },
  HOME_SECTIONS: {
    HOME_TOP_CHART: "home_top_c",
    HOME_TOP_METRICS: "home_top_metrics",
    HOME_SECTORS: "home_sectors",
    HOME_CAP_SIZE: "home_cap_size",
    RECENT_EARNING: "recent_earning",
    RECENT_FUND_HOLDING: "recent_fund_holding",
    HOME_SERIES: "home_series",
    STOCK_RECENTS: "stock_recents",
    STOCK_SELLS: "stock_sells",
    STOCK_BUYS: "stock_buys",
    STOCK_OUTS: "stock_outs",
    HOME_WIDGET: "home_widget",
    STOCK_LAGGARDS: "stock_laggards",
    HOME_SECTOR_BOTTOM: "home_sector_bottom",
    HOME_TOP_RIGHT: "home_top_cr",
    HOME_TOP_RIGHT_BOTTOM: "home_top_crb",
    HOME_WIDGET_CHART: "home_widget_c",
    HOME_YIELD_DATA: "home_yield_data",
  },
  // SEARCH_CONSTANTS: {
  //   STOCKS: "Stocks",
  //   STOCKTYPE: "STOCK",
  //   CHARTS: "Charts",
  //   CHARTTYPE: "CHART",
  //   FUNDS: "Funds",
  //   FUNDTYPE: "FUND",
  //   BLOGS: "Blogs",
  //   BLOGTYPE: "BLOG",
  //   DASHBOARD: "Dashboards",
  //   DASHBOARDTYPE: "DASHBOARD",
  //   ECONOMY: "Economy",
  //   ECONOMYTYPE: "ECONOMY",
  //   OTHERS: "Others",
  //   PLACEHOLDER_TEXT: "Search Grufity",
  //   FUNDS_SEARCH_PLACEHOLDER: "Search Funds",
  // },
  // COMPARE_STOCK: {
  //   TAB_CODE: 'COMP',
  //   PAGE_TYPE: 'STOCK',
  //   METRIC_FIELD_NAME: 'code',
  //   SECTION_CODES: {
  //     METRICS: 'Metrics',
  //     METADATA: 'METADATA',
  //   },
  //   SECTION_TYPES: {
  //     CHART: 'CHART',
  //     SERIES: 'SERIES',
  //     METRICS: 'LATESTMETRICS',
  //   },
  // },
  SCREENER: {
    MET_CAP_CAT: {
      MEGA_CAP: 'MEGA_CAP',
      LARGE_CAP: 'LARGE_CAP',
      MID_CAP: 'MID_CAP',
      SMALL_CAP: 'SMALL_CAP',
      MICRO_CAP: 'MICRO_CAP',
      NANO_CAP: 'NANO_CAP',
    },
    CONDITION: {
      LESS_THAN: 'LESS_THAN',
      GREATER_THAN: 'GREATER_THAN',
      EQUALS: 'EQUALS',
      BETWEEN: 'BETWEEN',
    },
  },
  REVALIDATE_IN: {
    THREE_MINUTES: 200,
    ONE_HOUR: 3600,
    SIX_HOURS: 21600,
    TWELVE_HOURS: 43200,
    DEFAULT_HOURS: 28800,
    ONE_DAY: 86400,
    _24HOURS: 86400,
    SEVEN_DAYS: 604800,
    ONE_MONTH: 2592000,
  },
  TOP_TWO_BLOGS: 2,
  TOP_IDEA: 1,
  TEN_STOCKS: 1,
  HUNDRED_FUNDS: 1,
  TIME_LIMIT: 480,
  // TIME_LIMIT: 10,
  DEVPLANS: [
    {
      "id": 104,
      "plan": "Premium",
      "features": {
        "desc": "Complete website access for $8.33 / month",
        "features": [
          "Detailed Stock Analysis",
          "Premium Financial and US Macro Data"
        ],
        "recommended": true,
        "dFeatures": [
          {
            "title": "Detailed Stock Information:",
            "items": [
              "Stock summary",
              "Historical returns",
              "Ratios and Metrics",
              "Fundamental Data series"
            ]
          },
          {
            "title": "Access premium data and analysis:",
            "items": [
              "Insider trading",
              "Institutional holdings",
              "Fair Value of Stocks"
            ]
          }
        ],
        "qAndA": [
          {
            "q": "What kind of payment methods do you accept?",
            "a": "We currently accept any credit or debit card with a MasterCard, Visa, Discover Network, American Express or Diners Club International. All credit card payments are securely processed through Stripe."
          },
          {
            "q": "I want you to cover a particular stock.",
            "a": "Currently, we are covering just US stocks (5000+ tickers). If you want us to cover a particular stock, just email the request to support@grufity.com or DM to twitter handles @Grufity or @grufityreports. We will onboard it if possible else we will get back to you on when we can."
          },
          {
            "q": "Could you add a particular chart or data point to the stock/economy pages?",
            "a": "Yes. We are open to suggestions. If there's a data point or chart you would like to see for all stocks then it can be added. We show only 10% of the data that we have so as to keep it succint. We do share a lot of extra data on twitter @grufity and @grufityReports when those come into focus. You can interact with us on our twitter handles. You can DM us there or email to support@grufity.com with suggestions."
          },
          {
            "q": "Can I access your data through API?",
            "a": "Not yet. But we are considering exposing our APIs."
          },
          {
            "q": "What if I want to cancel my service?",
            "a": "No problem. If you decide Grufity isn't for you, you can cancel with a single click from your account page."
          },
          {
            "q": "Can I get a refund?",
            "a": "We want you to have a good experience with us. If you are not happy, you can email us at support@grufity.com."
          }
        ]
      },
      "y": 9.99,
      "m": 99.99,
      "im": 800,
      "iy": 8000,
      "mpid": "price_1LsiQdCdnFB4AZORryoRdkxv",
      "ypid": "price_1LsiQdCdnFB4AZORSB6ZCwDK",
      "student_m": null,
      "student_y": null,
      "type": "PREMIUM",
      "order": 2
    },
    {
      "id": 103,
      "plan": "Enterprise",
      "features": {
        "desc": "Customizable data and research solutions for small and large enterprises",
        "features": [
          "All Professional Plan features",
          "Customised Data Requirements",
          "API Access with Unlimited API calls",
          "Custom Charting Requirements",
          "Back Office Support"
        ],
        "recommended": false
      },
      "y": null,
      "m": null,
      "im": null,
      "iy": null,
      "mpid": null,
      "ypid": null,
      "student_m": null,
      "student_y": null,
      "type": "ENTERPRISE",
      "order": 3
    }
  ],
  PRODPLANS: [
    {
      "id": 105,
      "plan": "Premium",
      "features": {
        "desc": "Complete website access for $8.33 / month",
        "features": [
          "Detailed Stock Analysis",
          "Premium Financial and US Macro Data"
        ],
        "recommended": true,
        "dFeatures": [
          {
            "title": "Detailed Stock Information:",
            "items": [
              "Stock summary",
              "Historical returns",
              "Ratios and Metrics",
              "Fundamental Data series"
            ]
          },
          {
            "title": "Access premium data and analysis:",
            "items": [
              "Insider trading",
              "Institutional holdings",
              "Fair Value of Stocks"
            ]
          }
        ],
        "qAndA": [
          {
            "q": "What kind of payment methods do you accept?",
            "a": "We currently accept any credit or debit card with a MasterCard, Visa, Discover Network, American Express or Diners Club International. All credit card payments are securely processed through Stripe."
          },
          {
            "q": "I want you to cover a particular stock.",
            "a": "Currently, we are covering just US stocks (5000+ tickers). If you want us to cover a particular stock, just email the request to support@grufity.com or DM to twitter handles @Grufity or @grufityreports. We will onboard it if possible else we will get back to you on when we can."
          },
          {
            "q": "Could you add a particular chart or data point to the stock/economy pages?",
            "a": "Yes. We are open to suggestions. If there's a data point or chart you would like to see for all stocks then it can be added. We show only 10% of the data that we have so as to keep it succint. We do share a lot of extra data on twitter @grufity and @grufityReports when those come into focus. You can interact with us on our twitter handles. You can DM us there or email to support@grufity.com with suggestions."
          },
          {
            "q": "Can I access your data through API?",
            "a": "Not yet. But we are considering exposing our APIs."
          },
          {
            "q": "What if I want to cancel my service?",
            "a": "No problem. If you decide Grufity isn't for you, you can cancel with a single click from your account page."
          },
          {
            "q": "Can I get a refund?",
            "a": "We want you to have a good experience with us. If you are not happy, you can email us at support@grufity.com."
          }
        ]
      },
      "y": 99.99,
      "q": 49.9,
      "m": 9.99,
      "im": 800,
      "iq": 3999,
      "iy": 8990,
      "em": 9.99,
      "eq": 49.9,
      "ey": 99.99,
      "gm": 9.99,
      "gq": 49.9,
      "gy": 99.99,
      "mpid": "price_1LskeQCdnFB4AZORLZ5pPZVf",
      "qpid": "price_1N1QSyCdnFB4AZORXRXZLTOF",
      "ypid": "price_1LskeQCdnFB4AZORLqu3ec5r",
      // "mpid": "price_1N2BTACdnFB4AZOR4pjGQq1N",
      // "qpid": "price_1N1QaDCdnFB4AZORH2X52XXL",
      // "ypid": "price_1LsiQdCdnFB4AZORSB6ZCwDK",
      "student_m": null,
      "student_y": null,
      "type": "PREMIUM",
      "order": 4
    },
    {
      "id": 103,
      "plan": "Enterprise",
      "features": {
        "desc": "Customizable data and research solutions for small and large enterprises",
        "features": [
          "All Professional Plan features",
          "Customised Data Requirements",
          "API Access with Unlimited API calls",
          "Custom Charting Requirements",
          "Back Office Support"
        ],
        "recommended": false
      },
      "y": null,
      "m": null,
      "im": null,
      "iy": null,
      "mpid": null,
      "ypid": null,
      "student_m": null,
      "student_y": null,
      "type": "ENTERPRISE",
      "order": 3
    }
  ],
  ENCRYPTION_KEY: 'b3f157ada770e67effc3feba173584bcef0eaa5ea59408f461388b3bdcf107ff',
  DECRYPTION_KEY: 'b3f157ada770e67effc3feba173584bcef0eaa5ea59408f461388b3bdcf107ff',

  SCREENER_OPTIONS: [
    { "shortName": "Assets To Sales", "aggCode": "ASS.SAL", "legend": "Assets-to-Sales Ratio", "description": "The Assets-to-Sales Ratio measures how efficiently a company utilizes its assets to generate sales revenue." },
    { "shortName": "Assets", "aggCode": "ASSETS", "legend": "Total Assets", "description": "Total Assets represents the total value of a company's resources, including cash, investments, and physical assets." },
    { "shortName": "Curr Assets", "aggCode": "ASSETS.CUR", "legend": "Current Assets", "description": "Current Assets include assets like cash, accounts receivable, and inventory, which can be quickly converted into cash within a year." },
    { "shortName": "Assets 1Y Grw", "aggCode": "ASSETS|PCHG.1Y", "legend": "Percentage change in total assets over the last 1 year", "description": "Assets 1Y Grw measures the percentage change in a company's total assets over the past 1 year." },
    { "shortName": "Cash Eqs", "aggCode": "CASHEQ", "legend": "Cash and Cash Equivalents", "description": "Cash Eqs represents the sum of cash on hand and highly liquid assets that can be easily converted to cash." },
    { "shortName": "Cash From Fin", "aggCode": "CFFIN", "legend": "Cash From Financing Activities", "description": "Cash From Fin refers to cash generated or spent as a result of financing activities, such as issuing or repurchasing stock." },
    { "shortName": "Cash From Fin TTM", "aggCode": "CFFIN.TTM", "legend": "Cash From Financing Activities (TTM)", "description": "Cash From Fin TTM is the total cash generated or spent from financing activities over the trailing twelve months." },
    { "shortName": "Cash From Inv", "aggCode": "CFINV", "legend": "Cash From Investing Activities", "description": "Cash From Inv represents cash generated or spent due to investment activities, like buying or selling assets or securities." },
    { "shortName": "Cash From Inv TTM", "aggCode": "CFINV.TTM", "legend": "Cash From Investing Activities (TTM)", "description": "Cash From Inv TTM is the total cash generated or spent from investing activities over the trailing twelve months." },
    { "shortName": "Cash from Oper", "aggCode": "CFOPER", "legend": "Cash From Operating Activities", "description": "Cash from Oper reflects the cash generated or spent as a result of a company's core operating business activities." },
    { "shortName": "Cash from Oper TTM", "aggCode": "CFOPER.TTM", "legend": "Cash From Operating Activities (TTM)", "description": "Cash from Oper TTM represents the total cash generated or spent from core operating activities over the trailing twelve months." },
    { "shortName": "Cost of Rev", "aggCode": "COSTREV", "legend": "Cost of Revenue", "description": "Cost of Rev is the expenses directly associated with producing goods or services, deducted from revenue to calculate gross profit." },
    { "shortName": "Current Ratio", "aggCode": "CR", "legend": "Current Ratio", "description": "The Current Ratio measures a company's ability to cover short-term liabilities with its current assets. It's a liquidity indicator." },
    { "shortName": "Debt To Eq", "aggCode": "DEBT.EQ", "legend": "Debt To Equity Ratio", "description": "Debt To Eq indicates the proportion of a company's debt compared to shareholders' equity, assessing financial leverage." },
    { "shortName": "LT Debt", "aggCode": "DEBT.LT", "legend": "Long Term Debt", "description": "LT Debt represents debts and obligations with a maturity period of more than one year." },
    { "shortName": "Debt To Assets", "aggCode": "DEBT.RAT", "legend": "Debt To Assets Ratio", "description": "Debt To Assets Ratio indicates the proportion of a company's total assets financed by debt, assessing financial risk." },
    { "shortName": "Deprec TTM", "aggCode": "DEPAMOR.TTM", "legend": "Depreciation and Amortization TTM", "description": "Deprec TTM represents the total depreciation and amortization expenses over the trailing twelve months." },
    { "shortName": "Div Per Sh TTM", "aggCode": "DIV.PS.TTM", "legend": "Cash dividends declared per share (Trailing 12-Months)", "description": "Div Per Sh TTM is the total cash dividends declared per share over the trailing twelve months." },
    { "shortName": "Div Yld", "aggCode": "DIV.YIELD", "legend": "Dividend Yield", "description": "Div Yld represents the dividend income earned from an investment as a percentage of its current market price." },
    { "shortName": "Drawdowns", "aggCode": "DRAWDN", "legend": "DrawDown", "description": "Drawdowns measure the peak-to-trough decline in an investment's value during a specific period, indicating risk." },
    { "shortName": "Prob 30 DW", "aggCode": "DRAWDN.PROB30|5Y", "legend": "Probability of 30% Drawdown in a 1-Year holding period", "description": "Prob 30 DW assesses the likelihood of a 30% or more drawdown in an investment's value within a 1-year holding period." },
    { "shortName": "EBIT TTM", "aggCode": "EBIT.TTM", "legend": "Earnings Before Interest and Taxes", "description": "EBIT TTM represents a company's earnings before deducting interest and taxes, a key measure of operational profitability." },
    { "shortName": "EBIT 1Y Grw", "aggCode": "EBIT.TTM|PCHG.1Y", "legend": "1-Year Growth of Earnings Before Interest and Taxes TTM", "description": "EBIT 1Y Grw measures the percentage change in a company's Earnings Before Interest and Taxes over the past 1 year." },
    { "shortName": "EBIT 3Y Grw", "aggCode": "EBIT.TTM|PCHG.3Y", "legend": "3-Year Growth of Earnings Before Interest and Taxes TTM", "description": "EBIT 3Y Grw measures the percentage change in a company's Earnings Before Interest and Taxes over the past 3 years." },
    { "shortName": "EBITDA Margin", "aggCode": "EBITDA.MGN", "legend": "Earnings Before Taxes, Interest, Depreciation, and Amortization Margin", "description": "EBITDA Margin indicates the proportion of a company's revenue that remains as operating profit after excluding certain expenses." },
    { "shortName": "EBITDA TTM", "aggCode": "EBITDA.TTM", "legend": "Earnings Before Taxes, Interest, Dep. and Amort. (EBITDA)", "description": "EBITDA TTM represents a company's earnings before taxes, interest, depreciation, and amortization over the trailing twelve months." },
    { "shortName": "EBT Margin", "aggCode": "EBT.MGN", "legend": "Earnings Before Tax Margin", "description": "EBT Margin measures the profitability of a company by assessing the percentage of revenue that remains as earnings before taxes." },
    { "shortName": "EBT TTM", "aggCode": "EBT.TTM", "legend": "Earnings Before Tax (Last 12 Months)", "description": "EBT TTM represents a company's earnings before tax over the last 12 months." },
    { "shortName": "EPS Basic", "aggCode": "EPS", "legend": "Earnings Per Share, Basic", "description": "EPS Basic is the portion of a company's profit allocated to each outstanding common share." },
    { "shortName": "EPS Basic TTM", "aggCode": "EPS.TTM", "legend": "Earnings Per Share, Basic (Last 12 Months)", "description": "EPS Basic TTM represents the earnings per share for the last 12 months." },
    { "shortName": "EV", "aggCode": "EV", "legend": "Enterprise Value", "description": "EV is a comprehensive measure of a company's total value, including its market capitalization and debt, minus cash and cash equivalents." },
    { "shortName": "EV EBITDA", "aggCode": "EV.EBITDA", "legend": "EV to EBITDA Ratio", "description": "EV EBITDA is a valuation ratio that assesses a company's overall value in relation to its earnings before interest, taxes, depreciation, and amortization." },
    { "shortName": "FCF PSh TTM", "aggCode": "FCF.PS.TTM", "legend": "Free Cashflow Per Share, Trailing 12 Months", "description": "FCF PSh TTM represents the free cash flow per share over the trailing 12 months, indicating the cash generated by a company's operations available to shareholders." },
    { "shortName": "FCF TTM", "aggCode": "FCF.TTM", "legend": "Free Cashflow (FCF), Trailing 12-Months", "description": "FCF TTM represents the total free cash flow generated by a company over the trailing twelve months, indicating its ability to generate cash from its core operations." },
    { "shortName": "FCF Yield", "aggCode": "FCF.YIELD", "legend": "Free Cashflow Yield", "description": "FCF Yield represents the ratio of free cash flow to a company's market capitalization, indicating the return on investment through cash generation." },
    { "shortName": "Goodwill", "aggCode": "GWILL", "legend": "Goodwill", "description": "Goodwill represents the intangible asset on a company's balance sheet, reflecting the premium paid for acquisitions." },
    { "shortName": "Asset Imp", "aggCode": "IMP.ASSETS", "legend": "Impairment of Assets", "description": "Asset Impairment refers to the reduction in the value of a company's assets, typically due to a decline in their recoverable value." },
    { "shortName": "Impair Goodwill", "aggCode": "IMP.GWILL", "legend": "Goodwill Impairment", "description": "Impair Goodwill represents the reduction in the value of a company's goodwill asset, often due to a decrease in its fair market value." },
    { "shortName": "Industry", "aggCode": "industry", "legend": "Industry", "description": "Industry refers to the specific sector or category in which a company operates, classifying it based on its primary business activities." },
    { "shortName": "Inst Pct", "aggCode": "INSTPCT", "legend": "Percentage Shares held by Institutional Investors", "description": "Inst Pct measures the portion of a company's shares held by institutional investors, such as mutual funds and pension funds." },
    { "shortName": "Int To CF", "aggCode": "INT.CFOPER", "legend": "Interest to Cashflow Ratio", "description": "Int To CF assesses the proportion of a company's interest expenses relative to its cash flow from operating activities, indicating its financial obligations." },
    { "shortName": "Int Cov", "aggCode": "INT.COV", "legend": "Interest Coverage Ratio", "description": "Int Cov measures a company's ability to meet its interest payments on debt obligations using its earnings before interest and taxes (EBIT)." },
    { "shortName": "Invy WD", "aggCode": "INVWD", "legend": "Inventory Writedown", "description": "Invy WD represents the reduction in the value of a company's inventory due to impairment, damage, or obsolescence." },
    { "shortName": "Inventory", "aggCode": "INVY", "legend": "Inventory", "description": "Inventory refers to the goods, raw materials, or finished products held by a company for the purpose of production or resale." },
    { "shortName": "Inc Tax Rate", "aggCode": "ITXR", "legend": "Income Tax Rate", "description": "Inc Tax Rate is the percentage of a company's earnings that is paid in income taxes to the government." },
    { "shortName": "Liabilities", "aggCode": "LIABS", "legend": "Total Liabilities", "description": "Liabilities represent a company's financial obligations and debts, including both current and long-term liabilities." },
    { "shortName": "Current Liabs", "aggCode": "LIABS.CUR", "legend": "Current Liabilities", "description": "Current Liabilities are short-term financial obligations that a company must settle within one year, including debts and payables." },
    { "shortName": "Market Cap", "aggCode": "MKTCAP", "legend": "Market Capital", "description": "Market Cap is the total value of a company's outstanding shares of stock, calculated by multiplying the stock price by the number of shares." },
    { "shortName": "MktCap To EBT", "aggCode": "MKTEBT", "legend": "Market Capital to Earnings Before Tax Ratio", "description": "MktCap To EBT measures the relationship between a company's market capitalization and its earnings before taxes, indicating valuation." },
    { "shortName": "Net Inc Mgn", "aggCode": "NETINC.MGN", "legend": "Net Earnings Margin (Trailing 12-Months)", "description": "Net Inc Mgn represents the percentage of revenue that becomes net earnings over the trailing twelve months, indicating profitability." },
    { "shortName": "Net Inc TTM", "aggCode": "NETINC.TTM", "legend": "Net income, TTM", "description": "Net Inc TTM represents a company's net income over the trailing twelve months, reflecting its bottom-line profit." },
    { "shortName": "Earn 1Y Grw", "aggCode": "NETINC.TTM|PCHG.1Y", "legend": "Earnings Grw, 1-Year", "description": "Earn 1Y Grw measures the percentage change in a company's earnings over the past 1 year, reflecting its growth rate." },
    { "shortName": "Earn 3Y Grw", "aggCode": "NETINC.TTM|PCHG.3Y", "legend": "Earnings Grw, 3-Year", "description": "Earn 3Y Grw measures the percentage change in a company's earnings over the past 3 years, reflecting its growth rate." },
    { "shortName": "Earn YoY Grw", "aggCode": "NETINC|PCHG.1Y", "legend": "Earnings Grw, Year-on-Year", "description": "Earn YoY Grw measures the percentage change in a company's earnings from year to year, indicating its annual growth rate." },
    { "shortName": "Oper Margin", "aggCode": "OPER.MGN", "legend": "Operating Margin", "description": "Oper Margin measures the profitability of a company's core operations by assessing the percentage of revenue that remains as operating profit." },
    { "shortName": "Opex TTM", "aggCode": "OPEX.TTM", "legend": "Operational Expense TTM", "description": "Opex TTM represents a company's total operational expenses over the trailing twelve months, including costs directly related to its core operations." },
    { "shortName": "PCF", "aggCode": "PCF", "legend": "Price to Cashflow Ratio", "description": "PCF is a valuation ratio that assesses a company's stock price relative to its cash flow from operating activities, providing insights into its valuation." },
    { "shortName": "6M Pct", "aggCode": "PCHG|180", "legend": "6-Months Stock Price Percent Change", "description": "6M Pct measures the percentage change in a company's stock price over the past 6 months, indicating short to medium-term price trends." },
    { "shortName": "1M Pct", "aggCode": "PCHG|30", "legend": "1-Month Stock Price Percent Change", "description": "1M Pct measures the percentage change in a company's stock price over the past 1 month, indicating short-term price trends." },
    { "shortName": "1Y Pct", "aggCode": "PCHG|365", "legend": "1-Year Percent Change in Stock Price", "description": "1Y Pct measures the percentage change in a company's stock price over the past 1 year, reflecting its long-term performance." },
    { "shortName": "1W Pct", "aggCode": "PCHG|7", "legend": "1-Week Stock Price Percent Change", "description": "1W Pct measures the percentage change in a company's stock price over the past 1 week, providing insights into short-term price movements." },
    { "shortName": "PE", "aggCode": "PE", "legend": "Price to Earnings Ratio", "description": "PE is a valuation ratio that assesses a company's stock price relative to its earnings per share, providing insights into its valuation." },
    { "shortName": "PFCF", "aggCode": "PFCF", "legend": "Price to Free Cash Flow", "description": "PFCF is a valuation ratio that assesses a company's stock price relative to its free cash flow per share, indicating its valuation based on cash generation." },
    { "shortName": "Net PPE", "aggCode": "PPE.NET", "legend": "Net Property Plant and Equipment", "description": "Net PPE represents a company's property, plant, and equipment assets, accounting for depreciation and accumulated impairment." },
    { "shortName": "Gross Profit", "aggCode": "PROFIT.GROSS.TTM", "legend": "Gross Profit TTM", "description": "Gross Profit represents a company's total revenue minus the cost of goods sold over the trailing twelve months, indicating profitability from core operations." },
    { "shortName": "Oper Profit", "aggCode": "PROFIT.OPER.TTM", "legend": "Operating Profit TTM", "description": "Oper Profit represents a company's earnings from its core operational activities over the trailing twelve months, excluding interest and taxes." },
    { "shortName": "PS", "aggCode": "PS", "legend": "Price to Sales Ratio", "description": "PS is a valuation ratio that assesses a company's stock price relative to its total revenue, providing insights into its valuation based on sales." },
    { "shortName": "Ret Earn", "aggCode": "RETEARN", "legend": "Retained earnings", "description": "Ret Earn represents the portion of a company's earnings that is retained and reinvested in the business rather than distributed to shareholders as dividends." },
    { "shortName": "Ret Earn 1Y Grw", "aggCode": "RETEARN|PCHG.1Y", "legend": "Retained Earnings Grw in past 1-Year", "description": "Ret Earn 1Y Grw measures the percentage change in a company's retained earnings over the past 1 year, reflecting its growth in retained profits." },
    { "shortName": "Ret Earn 3Y Grw", "aggCode": "RETEARN|PCHG.3Y", "legend": "Retained Earnings Grw in past 3-Year", "description": "Ret Earn 3Y Grw measures the percentage change in a company's retained earnings over the past 3 years, reflecting its growth in retained profits." },
    { "shortName": "3Y Ret", "aggCode": "RETURNS|1095", "legend": "3-Year Rolling Returns", "description": "3Y Ret represents the investment returns over a rolling 3-year period, indicating the average annualized return for investors." },
    { "shortName": "5Y Ret", "aggCode": "RETURNS|1825", "legend": "5-Year Rolling Returns", "description": "5Y Ret represents the investment returns over a rolling 5-year period, indicating the average annualized return for investors." },
    { "shortName": "7Y Ret", "aggCode": "RETURNS|2555", "legend": "7-Year Rolling Returns", "description": "7Y Ret represents the investment returns over a rolling 7-year period, indicating the average annualized return for investors." },
    { "shortName": "10Y Ret", "aggCode": "RETURNS|3650", "legend": "10-Year Rolling Returns", "description": "10Y Ret represents the investment returns over a rolling 10-year period, indicating the average annualized return for investors." },
    { "shortName": "Revenues TTM", "aggCode": "REV.TTM", "legend": "Net sales", "description": "Revenues TTM represents a company's net sales over the trailing twelve months, indicating its total sales income." },
    { "shortName": "Rev 1Y Grw", "aggCode": "REV.TTM|PCHG.1Y", "legend": "Revenue Grw in past 1-Year", "description": "Rev 1Y Grw measures the percentage change in a company's revenue over the past 1 year, reflecting its growth in sales income." },
    { "shortName": "Rev 3Y Grw", "aggCode": "REV.TTM|PCHG.3Y", "legend": "Revenue Grw in past 3-Years", "description": "Rev 3Y Grw measures the percentage change in a company's revenue over the past 3 years, reflecting its growth in sales income." },
    { "shortName": "Rev Grw YoY", "aggCode": "REV|PCHG.1Y", "legend": "Revenue Grw Year-over-Year", "description": "Rev Grw YoY measures the percentage change in a company's revenue from year to year, indicating its annual sales growth rate." },
    { "shortName": "Grufity Risk", "aggCode": "RISKLEVEL|5Y", "legend": "Grufity Risk Level Assessment", "description": "Grufity Risk Level assesses the risk level associated with a company's financial performance and stability over the past 5 years." },
    { "shortName": "ROA", "aggCode": "ROA", "legend": "Return on Assets", "description": "ROA measures a company's profitability by assessing the percentage of net income generated relative to its total assets, indicating efficiency in asset utilization." },
    { "shortName": "ROE", "aggCode": "ROE", "legend": "Return on Equity", "description": "ROE measures a company's profitability by assessing the percentage of net income generated relative to its total shareholders' equity, indicating shareholder value creation." },
    { "shortName": "Sales To Inv", "aggCode": "SAL.INV", "legend": "Sales To Inventory Ratio", "description": "Sales To Inv assesses how efficiently a company is selling its inventory by comparing total sales to the value of inventory, indicating inventory turnover." },
    { "shortName": "Sector", "aggCode": "sector", "legend": "Sector", "description": "Sector represents the industry category to which a company belongs, providing information about its business segment." },
    { "shortName": "Equity", "aggCode": "SEQ", "legend": "Total shareholders equity", "description": "Equity represents the total ownership interest in a company, consisting of common and preferred stockholders' equity." },
    { "shortName": "BuyBack 1Y", "aggCode": "SHR.OUTS|PCHG.1Y", "legend": "Buy Back Or Dilution in 1-Year", "description": "BuyBack 1Y measures the percentage change in the number of outstanding shares due to stock buybacks or dilution over the past 1 year." },
    { "shortName": "BuyBack 3Y", "aggCode": "SHR.OUTS|PCHG.3Y", "legend": "Buy Back Or Dilution in 3-Years", "description": "BuyBack 3Y measures the percentage change in the number of outstanding shares due to stock buybacks or dilution over the past 3 years." },
    { "shortName": "Share Comp TTM", "aggCode": "SHRCOMP.TTM", "legend": "Shares Based Compensation (TTM)", "description": "Share Comp TTM represents the value of shares-based compensation expenses incurred by a company over the trailing twelve months." },
  ],

  SECTOR_OPTIONS: [
    { "shortName": "Technology", "legend": "Technology", "description": "The Technology sector includes companies that develop, manufacture, and distribute technological products and services. It encompasses a wide range of businesses, from software development and electronics manufacturing to internet services and telecommunications." },
    { "shortName": "Consumer Cyclical", "legend": "Consumer Cyclical", "description": "The Consumer Cyclical sector comprises companies that produce goods and services considered non-essential and often tied to consumer discretionary spending. This sector includes industries such as retail, automotive, and leisure and entertainment." },
    { "shortName": "Communication Services", "legend": "Communication Services", "description": "The Communication Services sector encompasses companies that provide communication and entertainment services. It includes telecommunications, media, and internet companies that facilitate the exchange of information and entertainment content." },
    { "shortName": "Industrials", "legend": "Industrials", "description": "The Industrials sector comprises companies involved in the manufacturing and distribution of industrial products and services. It includes businesses in aerospace, construction, transportation, and machinery manufacturing." },
    { "shortName": "Healthcare", "legend": "Healthcare", "description": "The Healthcare sector encompasses companies engaged in the healthcare and medical services industry. It includes pharmaceuticals, biotechnology, healthcare providers, and medical equipment manufacturers." },
    { "shortName": "Financial Services", "legend": "Financial Services", "description": "The Financial Services sector includes companies that provide financial and banking services. It encompasses banking, insurance, investment, and other financial institutions." },
    { "shortName": "Consumer Defensive", "legend": "Consumer Defensive", "description": "The Consumer Defensive sector consists of companies that produce essential products and services, often seen as necessities. It includes industries like food and beverage, household products, and healthcare." },
    { "shortName": "Energy", "legend": "Energy", "description": "The Energy sector includes companies involved in the exploration, production, and distribution of energy resources. It encompasses oil and gas, renewable energy, and utility companies." },
    { "shortName": "Basic Materials", "legend": "Basic Materials", "description": "The Basic Materials sector comprises companies involved in the extraction, processing, and distribution of raw materials and chemicals. It includes industries like mining, chemicals, and forestry." },
    { "shortName": "Real Estate", "legend": "Real Estate", "description": "The Real Estate sector includes companies engaged in real estate development, management, and investment. It encompasses industries like property development, real estate investment trusts (REITs), and property management." },
    { "shortName": "Utilities", "legend": "Utilities", "description": "The Utilities sector comprises companies that provide essential public services, including electricity, water, and gas. It plays a crucial role in supplying and maintaining infrastructure for communities and industries." },
  ],

  INDUSTRY_GROUP_OPTIONS: [
    { "shortName": "Electronics", "legend": "Electronics", "description": "The Electronics industry group encompasses companies involved in the design, manufacturing, and distribution of electronic components, devices, and equipment. It includes industries such as consumer electronics, semiconductors, and electronic manufacturing services." },
    { "shortName": "Internet Retail", "legend": "Internet Retail", "description": "The Internet Retail industry group consists of companies engaged in online retail and e-commerce. It includes businesses that sell products and services to consumers through online platforms." },
    { "shortName": "Internet", "legend": "Internet", "description": "The Internet industry group comprises companies that provide internet services, including internet service providers, web-based technology companies, and online content providers." },
    { "shortName": "Software - Infra", "legend": "Software - Infrastructure", "description": "The Software - Infrastructure industry group includes companies that develop and provide software solutions and platforms to support the infrastructure of other businesses. It encompasses infrastructure software and related services." },
    { "shortName": "Entertainment", "legend": "Entertainment", "description": "The Entertainment industry group encompasses companies involved in the production, distribution, and promotion of entertainment content. It includes industries such as film, television, music, and live entertainment." },
    { "shortName": "Semiconductors", "legend": "Semiconductors", "description": "The Semiconductors industry group consists of companies that design, manufacture, and distribute semiconductor chips and related electronic components used in a wide range of electronic devices." },
    { "shortName": "Airlines", "legend": "Airlines", "description": "The Airlines industry group includes companies that operate commercial airline services. It encompasses passenger and cargo airlines, as well as related aviation services." },
    { "shortName": "Pharmaceuticals", "legend": "Pharmaceuticals", "description": "The Pharmaceuticals industry group comprises companies engaged in the research, development, and manufacturing of pharmaceutical drugs and medications for various medical conditions." },
    { "shortName": "Credit Services", "legend": "Credit Services", "description": "The Credit Services industry group consists of companies that provide credit and lending services to consumers and businesses. It includes credit card companies, lenders, and financial institutions." },
    { "shortName": "Aerospace", "legend": "Aerospace", "description": "The Aerospace industry group includes companies involved in the design, manufacturing, and distribution of aerospace and aviation products, including aircraft, spacecraft, and related technologies." },
    { "shortName": "Banks", "legend": "Banks", "description": "The Banks industry group comprises financial institutions that offer banking and financial services, including savings and loans, commercial banks, and investment banks." },
    { "shortName": "Food", "legend": "Food", "description": "The Food industry group includes companies involved in the production, processing, and distribution of food products. It encompasses food manufacturers, processors, and distributors." },
    { "shortName": "Manufacturing", "legend": "Manufacturing", "description": "The Manufacturing industry group consists of companies engaged in various manufacturing processes and production of goods, including machinery, equipment, and industrial products." },
    { "shortName": "Leisure", "legend": "Leisure", "description": "The Leisure industry group encompasses companies providing leisure and entertainment services, including theme parks, resorts, and recreational activities." },
    { "shortName": "Oil - E&P", "legend": "Oil - Exploration and Production", "description": "The Oil - Exploration and Production industry group comprises companies involved in the exploration and production of oil and natural gas resources." },
    { "shortName": "Biotechnology", "legend": "Biotechnology", "description": "The Biotechnology industry group includes companies engaged in biotechnological research and development, particularly in the fields of genetics, genomics, and medical biotechnology." },
    { "shortName": "Software - Apps", "legend": "Software - Applications", "description": "The Software - Applications industry group consists of companies that develop software applications and solutions for various purposes, including business software, mobile apps, and productivity tools." },
    { "shortName": "Hardware", "legend": "Hardware", "description": "The Hardware industry group includes companies that design, manufacture, and distribute hardware products, including computer hardware, electronic devices, and hardware components." },
    { "shortName": "Chemicals", "legend": "Chemicals", "description": "The Chemicals industry group comprises companies involved in the production and distribution of chemicals, including specialty chemicals, industrial chemicals, and chemical compounds." },
    { "shortName": "Oil - Midstream", "legend": "Oil - Midstream", "description": "The Oil - Midstream industry group consists of companies engaged in the transportation and storage of oil and natural gas products through pipelines, terminals, and storage facilities." },
    { "shortName": "Autos", "legend": "Automotive", "description": "The Automotive industry group includes companies that manufacture and distribute automobiles, automotive parts, and related products and services." },
    { "shortName": "Equipment", "legend": "Equipment", "description": "The Equipment industry group encompasses companies that manufacture and supply various types of equipment, including industrial machinery, construction equipment, and heavy machinery." },
    { "shortName": "Apparel", "legend": "Apparel", "description": "The Apparel industry group includes companies involved in the design, manufacturing, and distribution of clothing and fashion-related products." },
    { "shortName": "Finance", "legend": "Finance", "description": "The Finance industry group comprises financial and investment companies that offer various financial services, including investment management, asset management, and financial planning." },
    { "shortName": "Oil - Services", "legend": "Oil - Services", "description": "The Oil - Services industry group consists of companies that provide services to the oil and gas industry, including drilling, well maintenance, and oilfield services." },
    { "shortName": "Home", "legend": "Home", "description": "The Home industry group encompasses companies involved in the homebuilding and real estate industry, including residential construction, property development, and real estate services." },
    { "shortName": "Conglomerates", "legend": "Conglomerates", "description": "The Conglomerates industry group includes diversified companies with interests in multiple industries and business segments." },
    { "shortName": "IT Services", "legend": "IT Services", "description": "The IT Services industry group consists of companies that provide a wide range of information technology services, including IT consulting, software development, and IT outsourcing." },
    { "shortName": "REIT", "legend": "Real Estate Investment Trust (REIT)", "description": "The Real Estate Investment Trust (REIT) industry group comprises companies that own, operate, or finance income-generating real estate assets and properties." },
    { "shortName": "Beverages", "legend": "Beverages", "description": "The Beverages industry group includes companies involved in the production and distribution of beverages, including soft drinks, alcoholic beverages, and non-alcoholic drinks." },
    { "shortName": "Restaurants", "legend": "Restaurants", "description": "The Restaurants industry group consists of companies engaged in the restaurant and food service business, including fast food chains, casual dining, and fine dining establishments." },
    { "shortName": "Footwear", "legend": "Footwear", "description": "The Footwear industry group includes companies that design, manufacture, and distribute footwear products, including shoes, sneakers, and related accessories." },
    { "shortName": "Household Products", "legend": "Household Products", "description": "The Household Products industry group comprises companies that produce and distribute household and personal care products, including cleaning products, toiletries, and hygiene items." },
    { "shortName": "Telecom", "legend": "Telecommunications", "description": "The Telecommunications industry group includes companies that provide telecommunications and communication services, including landline and mobile phone services, internet access, and network infrastructure." },
    { "shortName": "Insurance", "legend": "Insurance", "description": "The Insurance industry group comprises companies involved in the insurance and risk management business, offering various insurance products and services, including life insurance, property insurance, and health insurance." },
    { "shortName": "Healthcare", "legend": "Healthcare", "description": "The Healthcare industry group includes companies engaged in the healthcare and medical services industry. It includes pharmaceuticals, biotechnology, healthcare providers, and medical equipment manufacturers." },
    { "shortName": "Discount Stores", "legend": "Discount Stores", "description": "The Discount Stores industry group consists of companies that operate discount retail stores and offer low-cost products and consumer goods." },
    { "shortName": "Diagnostics", "legend": "Diagnostics", "description": "The Diagnostics industry group includes companies involved in medical diagnostics, diagnostic testing, and laboratory services for healthcare applications." },
    { "shortName": "Metals and Mining", "legend": "Metals and Mining", "description": "The Metals and Mining industry group comprises companies engaged in the extraction and processing of metals and minerals, including mining, smelting, and refining operations." },
    { "shortName": "Retail", "legend": "Retail", "description": "The Retail industry group includes companies that operate retail stores and sell a wide range of consumer products, including clothing, electronics, and home goods." },
    { "shortName": "Medical Instruments", "legend": "Medical Instruments", "description": "The Medical Instruments industry group encompasses companies that manufacture and distribute medical instruments, devices, and equipment used in healthcare and medical procedures." },
    { "shortName": "Services", "legend": "Services", "description": "The Services industry group includes companies that provide various services to consumers and businesses, such as consulting, logistics, and business services." },
    { "shortName": "Utilities", "legend": "Utilities", "description": "The Utilities industry group comprises companies that provide essential public services, including electricity, water, and gas. It plays a crucial role in supplying and maintaining infrastructure for communities and industries." },
    { "shortName": "Packers", "legend": "Packers", "description": "The Packers industry group includes companies involved in the meatpacking and food processing industry, particularly the processing of meat and related products." },
    { "shortName": "Asset Management", "legend": "Asset Management", "description": "The Asset Management industry group consists of companies that manage and invest in financial assets on behalf of clients, including investment funds, asset management firms, and wealth management services." },
    { "shortName": "Gaming", "legend": "Gaming", "description": "The Gaming industry group encompasses companies involved in the development, distribution, and operation of video games, casino gaming, and related entertainment products and services." },
    { "shortName": "Real Estate", "legend": "Real Estate", "description": "The Real Estate industry group includes companies engaged in real estate development, management, and investment. It encompasses industries like property development, real estate investment trusts (REITs), and property management." },
    { "shortName": "Agriculture", "legend": "Agriculture", "description": "The Agriculture industry group consists of companies involved in agricultural production, including farming, crop cultivation, and agricultural equipment manufacturing." },
    { "shortName": "Logistics", "legend": "Logistics", "description": "The Logistics industry group includes companies that provide logistics and supply chain management services, including transportation, warehousing, and distribution." },
    { "shortName": "Railroads", "legend": "Railroads", "description": "The Railroads industry group comprises companies involved in the operation and management of railroad transportation and related services." },
    { "shortName": "Solar", "legend": "Solar", "description": "The Solar industry group includes companies engaged in the design, manufacturing, and installation of solar energy systems and products." },
    { "shortName": "Personal Services", "legend": "Personal Services", "description": "The Personal Services industry group consists of companies that offer personal care and grooming services, including beauty salons, spas, and fitness centers." },
    { "shortName": "Advertising", "legend": "Advertising", "description": "The Advertising industry group includes companies that provide advertising and marketing services, including advertising agencies, media companies, and online advertising platforms." },
    { "shortName": "Building Materials", "legend": "Building Materials", "description": "The Building Materials industry group encompasses companies involved in the production and distribution of building materials and construction products, including cement, lumber, and building supplies." },
    { "shortName": "Tobacco", "legend": "Tobacco", "description": "The Tobacco industry group includes companies engaged in the production and distribution of tobacco and tobacco-related products, including cigarettes, cigars, and tobacco accessories." },
    { "shortName": "Luxury", "legend": "Luxury", "description": "The Luxury industry group consists of companies that manufacture and sell high-end luxury goods, including fashion, accessories, and luxury consumer products." },
    { "shortName": "Education", "legend": "Education", "description": "The Education industry group includes companies involved in the education and training sector, including schools, universities, online education platforms, and educational services." },
    { "shortName": "Energy - Non Oil", "legend": "Energy - Non Oil", "description": "The Energy - Non Oil industry group comprises companies involved in the energy sector, excluding oil and gas. It includes renewable energy, nuclear energy, and other non-oil energy sources." },
    { "shortName": "Media", "legend": "Media", "description": "The Media industry group includes companies engaged in media production, distribution, and content creation, including television, radio, print, and digital media." },
    { "shortName": "Wood and Paper", "legend": "Wood and Paper", "description": "The Wood and Paper industry group encompasses companies involved in the production of wood and paper products, including forestry, lumber, and paper manufacturing." },
    { "shortName": "Shipping", "legend": "Shipping", "description": "The Shipping industry group consists of companies that operate in the shipping and maritime transportation industry, including shipping companies, container shipping, and logistics." },
    { "shortName": "Shell Companies", "legend": "Shell Companies", "description": "The Shell Companies industry group refers to companies that have no significant business operations and are often used as vehicles for financial transactions, acquisitions, or mergers." },
  ],

  INDUSTRY: [
    { "sector": "Technology", "shortName": "Consumer Electronics", "legend": "Consumer Electronics", "description": "The Consumer Electronics industry includes companies that design, manufacture, and distribute electronic products for consumers, such as smartphones, laptops, and home entertainment devices." },
    { "sector": "Technology", "shortName": "Software Infrastructure", "legend": "Software Infrastructure", "description": "The Software Infrastructure industry group includes companies that develop and provide software solutions and platforms to support the infrastructure of other businesses, including operating systems and cloud services." },
    { "sector": "Technology", "shortName": "Semiconductors", "legend": "Semiconductors", "description": "The Semiconductors industry consists of companies that design, manufacture, and distribute semiconductor chips and related electronic components used in various electronic devices." },
    { "sector": "Technology", "shortName": "Software Application", "legend": "Software Application", "description": "The Software Application industry group consists of companies that develop software applications and solutions for various purposes, including business software, mobile apps, and productivity tools." },
    { "sector": "Technology", "shortName": "Communication Equipment", "legend": "Communication Equipment", "description": "The Communication Equipment industry includes companies that manufacture and distribute communication equipment and hardware, including telecommunications and network devices." },
    { "sector": "Technology", "shortName": "Information Technology Services", "legend": "Information Technology Services", "description": "The Information Technology Services industry consists of companies that provide a wide range of information technology services, including IT consulting, software development, and IT outsourcing." },
    { "sector": "Technology", "shortName": "Semiconductor Equipment & Materials", "legend": "Semiconductor Equipment & Materials", "description": "The Semiconductor Equipment & Materials industry includes companies that provide equipment and materials used in semiconductor manufacturing and chip fabrication." },
    { "sector": "Technology", "shortName": "Computer Hardware", "legend": "Computer Hardware", "description": "The Computer Hardware industry includes companies that manufacture and distribute computer hardware and peripherals, including desktops, laptops, and computer components." },
    { "sector": "Technology", "shortName": "Electronic Components", "legend": "Electronic Components", "description": "The Electronic Components industry comprises companies that produce and distribute electronic components, including semiconductors, connectors, and electronic parts." },
    { "sector": "Technology", "shortName": "Electronic Gaming & Multimedia", "legend": "Electronic Gaming & Multimedia", "description": "The Electronic Gaming & Multimedia industry includes companies engaged in the development, distribution, and operation of video games, multimedia content, and related entertainment products." },
    { "sector": "Technology", "shortName": "Scientific & Technical Instruments", "legend": "Scientific & Technical Instruments", "description": "The Scientific & Technical Instruments industry includes companies that produce scientific and technical instruments and equipment used in research, analysis, and laboratory applications." },
    { "sector": "Technology", "shortName": "Electronics & Computer Distribution", "legend": "Electronics & Computer Distribution", "description": "The Electronics & Computer Distribution industry comprises companies that distribute electronics and computer hardware, components, and software to businesses and consumers." },
    { "sector": "Consumer Cyclical", "shortName": "Internet Retail", "legend": "Internet Retail", "description": "The Internet Retail industry comprises companies engaged in online retail and e-commerce, offering products and services through online platforms." },
    { "sector": "Consumer Cyclical", "shortName": "Travel Services", "legend": "Travel Services", "description": "The Travel Services industry includes companies that provide travel and tourism-related services, such as travel booking and reservations, tour operators, and travel agencies." },
    { "sector": "Consumer Cyclical", "shortName": "Gambling", "legend": "Gambling", "description": "The Gambling industry includes companies involved in gaming and betting activities, such as casinos, sports betting, and online gambling." },
    { "sector": "Consumer Cyclical", "shortName": "Auto Manufacturers", "legend": "Auto Manufacturers", "description": "The Auto Manufacturers industry includes companies that design, manufacture, and distribute automobiles and vehicles, including passenger cars and commercial vehicles." },
    { "sector": "Consumer Cyclical", "shortName": "Apparel Retail", "legend": "Apparel Retail", "description": "The Apparel Retail industry includes companies involved in the retail of clothing and fashion-related products, operating clothing stores and outlets." },
    { "sector": "Consumer Cyclical", "shortName": "Home Improvement Retail", "legend": "Home Improvement Retail", "description": "The Home Improvement Retail industry includes companies that operate retail stores specializing in home improvement and construction-related products." },
    { "sector": "Consumer Cyclical", "shortName": "Restaurants", "legend": "Restaurants", "description": "The Restaurants industry consists of companies engaged in the restaurant and food service business, including fast food chains, casual dining, and fine dining establishments." },
    { "sector": "Consumer Cyclical", "shortName": "Resorts & Casinos", "legend": "Resorts & Casinos", "description": "The Resorts & Casinos industry includes companies that operate resorts, hotels, and casinos, offering entertainment, lodging, and gaming services." },
    { "sector": "Consumer Cyclical", "shortName": "Footwear & Accessories", "legend": "Footwear & Accessories", "description": "The Footwear & Accessories industry comprises companies that design, manufacture, and distribute footwear products, including shoes, sneakers, and related accessories." },
    { "sector": "Consumer Cyclical", "shortName": "Specialty Retail", "legend": "Specialty Retail", "description": "The Specialty Retail industry consists of companies that operate retail stores specializing in specific niche products or categories." },
    { "sector": "Consumer Cyclical", "shortName": "Leisure", "legend": "Leisure", "description": "The Leisure industry encompasses companies providing leisure and entertainment services, including theme parks, resorts, and recreational activities." },
    { "sector": "Consumer Cyclical", "shortName": "Auto Parts", "legend": "Auto Parts", "description": "The Auto Parts industry includes companies that manufacture and distribute automotive parts and components for vehicles, including engines, brakes, and automotive systems." },
    { "sector": "Consumer Cyclical", "shortName": "Apparel Manufacturing", "legend": "Apparel Manufacturing", "description": "The Apparel Manufacturing industry consists of companies that manufacture clothing and apparel products, including garments, textiles, and fashion items." },
    { "sector": "Consumer Cyclical", "shortName": "Lodging", "legend": "Lodging", "description": "The Lodging industry includes companies that own and operate lodging and accommodation facilities, such as hotels, motels, and resorts." },
    { "sector": "Consumer Cyclical", "shortName": "Recreational Vehicles", "legend": "Recreational Vehicles", "description": "The Recreational Vehicles industry comprises companies that manufacture and distribute recreational vehicles (RVs), including motorhomes, campers, and trailers." },
    { "sector": "Consumer Cyclical", "shortName": "Personal Services", "legend": "Personal Services", "description": "The Personal Services industry consists of companies that offer personal and consumer services, including beauty salons, fitness centers, and personal care services." },
    { "sector": "Consumer Cyclical", "shortName": "Department Stores", "legend": "Department Stores", "description": "The Department Stores industry consists of companies that operate department stores and retail chains offering a wide range of products and merchandise." },
    { "sector": "Consumer Cyclical", "shortName": "Auto & Truck Dealerships", "legend": "Auto & Truck Dealerships", "description": "The Auto & Truck Dealerships industry includes companies that operate dealerships for automobiles and trucks, offering sales, service, and financing for vehicles." },
    // { "sector": "Consumer Cyclical", "shortName": "Furnishings, Fixtures & Appliances", "legend": "Furnishings, Fixtures & Appliances", "description": "The Furnishings, Fixtures & Appliances industry includes companies that produce and distribute furnishings, fixtures, and appliances for homes, offices, and commercial spaces." },
    { "sector": "Consumer Cyclical", "shortName": "Luxury Goods", "legend": "Luxury Goods", "description": "The Luxury Goods industry includes companies that produce and market high-end and luxury products, including fashion, accessories, and luxury consumer goods." },
    { "sector": "Consumer Cyclical", "shortName": "Textile Manufacturing", "legend": "Textile Manufacturing", "description": "The Textile Manufacturing industry includes companies engaged in the manufacturing of textiles, fabrics, and related textile products for various applications." },
    { "sector": "Consumer Cyclical", "shortName": "Publishing", "legend": "Publishing", "description": "The Publishing industry consists of companies that produce and distribute printed and digital publications, including books, magazines, newspapers, and online content." },
    { "sector": "Communication Services", "shortName": "Internet Content & Information", "legend": "Internet Content & Information", "description": "The Internet Content & Information industry includes companies that create, curate, and provide online content and information, including search engines, social media, and content platforms." },
    { "sector": "Communication Services", "shortName": "Entertainment", "legend": "Entertainment", "description": "The Entertainment industry includes companies involved in the production, distribution, and promotion of entertainment content, such as film, television, music, and live entertainment." },
    { "sector": "Communication Services", "shortName": "Telecom Services", "legend": "Telecommunications Services", "description": "The Telecommunications Services industry includes companies that provide telecommunications and communication services, including landline and mobile phone services, internet access, and network infrastructure." },
    { "sector": "Communication Services", "shortName": "Advertising Agencies", "legend": "Advertising Agencies", "description": "The Advertising Agencies industry comprises companies that provide advertising and marketing services, including advertising campaigns, creative design, and marketing strategies." },
    { "sector": "Communication Services", "shortName": "Broadcasting", "legend": "Broadcasting", "description": "The Broadcasting industry includes companies engaged in radio and television broadcasting, as well as cable and satellite broadcasting services." },
    { "sector": "Industrials", "shortName": "Airlines", "legend": "Airlines", "description": "The Airlines industry includes companies that operate commercial airline services, offering passenger and cargo transportation services." },
    { "sector": "Industrials", "shortName": "Aerospace & Defense", "legend": "Aerospace & Defense", "description": "The Aerospace & Defense industry includes companies involved in the design, manufacturing, and distribution of aerospace and defense products, including aircraft, spacecraft, and related technologies." },
    { "sector": "Industrials", "shortName": "Farm & Heavy Construction Machinery", "legend": "Farm & Heavy Construction Machinery", "description": "The Farm & Heavy Construction Machinery industry consists of companies that manufacture and distribute machinery used in agriculture and heavy construction, including tractors and earthmoving equipment." },
    { "sector": "Industrials", "shortName": "Electrical Equipment & Parts", "legend": "Electrical Equipment & Parts", "description": "The Electrical Equipment & Parts industry includes companies that manufacture and distribute electrical equipment and components, including wiring, circuitry, and electrical systems." },
    { "sector": "Industrials", "shortName": "Specialty Industrial Machinery", "legend": "Specialty Industrial Machinery", "description": "The Specialty Industrial Machinery industry comprises companies that design and manufacture specialized industrial machinery and equipment for various applications." },
    { "sector": "Industrials", "shortName": "Conglomerates", "legend": "Conglomerates", "description": "The Conglomerates industry comprises diversified companies with interests in multiple industries and business segments." },
    { "sector": "Industrials", "shortName": "Engineering & Construction", "legend": "Engineering & Construction", "description": "The Engineering & Construction industry includes companies involved in engineering and construction projects, including infrastructure development and construction services." },
    { "sector": "Industrials", "shortName": "Staffing & Employment Services", "legend": "Staffing & Employment Services", "description": "The Staffing & Employment Services industry includes companies that provide staffing and employment-related services, including job placement and workforce solutions." },
    { "sector": "Industrials", "shortName": "Security & Protection Services", "legend": "Security & Protection Services", "description": "The Security & Protection Services industry comprises companies that offer security and protection services, including physical security, alarm systems, and monitoring services." },
    { "sector": "Industrials", "shortName": "Consulting Services", "legend": "Consulting Services", "description": "The Consulting Services industry includes companies that provide consulting and advisory services to businesses and organizations, offering expertise in various domains and industries." },
    { "sector": "Industrials", "shortName": "Building Products & Equipment", "legend": "Building Products & Equipment", "description": "The Building Products & Equipment industry encompasses companies involved in the production and distribution of building materials and construction products, including cement, lumber, and building supplies." },
    { "sector": "Industrials", "shortName": "Integrated Freight & Logistics", "legend": "Integrated Freight & Logistics", "description": "The Integrated Freight & Logistics industry comprises companies involved in integrated logistics and transportation services, including freight forwarding, warehousing, and supply chain management." },
    { "sector": "Industrials", "shortName": "Railroads", "legend": "Railroads", "description": "The Railroads industry comprises companies that operate railroad networks and transportation services, including freight and passenger rail services." },
    { "sector": "Industrials", "shortName": "Specialty Business Services", "legend": "Specialty Business Services", "description": "The Specialty Business Services industry includes companies that provide specialized business services, such as document management, legal services, and specialized consulting services." },
    { "sector": "Industrials", "shortName": "Tools & Accessories", "legend": "Tools & Accessories", "description": "The Tools & Accessories industry comprises companies that manufacture and distribute tools, equipment, and accessories for various industries and consumer applications." },
    { "sector": "Industrials", "shortName": "Trucking", "legend": "Trucking", "description": "The Trucking industry consists of companies that provide trucking and transportation services, including the delivery and transportation of goods and freight." },
    { "sector": "Industrials", "shortName": "Waste Management", "legend": "Waste Management", "description": "The Waste Management industry comprises companies that provide waste collection, disposal, and recycling services, managing and processing waste materials." },
    { "sector": "Industrials", "shortName": "Rental & Leasing Services", "legend": "Rental & Leasing Services", "description": "The Rental & Leasing Services industry includes companies that offer rental and leasing services for various products and equipment, such as vehicles, machinery, and equipment." },
    { "sector": "Industrials", "shortName": "Business Equipment & Supplies", "legend": "Business Equipment & Supplies", "description": "The Business Equipment & Supplies industry consists of companies that manufacture and distribute business equipment and supplies for office and commercial use." },
    { "sector": "Industrials", "shortName": "Pollution & Treatment Controls", "legend": "Pollution & Treatment Controls", "description": "The Pollution & Treatment Controls industry includes companies that produce equipment and systems for pollution control and environmental treatment, managing pollution and waste." },
    { "sector": "Industrials", "shortName": "Metal Fabrication", "legend": "Metal Fabrication", "description": "The Metal Fabrication industry comprises companies that provide metal fabrication services, including the production of metal components, structures, and fabricated metal products." },
    { "sector": "Industrials", "shortName": "Marine Shipping", "legend": "Marine Shipping", "description": "The Marine Shipping industry comprises companies engaged in the transportation and shipping of goods and cargo via marine vessels and container shipping services." },
    { "sector": "Industrials", "shortName": "Infrastructure Operations", "legend": "Infrastructure Operations", "description": "The Infrastructure Operations industry includes companies involved in the ownership, operation, and management of infrastructure assets, such as toll roads, bridges, and public facilities." },
    { "sector": "Industrials", "shortName": "Airports & Air Services", "legend": "Airports & Air Services", "description": "The Airports & Air Services industry includes companies that operate airports, provide aviation services, and offer air transportation, including passenger and cargo services." },
    { "sector": "Industrials", "shortName": "Industrial Distribution", "legend": "Industrial Distribution", "description": "Industrial Distribution includes companies that are involved in the overall movement of industrial goods from their creation (manufacturing) through the supply chain (wholesale distribution) to companies who use those products." },
    { "sector": "Healthcare", "shortName": "Drug Manufacturers General", "legend": "Drug Manufacturers - General", "description": "The Drug Manufacturers - General industry comprises companies involved in the research, development, and manufacturing of pharmaceutical drugs and medications for various medical conditions." },
    { "sector": "Healthcare", "shortName": "Biotechnology", "legend": "Biotechnology", "description": "The Biotechnology industry includes companies engaged in biotechnological research and development, particularly in the fields of genetics, genomics, and medical biotechnology." },
    { "sector": "Healthcare", "shortName": "Drug Manufacturers Specialty & Generic", "legend": "Drug Manufacturers - Specialty & Generic", "description": "The Drug Manufacturers - Specialty & Generic industry comprises companies engaged in the research, development, and manufacturing of specialty and generic pharmaceutical drugs." },
    { "sector": "Healthcare", "shortName": "Healthcare Plans", "legend": "Healthcare Plans", "description": "The Healthcare Plans industry includes companies that provide healthcare insurance plans and services, offering coverage for medical expenses and healthcare services." },
    { "sector": "Healthcare", "shortName": "Diagnostics & Research", "legend": "Diagnostics & Research", "description": "The Diagnostics & Research industry includes companies involved in medical diagnostics, diagnostic testing, and laboratory services for healthcare applications." },
    { "sector": "Healthcare", "shortName": "Medical Devices", "legend": "Medical Devices", "description": "The Medical Devices industry comprises companies that design, manufacture, and distribute medical devices and equipment used in healthcare and medical procedures." },
    { "sector": "Healthcare", "shortName": "Medical Instruments & Supplies", "legend": "Medical Instruments & Supplies", "description": "The Medical Instruments & Supplies industry comprises companies that manufacture and distribute medical instruments, devices, and equipment used in healthcare and medical procedures." },
    { "sector": "Healthcare", "shortName": "Medical Distribution", "legend": "Medical Distribution", "description": "The Medical Distribution industry includes companies that distribute medical and healthcare products to healthcare providers and facilities, ensuring the timely supply of medical goods." },
    { "sector": "Healthcare", "shortName": "Medical Care Facilities", "legend": "Medical Care Facilities", "description": "The Medical Care Facilities industry includes companies that own and operate medical care and healthcare facilities, such as hospitals, clinics, and healthcare centers." },
    { "sector": "Healthcare", "shortName": "Health Information Services", "legend": "Health Information Services", "description": "The Health Information Services industry consists of companies that provide information technology solutions and services for the healthcare and medical industry." },
    { "sector": "Financial Services", "shortName": "Credit Services", "legend": "Credit Services", "description": "The Credit Services industry consists of companies that provide credit and lending services to consumers and businesses, including credit card companies and lenders." },
    { "sector": "Financial Services", "shortName": "Banks Diversified", "legend": "Banks - Diversified", "description": "The Banks - Diversified industry comprises financial institutions that offer diversified banking and financial services, including commercial banking, investment banking, and asset management." },
    { "sector": "Financial Services", "shortName": "Capital Markets", "legend": "Capital Markets", "description": "The Capital Markets industry consists of financial institutions that provide capital market services, including investment banking, securities trading, and asset management." },
    { "sector": "Financial Services", "shortName": "Insurance Property & Casualty", "legend": "Insurance - Property & Casualty", "description": "The Insurance - Property & Casualty industry includes companies that offer property and casualty insurance coverage, protecting against property damage and liability claims." },
    { "sector": "Financial Services", "shortName": "Insurance Diversified", "legend": "Insurance - Diversified", "description": "The Insurance - Diversified industry includes companies engaged in multiple aspects of the insurance business, offering various insurance products and services." },
    { "sector": "Financial Services", "shortName": "Insurance Life", "legend": "Insurance - Life", "description": "The Insurance - Life industry comprises companies that offer life insurance products and services, providing financial protection and investment opportunities to policyholders." },
    { "sector": "Financial Services", "shortName": "Insurance Specialty", "legend": "Insurance - Specialty", "description": "The Insurance - Specialty industry includes companies that provide specialty insurance products and services, offering coverage for unique risks and situations." },
    { "sector": "Financial Services", "shortName": "Insurance Brokers", "legend": "Insurance Brokers", "description": "The Insurance Brokers industry consists of companies that act as intermediaries between insurance providers and customers, helping customers find the right insurance coverage." },
    { "sector": "Financial Services", "shortName": "Asset Management", "legend": "Asset Management", "description": "The Asset Management industry consists of companies that manage and invest in financial assets on behalf of clients, including investment funds, asset management firms, and wealth management services." },
    { "sector": "Financial Services", "shortName": "Banks Regional", "legend": "Banks - Regional", "description": "The Banks - Regional industry includes regional and community banks that operate within specific geographic regions, providing banking and financial services to local communities." },
    { "sector": "Financial Services", "shortName": "Financial Data & Stock Exchanges", "legend": "Financial Data & Stock Exchanges", "description": "The Financial Data & Stock Exchanges industry includes companies that provide financial data, analytics, and stock exchange services, supporting financial markets and investment activities." },
    { "sector": "Financial Services", "shortName": "Insurance Reinsurance", "legend": "Insurance - Reinsurance", "description": "The Insurance - Reinsurance industry includes companies that provide reinsurance services to insurance companies, helping manage risk and liability." },
    { "sector": "Financial Services", "shortName": "Mortgage Finance", "legend": "Mortgage Finance", "description": "The Mortgage Finance industry includes companies that provide mortgage financing and lending services, including mortgage origination, underwriting, and lending." },
    { "sector": "Financial Services", "shortName": "Financial Conglomerates", "legend": "Financial Conglomerates", "description": "The Financial Conglomerates industry consists of diversified financial services companies with interests in multiple financial sectors, including banking, insurance, and investment services." },
    { "sector": "Financial Services", "shortName": "Shell Companies", "legend": "Shell Companies", "description": "The Shell Companies industry comprises companies that exist as legal entities but have no significant business operations or assets, often used for various financial purposes." },
    { "sector": "Consumer Defensive", "shortName": "Packaged Foods", "legend": "Packaged Foods", "description": "The Packaged Foods industry includes companies involved in the production, processing, and distribution of packaged food products, including packaged snacks, canned goods, and convenience foods." },
    { "sector": "Consumer Defensive", "shortName": "Beverages Non-Alcoholic", "legend": "Beverages - Non-Alcoholic", "description": "The Beverages - Non-Alcoholic industry includes companies involved in the production and distribution of non-alcoholic beverages, such as soft drinks and non-alcoholic beverages." },
    { "sector": "Consumer Defensive", "shortName": "Household & Personal Products", "legend": "Household & Personal Products", "description": "The Household & Personal Products industry includes companies that produce and distribute household and personal care products, including cleaning products, toiletries, and hygiene items." },
    { "sector": "Consumer Defensive", "shortName": "Pharmaceutical Retailers", "legend": "Pharmaceutical Retailers", "description": "The Pharmaceutical Retailers industry comprises companies involved in the retail of pharmaceutical and healthcare products, including prescription drugs and over-the-counter medications." },
    { "sector": "Consumer Defensive", "shortName": "Discount Stores", "legend": "Discount Stores", "description": "The Discount Stores industry consists of companies that operate discount retail stores and offer low-cost products and consumer goods." },
    { "sector": "Consumer Defensive", "shortName": "Grocery Stores", "legend": "Grocery Stores", "description": "The Grocery Stores industry consists of companies that operate retail grocery stores and supermarkets, offering a wide range of food and grocery products." },
    { "sector": "Consumer Defensive", "shortName": "Confectioners", "legend": "Confectioners", "description": "The Confectioners industry includes companies that manufacture and distribute confectionery products, such as candies, chocolates, and sweets." },
    { "sector": "Consumer Defensive", "shortName": "Tobacco", "legend": "Tobacco", "description": "The Tobacco industry includes companies that produce and distribute tobacco and tobacco-related products, including cigarettes, cigars, and tobacco accessories." },
    { "sector": "Consumer Defensive", "shortName": "Beverages Wineries & Distilleries", "legend": "Beverages - Wineries & Distilleries", "description": "The Beverages - Wineries & Distilleries industry comprises companies involved in the production and distribution of wine, spirits, and alcoholic beverages." },
    { "sector": "Consumer Defensive", "shortName": "Food Distribution", "legend": "Food Distribution", "description": "The Food Distribution industry includes companies that distribute food products and supplies to restaurants, grocery stores, and foodservice providers." },
    { "sector": "Consumer Defensive", "shortName": "Beverages Brewers", "legend": "Beverages - Brewers", "description": "The Beverages - Brewers industry comprises companies that brew and produce alcoholic and non-alcoholic beverages, including beer, soft drinks, and beverages." },
    { "sector": "Consumer Defensive", "shortName": "Education & Training Services", "legend": "Education & Training Services", "description": "The Education & Training Services industry includes companies that offer education and training services, including schools, training programs, and educational content." },
    // { "sector": "Energy", "shortName": "Oil & Gas E&P", "legend": "Oil & Gas Exploration & Production (E&P)", "description": "The Oil & Gas Exploration & Production (E&P) industry comprises companies involved in the exploration, drilling, and production of oil and natural gas resources." },
    { "sector": "Energy", "shortName": "Oil & Gas Integrated", "legend": "Oil & Gas Integrated", "description": "The Oil & Gas Integrated industry comprises companies involved in the entire oil and gas supply chain, including exploration, production, refining, and distribution." },
    { "sector": "Energy", "shortName": "Oil & Gas Midstream", "legend": "Oil & Gas Midstream", "description": "The Oil & Gas Midstream industry consists of companies engaged in the transportation and storage of oil and natural gas products through pipelines, terminals, and storage facilities." },
    { "sector": "Energy", "shortName": "Oil & Gas Equipment & Services", "legend": "Oil & Gas Equipment & Services", "description": "The Oil & Gas Equipment & Services industry comprises companies that provide services to the oil and gas industry, including drilling, well maintenance, and oilfield services." },
    { "sector": "Energy", "shortName": "Oil & Gas Drilling", "legend": "Oil & Gas Drilling", "description": "The Oil & Gas Drilling industry includes companies that provide drilling services for oil and natural gas exploration and production." },
    { "sector": "Energy", "shortName": "Oil & Gas Refining & Marketing", "legend": "Oil & Gas Refining & Marketing", "description": "The Oil & Gas Refining & Marketing industry consists of companies involved in the refining and marketing of oil and petroleum products, including gasoline and diesel." },
    { "sector": "Energy", "shortName": "Uranium", "legend": "Uranium", "description": "The Uranium industry includes companies involved in the exploration, mining, and production of uranium for nuclear energy and industrial applications." },
    { "sector": "Basic Materials", "shortName": "Chemicals", "legend": "Chemicals", "description": "The Chemicals industry comprises companies involved in the production and distribution of chemicals, including specialty chemicals, industrial chemicals, and chemical compounds." },
    { "sector": "Basic Materials", "shortName": "Aluminum", "legend": "Aluminum", "description": "The Aluminum industry includes companies involved in the production and distribution of aluminum and aluminum-related products." },
    { "sector": "Basic Materials", "shortName": "Farm Products", "legend": "Farm Products", "description": "The Farm Products industry comprises companies involved in agricultural production, including farming, crop cultivation, and agricultural equipment manufacturing." },
    { "sector": "Basic Materials", "shortName": "Specialty Chemicals", "legend": "Specialty Chemicals", "description": "The Specialty Chemicals industry includes companies that produce and distribute specialty chemicals for various industries and applications." },
    { "sector": "Basic Materials", "shortName": "Packaging & Containers", "legend": "Packaging & Containers", "description": "The Packaging & Containers industry consists of companies that manufacture and distribute packaging and container products for various industries and consumer goods." },
    { "sector": "Basic Materials", "shortName": "Agricultural Inputs", "legend": "Agricultural Inputs", "description": "The Agricultural Inputs industry consists of companies that provide agricultural inputs and products to support crop cultivation and agriculture, including fertilizers, seeds, and agricultural chemicals." },
    { "sector": "Basic Materials", "shortName": "Copper", "legend": "Copper", "description": "The Copper industry includes companies involved in the production and distribution of copper and copper-related products." },
    { "sector": "Basic Materials", "shortName": "Building Materials", "legend": "Building Materials", "description": "The Building Materials industry comprises companies that manufacture and distribute construction and building materials, including concrete, cement, and structural components." },
    { "sector": "Basic Materials", "shortName": "Gold", "legend": "Gold", "description": "The Gold industry includes companies engaged in the exploration, mining, and distribution of gold and precious metals." },
    { "sector": "Basic Materials", "shortName": "Steel", "legend": "Steel", "description": "The Steel industry includes companies that manufacture and distribute steel and steel-related products for construction, manufacturing, and industrial applications." },
    { "sector": "Basic Materials", "shortName": "Coking Coal", "legend": "Coking Coal", "description": "The Coking Coal industry includes companies involved in the mining and production of coking coal, which is used in steel production and metallurgical processes." },
    { "sector": "Basic Materials", "shortName": "Thermal Coal", "legend": "Thermal Coal", "description": "The Thermal Coal industry includes companies engaged in the mining and production of thermal coal, which is used in power generation and industrial processes." },
    { "sector": "Basic Materials", "shortName": "Other Precious Metals & Mining", "legend": "Other Precious Metals & Mining", "description": "The Other Precious Metals & Mining industry comprises companies involved in the exploration, mining, and production of other precious metals, such as silver, platinum, and palladium." },
    { "sector": "Basic Materials", "shortName": "Paper & Paper Products", "legend": "Paper & Paper Products", "description": "The Paper & Paper Products industry comprises companies involved in the manufacturing and distribution of paper and paper-based products, including packaging, printing, and specialty paper." },
    { "sector": "Basic Materials", "shortName": "Other Industrial Metals & Mining", "legend": "Other Industrial Metals & Mining", "description": "The Other Industrial Metals & Mining industry includes companies engaged in the exploration and production of industrial metals other than steel, aluminum, and precious metals." },
    { "sector": "Basic Materials", "shortName": "Lumber & Wood Production", "legend": "Lumber & Wood Production", "description": "The Lumber & Wood Production industry includes companies involved in lumber and wood product manufacturing and distribution for construction and industrial applications." },
    { "sector": "Real Estate", "shortName": "REIT Mortgage", "legend": "Real Estate Investment Trust (REIT) - Mortgage", "description": "The Real Estate Investment Trust (REIT) - Mortgage industry comprises REITs that invest primarily in mortgage-backed securities and real estate-related debt instruments." },
    { "sector": "Real Estate", "shortName": "REIT Residential", "legend": "Real Estate Investment Trust (REIT) - Residential", "description": "The Real Estate Investment Trust (REIT) - Residential industry includes REITs that primarily invest in residential real estate properties, such as apartments, single-family homes, and residential communities." },
    { "sector": "Real Estate", "shortName": "REIT Specialty", "legend": "Real Estate Investment Trust (REIT) - Specialty", "description": "The Real Estate Investment Trust (REIT) - Specialty industry includes REITs that focus on specific niche or specialized real estate sectors, such as healthcare properties, data centers, or infrastructure." },
    { "sector": "Real Estate", "shortName": "REIT Office", "legend": "Real Estate Investment Trust (REIT) - Office", "description": "The Real Estate Investment Trust (REIT) - Office industry comprises REITs that invest primarily in office real estate properties, such as office buildings and commercial office spaces." },
    { "sector": "Real Estate", "shortName": "Real Estate Services", "legend": "Real Estate Services", "description": "The Real Estate Services industry comprises companies that offer real estate-related services, including property management, real estate brokerage, and real estate advisory services." },
    { "sector": "Real Estate", "shortName": "Residential Construction", "legend": "Residential Construction", "description": "The Residential Construction industry comprises companies involved in the construction and development of residential real estate, including single-family homes, apartment buildings, and residential communities." },
    { "sector": "Real Estate", "shortName": "REIT Industrial", "legend": "Real Estate Investment Trust (REIT) - Industrial", "description": "The Real Estate Investment Trust (REIT) - Industrial industry includes REITs that invest primarily in industrial real estate properties, such as warehouses, distribution centers, and industrial facilities." },
    { "sector": "Real Estate", "shortName": "REIT Retail", "legend": "Real Estate Investment Trust (REIT) - Retail", "description": "The Real Estate Investment Trust (REIT) - Retail industry comprises REITs that invest primarily in retail and shopping center properties, including malls, outlets, and retail spaces." },
    { "sector": "Real Estate", "shortName": "REIT Hotel & Motel", "legend": "Real Estate Investment Trust (REIT) - Hotel & Motel", "description": "The Real Estate Investment Trust (REIT) - Hotel & Motel industry includes REITs that primarily invest in hotel and motel real estate properties, offering lodging and hospitality services." },
    { "sector": "Real Estate", "shortName": "REIT Diversified", "legend": "Real Estate Investment Trust (REIT) - Diversified", "description": "The Real Estate Investment Trust (REIT) - Diversified industry includes REITs that invest in a diversified range of real estate properties and sectors, offering a balanced portfolio of real estate investments." },
    { "sector": "Real Estate", "shortName": "REIT Healthcare Facilities", "legend": "Real Estate Investment Trust (REIT) - Healthcare Facilities", "description": "The Real Estate Investment Trust (REIT) - Healthcare Facilities industry comprises REITs that invest primarily in healthcare and medical facilities, such as hospitals and healthcare centers." },
    { "sector": "Real Estate", "shortName": "Real Estate Development", "legend": "Real Estate Development", "description": "The Real Estate Development industry consists of companies that develop and construct real estate properties, including residential, commercial, and mixed-use projects." },
    { "sector": "Real Estate", "shortName": "Real Estate Diversified", "legend": "Real Estate - Diversified", "description": "The Real Estate - Diversified industry includes companies with diversified real estate holdings across various property types, such as residential, commercial, and industrial properties." },
    { "sector": "Utilities", "shortName": "Utilities Regulated Electric", "legend": "Utilities - Regulated Electric", "description": "The Utilities - Regulated Electric industry consists of companies that provide regulated electric utility services, including the generation, transmission, and distribution of electricity." },
    { "sector": "Utilities", "shortName": "Utilities Diversified", "legend": "Utilities - Diversified", "description": "The Utilities - Diversified industry includes companies that provide a diversified range of utility services, including electricity, water, and gas, to residential and commercial customers." },
    { "sector": "Utilities", "shortName": "Utilities Regulated Gas", "legend": "Utilities - Regulated Gas", "description": "The Utilities - Regulated Gas industry consists of companies that provide regulated gas utility services, including the distribution and supply of natural gas to residential and commercial customers." },
    { "sector": "Utilities", "shortName": "Utilities Regulated Water", "legend": "Utilities - Regulated Water", "description": "The Utilities - Regulated Water industry consists of companies that provide regulated water utility services, including water treatment and distribution to residential and commercial customers." },
    { "sector": "Utilities", "shortName": "Utilities Renewable", "legend": "Utilities - Renewable Energy", "description": "The Utilities - Renewable Energy industry includes companies that generate and supply energy from renewable sources, such as solar, wind, and hydropower, promoting sustainable and clean energy solutions." },
    { "sector": "Utilities", "shortName": "Solar", "legend": "Solar", "description": "The Solar industry consists of companies that manufacture, install, and operate solar power systems and renewable energy solutions, harnessing solar energy for electricity generation." },
    { "sector": "Utilities", "shortName": "Utilities Independent Power Producers", "legend": "Utilities - Independent Power Producers", "description": "The Utilities - Independent Power Producers industry comprises companies that generate electricity independently and sell it to utility companies and the power grid." },
  ],

  HISTORICALCHART_OPTIONS: [
    { "shortName": "Additional Paid-in Capital", "aggCode": "ADDPAIDCAP", "legend": "Additional Paid-in Capital" },
    { "shortName": "Available For Sale Sec.", "aggCode": "AFS", "legend": "Available For Sale Securities" },
    { "shortName": "Assets/Sales", "aggCode": "ASS.SAL", "legend": "Assets-to-Sales Ratio" },
    { "shortName": "Assets", "aggCode": "ASSETS", "legend": "Total Assets" },
    { "shortName": "Assets, Current", "aggCode": "ASSETS.CUR", "legend": "Total Current Assets" },
    { "shortName": "Assets, Chg", "aggCode": "ASSETS|CHG", "legend": "" },
    { "shortName": "Assets, 1-Year % Chg", "aggCode": "ASSETS|PCHG.1Y", "legend": "Percentage change in total assets over one-year period*" },
    { "shortName": "Cash equivalents", "aggCode": "CASHEQ", "legend": "Cash and Cash Equivalents" },
    { "shortName": "Change in Cash Eq.", "aggCode": "CASHEQ.CHG", "legend": "Increase/(Decrease) in cash and cash equivalents" },
    { "shortName": "Change in Cash Eq. (TTM)", "aggCode": "CASHEQ.CHG.TTM", "legend": "Increase/(Decrease) in cash and cash equivalents" },
    { "shortName": "Cash From Finance", "aggCode": "CFFIN", "legend": "Cash used in financing activities" },
    { "shortName": "Cash From Finance (TTM)", "aggCode": "CFFIN.TTM", "legend": "Cash used in financing activities" },
    { "shortName": "Cash From Investments", "aggCode": "CFINV", "legend": "Cash generated by/(used in) investing activities" },
    { "shortName": "Cash From Investments (TTM)", "aggCode": "CFINV.TTM", "legend": "Cash generated by/(used in) investing activities" },
    { "shortName": "Cash from Operations", "aggCode": "CFOPER", "legend": "Cash generated by operating activities" },
    { "shortName": "Cash from Operations (TTM)", "aggCode": "CFOPER.TTM", "legend": "Cash generated by operating activities" },
    { "shortName": "Contigencies", "aggCode": "CNTG", "legend": "" },
    { "shortName": "Cost of Goods Sold", "aggCode": "COGS", "legend": "Cost of Goods Sold*" },
    { "shortName": "Comprehensive Income", "aggCode": "COMPINC", "legend": "Total comprehensive income" },
    { "shortName": "Comprehensive Income (TTM)", "aggCode": "COMPINC.TTM", "legend": "Total comprehensive income" },
    { "shortName": "Cost of Revenue", "aggCode": "COSTREV", "legend": "Cost of Revenue*" },
    { "shortName": "Costs", "aggCode": "COSTS", "legend": "" },
    { "shortName": "Current Ratio", "aggCode": "CR", "legend": "Current Ratio" },
    { "shortName": "Debt/Cashflow", "aggCode": "DEBT.CFOPER", "legend": "Debt Cashflow Coverage" },
    { "shortName": "Debt/Equity", "aggCode": "DEBT.EQ", "legend": "Debt To Equity Ratio" },
    { "shortName": "Long Term Debt", "aggCode": "DEBT.LT", "legend": "Long Term Debt" },
    { "shortName": "Debt/Assets", "aggCode": "DEBT.RAT", "legend": "Debt to Assets Ratio" },
    { "shortName": "Depreciation", "aggCode": "DEPAMOR", "legend": "Depreciation and amortization" },
    { "shortName": "Depreciation (TTM)", "aggCode": "DEPAMOR.TTM", "legend": "Depreciation and amortization (Trailing 12-Months)" },
    { "shortName": "Dividends Per Share", "aggCode": "DIV.PS", "legend": "Cash dividends declared per share" },
    { "shortName": "Dividends Per Share (TTM)", "aggCode": "DIV.PS.TTM", "legend": "Cash dividends declared per share (Trailing 12-Months)" },
    { "shortName": "Dividend Yld", "aggCode": "DIV.YIELD", "legend": "Dividend Yield" },
    { "shortName": "Drawdowns", "aggCode": "DRAWDN", "legend": "DrawDown" },
    { "shortName": "Probability of 30% Drawdown", "aggCode": "DRAWDN.PROB30|5Y", "legend": "Probability of 30% Drawdown  in 1-year holding period" },
    { "shortName": "Probability of 40% Drawdown", "aggCode": "DRAWDN.PROB40|5Y", "legend": "Probability of 40% Drawdown in 1-year holding period" },
    { "shortName": "EBIT (TTM)", "aggCode": "EBIT.TTM", "legend": "Earnings Before Taxes, Interest, Depreciation and Amortization (EBITDA)" },
    { "shortName": "EBITDA Margin", "aggCode": "EBITDA.MGN", "legend": "Earnings Before Taxes, Interest, Depreciation and Amortization Margin (EBITDA Margin)" },
    { "shortName": "EBITDA (TTM)", "aggCode": "EBITDA.TTM", "legend": "Earnings Before Taxes, Interest, Depreciation and Amortization (EBITDA)" },
    { "shortName": "Earnings Before Tax", "aggCode": "EBT", "legend": "Income before provision for income taxes" },
    { "shortName": "EBT Margin", "aggCode": "EBT.MGN", "legend": "Earnings Before Tax Margin" },
    { "shortName": "Earnings Before Tax (TTM)", "aggCode": "EBT.TTM", "legend": "Income before provision for income taxes" },
    { "shortName": "EPS, Basic", "aggCode": "EPS", "legend": "Earnings Per Share, Basic" },
    { "shortName": "EPS, Diluted", "aggCode": "EPS.DIL", "legend": "Earnings Per Share, Diluted" },
    { "shortName": "EPS, Diluted (TTM)", "aggCode": "EPS.DIL.TTM", "legend": "Earnings Per Share, Diluted (Trailing 12-Months)" },
    { "shortName": "EPS, Basic (TTM)", "aggCode": "EPS.TTM", "legend": "Earnings Per Share, Basic (Trailing 12-Months)" },
    { "shortName": "EV", "aggCode": "EV", "legend": "Enterprise Value" },
    { "shortName": "EV/EBITDA", "aggCode": "EV.EBITDA", "legend": "EV to EBITDA Ratio" },
    { "shortName": "Advertising Expense", "aggCode": "EXP.ADV", "legend": "Advertising expense" },
    { "shortName": "Advertising Expense (TTM)", "aggCode": "EXP.ADV.TTM", "legend": "Advertising expense (Trailing 12-Months)" },
    { "shortName": "Interest Expense", "aggCode": "EXP.INT", "legend": "Interest expense" },
    { "shortName": "Interest Expense (TTM)", "aggCode": "EXP.INT.TTM", "legend": "Interest expense (Trailing 12-Months)" },
    { "shortName": "Sharebased Compensation Expense", "aggCode": "EXP.SHCOMP", "legend": "Share-based Compensation Expense*" },
    { "shortName": "Free Cashflow", "aggCode": "FCF", "legend": "Free Cashflow (FCF)" },
    { "shortName": "Free Cashflow Per Share, TTM", "aggCode": "FCF.PS.TTM", "legend": "Free Cashflow Per Share, Trailing 12-Months" },
    { "shortName": "Free Cashflow, TTM", "aggCode": "FCF.TTM", "legend": "Free Cashflow (FCF), Trailing 12-Months" },
    { "shortName": "FCF Yield", "aggCode": "FCF.YIELD", "legend": "Free Cashflow Yield" },
    { "shortName": "Float", "aggCode": "FLOAT", "legend": "" },
    { "shortName": "Goodwill", "aggCode": "GWILL", "legend": "Goodwill" },
    { "shortName": "Impairment of Assets", "aggCode": "IMP.ASSETS", "legend": "Impairment of Assets*" },
    { "shortName": "Goodwill Impairment", "aggCode": "IMP.GWILL", "legend": "Goodwill impairment charges" },
    { "shortName": "Goodwill Impairment (TTM)", "aggCode": "IMP.GWILL.TTM", "legend": "Goodwill impairment charges (TTM)" },
    { "shortName": "Inst. Holdings", "aggCode": "INSTPCT", "legend": "Institutional Ownership Percentage*" },
    { "shortName": "Interest/Cashflow", "aggCode": "INT.CFOPER", "legend": "Interest to Cashflow Ratio" },
    { "shortName": "Interest Coverage", "aggCode": "INT.COV", "legend": "Interest Coverage Ratio" },
    { "shortName": "Invesntory Writedown", "aggCode": "INVWD", "legend": "Invesntory Writedown*" },
    { "shortName": "Inventory, Net", "aggCode": "INVY", "legend": "Inventories" },
    { "shortName": "Inventory, Change", "aggCode": "INVY.CHG", "legend": "Change in Inventories" },
    { "shortName": "Inventory, Change (TTM)", "aggCode": "INVY.CHG.TTM", "legend": "Change in Inventories" },
    { "shortName": "Income Tax Rate", "aggCode": "ITXR", "legend": "Income Tax Rate*" },
    { "shortName": "Liabilities", "aggCode": "LIABS", "legend": "Total liabilities" },
    { "shortName": "Liabilities, Current", "aggCode": "LIABS.CUR", "legend": "Total current liabilities" },
    { "shortName": "Stock Eq and Liab.", "aggCode": "LIABSEQ", "legend": "Total liabilities and shareholders equity" },
    { "shortName": "Line of Credit", "aggCode": "LOC", "legend": "Line of Credit*" },
    { "shortName": "Long-term Capital Ratio", "aggCode": "LTCAP", "legend": "Long-term Capital Ratio" },
    { "shortName": "Minority Interest", "aggCode": "MININT", "legend": "Minority Interest*" },
    { "shortName": "Market Cap", "aggCode": "MKTCAP", "legend": "Market Capital" },
    { "shortName": "MktCap/EBT", "aggCode": "MKTEBT", "legend": "Market Capital to Earnings Before Tax Ratio" },
    { "shortName": "Net Income", "aggCode": "NETINC", "legend": "Net income" },
    { "shortName": "Net Income Margin (TTM)", "aggCode": "NETINC.MGN", "legend": "Net Earnings Margin (Trailing 12-Months)" },
    { "shortName": "Net Income (TTM)", "aggCode": "NETINC.TTM", "legend": "Net income" },
    { "shortName": "Net Income, TTM, Chg", "aggCode": "NETINC.TTM|CHG", "legend": "Change in Net-Income over the trailing twelve months (TTM)*" },
    { "shortName": "Net Income, TTM, 1-Year Chg", "aggCode": "NETINC.TTM|CHG.1Y", "legend": "" },
    { "shortName": "Net Income, TTM, % Chg", "aggCode": "NETINC.TTM|PCHG", "legend": "" },
    { "shortName": "Net Income, TTM, 1-Year % Chg", "aggCode": "NETINC.TTM|PCHG.1Y", "legend": "" },
    { "shortName": "Net Income, Chg", "aggCode": "NETINC|CHG", "legend": "Net Income, Change" },
    { "shortName": "Net Income, 1-Year Chg", "aggCode": "NETINC|CHG.1Y", "legend": "Net Income, 1 Year Change" },
    { "shortName": "Net Income, % Chg", "aggCode": "NETINC|PCHG", "legend": "Net Income, Percent Change" },
    { "shortName": "Net Income, 1-Year % Chg", "aggCode": "NETINC|PCHG.1Y", "legend": "Net Income, 1-Year Percent Change" },
    { "shortName": "Net PPE", "aggCode": "NETPPE", "legend": "" },
    { "shortName": "Operating Margin", "aggCode": "OPER.MGN", "legend": "" },
    { "shortName": "Operating Expense", "aggCode": "OPEX", "legend": "Total operating expenses" },
    { "shortName": "G&A Expense", "aggCode": "OPEX.GA", "legend": "" },
    { "shortName": "R&D, Expense", "aggCode": "OPEX.RND", "legend": "Research and development" },
    { "shortName": "R&D, Expense (TTM)", "aggCode": "OPEX.RND.TTM", "legend": "Research and development" },
    { "shortName": "SG&A, Expense", "aggCode": "OPEX.SGA", "legend": "Selling, general and administrative" },
    { "shortName": "SG&A, Expense (TTM)", "aggCode": "OPEX.SGA.TTM", "legend": "Selling, general and administrative" },
    { "shortName": "Operating Expense (TTM)", "aggCode": "OPEX.TTM", "legend": "Total operating expenses" },
    { "shortName": "Price/Cashflow", "aggCode": "PCF", "legend": "Price to Cashflow Ratio" },
    { "shortName": "6-mth , % Change", "aggCode": "PCHG|180", "legend": "6 Months Stock Price Percent Change" },
    { "shortName": "1-Mth , % Change", "aggCode": "PCHG|30", "legend": "1 Month Stock Price Percent Change" },
    { "shortName": "2-Mth , % Change", "aggCode": "PCHG|60", "legend": "2 Months Stock Price Percent Change" },
    { "shortName": "1 Week , % Change", "aggCode": "PCHG|7", "legend": "1 Week Stock Price Percent Change" },
    { "shortName": "3-Mth , % Change", "aggCode": "PCHG|90", "legend": "3 Months Stock Price Percent Change" },
    { "shortName": "Monthly % Change", "aggCode": "PCHG|M", "legend": "Monthly Percent Change" },
    { "shortName": "Quarterly % Change", "aggCode": "PCHG|Q", "legend": "Quarterly Percent Change" },
    { "shortName": "Weekly % Change", "aggCode": "PCHG|W", "legend": "Weekly Percent Change" },
    { "shortName": "Yearly Return", "aggCode": "PCHG|Y", "legend": "Yearly Stock Return" },
    { "shortName": "Price/Earnings", "aggCode": "PE", "legend": "Price to Earnings Ratio" },
    { "shortName": "Collection Period", "aggCode": "PER.AVGCOLL", "legend": "Average Collection Period" },
    { "shortName": "Avg. Inventory Period", "aggCode": "PER.AVGINVY", "legend": "Average Inventory Period*" },
    { "shortName": "Price / FCF", "aggCode": "PFCF", "legend": "Price to Free Cash Flow (P/FCF)" },
    { "shortName": "Payments for Business Acquisition", "aggCode": "PMT.ACQ.BUS", "legend": "Payments for Business Acquisition*" },
    { "shortName": "Payments for Inventory Acquisition", "aggCode": "PMT.ACQ.INV", "legend": "Payments for Inventory Acquisition*" },
    { "shortName": "Payments for Others Acquisitions", "aggCode": "PMT.ACQ.OTR", "legend": "Payments for Others Acquisitions*" },
    { "shortName": "Dividends", "aggCode": "PMT.DIV", "legend": "Dividends" },
    { "shortName": "Dividends", "aggCode": "PMT.DIV.CS", "legend": "Dividend Payments" },
    { "shortName": "Dividends (TTM)", "aggCode": "PMT.DIV.CS.TTM", "legend": "Dividend Payments (TTM)" },
    { "shortName": "Dividends (TTM)", "aggCode": "PMT.DIV.TTM", "legend": "Dividend Payments (TTM)" },
    { "shortName": "Stock Buy Backs", "aggCode": "PMT.REP.SHR", "legend": "Repurchases of common stock" },
    { "shortName": "Stock Buy Backs (TTM)", "aggCode": "PMT.REP.SHR.TTM", "legend": "Repurchases of common stock" },
    { "shortName": "PPE, Gross", "aggCode": "PPE.GROSS", "legend": "Gross property, plant and equipment" },
    { "shortName": "PPE, Net", "aggCode": "PPE.NET", "legend": "Property, plant and equipment, net" },
    { "shortName": "Price", "aggCode": "PRICE", "legend": "Price" },
    { "shortName": "Gross Profit", "aggCode": "PROFIT.GROSS", "legend": "Gross margin" },
    { "shortName": "Gross Profit (TTM)", "aggCode": "PROFIT.GROSS.TTM", "legend": "Gross margin" },
    { "shortName": "Operating Profit", "aggCode": "PROFIT.OPER", "legend": "Operating income" },
    { "shortName": "Operating Profit (TTM)", "aggCode": "PROFIT.OPER.TTM", "legend": "Operating income (TTM)" },
    { "shortName": "Price/Sales", "aggCode": "PS", "legend": "Price to Sales Ratio" },
    { "shortName": "Retained Earnings", "aggCode": "RETEARN", "legend": "Retained earnings" },
    { "shortName": "3-yr Rolling Returns", "aggCode": "RETURNS|1095", "legend": "3-yr Rolling Returns" },
    { "shortName": "5-yr Rolling Returns", "aggCode": "RETURNS|1825", "legend": "5-yr Rolling Returns" },
    { "shortName": "7-yr Rolling Returns", "aggCode": "RETURNS|2555", "legend": "7-yr Rolling Returns" },
    { "shortName": "1-yr Rolling Returns", "aggCode": "RETURNS|365", "legend": "1-yr Rolling Returns" },
    { "shortName": "10-yr Rolling Returns", "aggCode": "RETURNS|3650", "legend": "10-yr Rolling Returns" },
    { "shortName": "Revenues", "aggCode": "REV", "legend": "Net sales" },
    { "shortName": "Revenues (TTM)", "aggCode": "REV.TTM", "legend": "Net sales" },
    { "shortName": "Revenues, TTM, Chg", "aggCode": "REV.TTM|CHG", "legend": "" },
    { "shortName": "Revenues, TTM, 1-Year Chg", "aggCode": "REV.TTM|CHG.1Y", "legend": "" },
    { "shortName": "Revenues, TTM, % Chg", "aggCode": "REV.TTM|PCHG", "legend": "" },
    { "shortName": "Revenues, TTM, 1-Year % Chg", "aggCode": "REV.TTM|PCHG.1Y", "legend": "" },
    { "shortName": "Revenues, Chg", "aggCode": "REV|CHG", "legend": "Net sales, Change" },
    { "shortName": "Revenues, 1-Year Chg", "aggCode": "REV|CHG.1Y", "legend": "Net sales, 1 Year Change" },
    { "shortName": "Revenues, % Chg", "aggCode": "REV|PCHG", "legend": "Net sales, Percent Change" },
    { "shortName": "Revenues, 1-Year % Chg", "aggCode": "REV|PCHG.1Y", "legend": "Net sales, 1-Year Percent Change" },
    { "shortName": "Grufity Risk", "aggCode": "RISKLEVEL|5Y", "legend": "Grufity Risk Level Assessment" },
    { "shortName": "Return on Assets", "aggCode": "ROA", "legend": "Return on Assets" },
    { "shortName": "Return on Equity", "aggCode": "ROE", "legend": "Return on Equity" },
    { "shortName": "Repayment of Long-term Date", "aggCode": "RPY.LTD", "legend": "" },
    { "shortName": "Sales/Inventory", "aggCode": "SAL.INV", "legend": "Sales to Investory Ratio" },
    { "shortName": "Stockholders Equity", "aggCode": "SEQ", "legend": "Total shareholders equity" },
    { "shortName": "Shares Outstanding", "aggCode": "SHR.OUTS", "legend": "Entity Common Stock, Shares Outstanding" },
    { "shortName": "Shares Based Compensation", "aggCode": "SHRCOMP", "legend": "Shares Based Compensation*" },
    { "shortName": "Shares Based Compensation (TTM)", "aggCode": "SHRCOMP.TTM", "legend": "Shares Based Compensation (TTM)*" },
    { "shortName": "Revenues/Assets", "aggCode": "TO.ASSET", "legend": "Assets Turnover Ratio" },
    { "shortName": "Inventory Turnover", "aggCode": "TO.INV", "legend": "Inventory Turnover*" },
    { "shortName": "Payables Turnover", "aggCode": "TO.PAYB", "legend": "Payables Turnover*" },
    { "shortName": "Revenues/Receivables", "aggCode": "TO.REC", "legend": "Receivables Turnover Ratio" },
    { "shortName": "Yearly Volatility", "aggCode": "VOL|Y", "legend": "Yearly Volatility" },
    { "shortName": "Watermarks", "aggCode": "WATERMARK", "legend": "Rolling Watermark" }],

  DEFAULT_TBL_COLS: [
    { "label": "Fundamentals", "name": "Ticker", "tooltip": "Ticker", "field": "ticker", "urlField": "ticker", "class": "text-left", sortFunc: (a, b) => a.localeCompare(b) },
    { "label": "Fundamentals", "name": "Name", "tooltip": "Name", "field": "tickername", "class": "td-color", "urlField": "ticker" },
    { "label": "Fundamentals", "name": "FCF PSh TTM", "field": "FCF_PS_TTM", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Free Cashflow Per Share, Trailing 12 Months" },
    { "label": "Fundamentals", "name": "FCF TTM", "field": "FCF_TTM", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Free Cashflow (FCF), Trailing 12-Months" },
    { "label": "Fundamentals", "name": "Industry", "field": "industry", "class": "text-left", "isSectorValue": false, "isPct": false, "mult": 1, "tooltip": "Industry" },
    { "label": "Fundamentals", "name": "Inst Pct", "field": "INSTPCT", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 1, "tooltip": "Percentage Shares held by Institutional Investors" },
    { "label": "Fundamentals", "name": "Market Cap", "field": "MKTCAP", "class": "sorted", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Market Capital" },
    { "label": "Fundamentals", "name": "Grufity Risk", "field": "RISKLEVEL__5Y", "class": "text-right", "isSectorValue": true, "isPct": true, "mult": 1, "tooltip": "Grufity Risk Level Assessment" },
    { "label": "Fundamentals", "name": "Sector", "field": "sector", "class": "text-left", "isSectorValue": false, "isPct": false, "mult": 1, "tooltip": "Sector" },
    { "label": "Returns", "name": "Drawdowns", "field": "DRAWDN", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 1, "tooltip": "DrawDown" },
    { "label": "Returns", "name": "Prob 30 DW", "field": "DRAWDN_PROB30__5Y", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 1, "tooltip": "Probability of 30% Drawdown  in 1-Year holding period" },
    { "label": "Returns", "name": "6M Pct", "field": "PCHG__180", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 100, "tooltip": "6-Months Stock Price Percent Change" },
    { "label": "Returns", "name": "1M Pct", "field": "PCHG__30", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 100, "tooltip": "1-Month Stock Price Percent Change" },
    { "label": "Returns", "name": "1Y Pct", "field": "PCHG__365", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 100, "tooltip": "1-Year Percent Change in Stock Price" },
    { "label": "Returns", "name": "1W Pct", "field": "PCHG__7", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 100, "tooltip": "1-Week Stock Price Percent Change" },
    { "label": "Returns", "name": "3Y Ret", "field": "RETURNS__1095", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 100, "tooltip": "3-Year Rolling Returns" },
    { "label": "Returns", "name": "5Y Ret", "field": "RETURNS__1825", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 100, "tooltip": "5-Year Rolling Returns" },
    { "label": "Returns", "name": "7Y Ret", "field": "RETURNS__2555", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 1, "tooltip": "7-Year Rolling Returns" },
    { "label": "Returns", "name": "10Y Ret", "field": "RETURNS__3650", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 1, "tooltip": "10-Year Rolling Returns" },
    { "label": "Valuation", "name": "Debt To Eq", "field": "DEBT_EQ", "class": "text-right", "isSectorValue": false, "isPct": false, "mult": 1, "tooltip": "Debt To Equity Ratio" },
    { "label": "Valuation", "name": "Debt To Assets", "field": "DEBT_RAT", "class": "text-right", "isSectorValue": false, "isPct": false, "mult": 1, "tooltip": "Debt To Assets Ratio" },
    { "label": "Valuation", "name": "Div Yld", "field": "DIV_YIELD", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 1, "tooltip": "Dividend Yield" },
    { "label": "Valuation", "name": "EV", "field": "EV", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Enterprise Value" },
    { "label": "Valuation", "name": "EV EBITDA", "field": "EV_EBITDA", "class": "text-right", "isSectorValue": false, "isPct": false, "mult": 1, "tooltip": "EV to EBITDA Ratio" },
    { "label": "Valuation", "name": "FCF Yield", "field": "FCF_YIELD", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 1, "tooltip": "Free Cashflow Yield" },
    { "label": "Valuation", "name": "MktCap To EBT", "field": "MKTEBT", "class": "text-right", "isSectorValue": false, "isPct": false, "mult": 1, "tooltip": "Market Capital to Earnings Before Tax Ratio" },
    { "label": "Valuation", "name": "PCF", "field": "PCF", "class": "text-right", "isSectorValue": false, "isPct": false, "mult": 1, "tooltip": "Price to Cashflow Ratio" },
    { "label": "Valuation", "name": "PE", "field": "PE", "class": "text-right", "isSectorValue": false, "isPct": false, "mult": 1, "tooltip": "Price to Earnings Ratio" },
    { "label": "Valuation", "name": "PFCF", "field": "PFCF", "class": "text-right", "isSectorValue": false, "isPct": false, "mult": 1, "tooltip": "Price to Free Cash Flow" },
    { "label": "Valuation", "name": "PS", "field": "PS", "class": "text-right", "isSectorValue": false, "isPct": false, "mult": 1, "tooltip": "Price to Sales Ratio" },
    { "label": "Valuation", "name": "ROE", "field": "ROE", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 1, "tooltip": "Return on Equity" },
    { "label": "Valuation", "name": "ROA", "field": "ROA", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 1, "tooltip": "Return on Assets" },
    { "label": "Growth", "name": "EBIT 1Y Grw", "field": "EBIT_TTM__PCHG_1Y", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 1, "tooltip": "1-Year Grw of Earnings Before Interest and Taxes TTM" },
    { "label": "Growth", "name": "EBIT 3Y Grw", "field": "EBIT_TTM__PCHG_3Y", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 1, "tooltip": "3-Year Grw of Earnings Before Interest and Taxes TTM" },
    { "label": "Growth", "name": "Earn 1Y Grw", "field": "NETINC_TTM__PCHG_1Y", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 1, "tooltip": "Earnings Grw, 1-Year" },
    { "label": "Growth", "name": "Earn 3Y Grw", "field": "NETINC_TTM__PCHG_3Y", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 1, "tooltip": "Earnings Grw, 3-Year" },
    { "label": "Growth", "name": "Earn YoY Grw", "field": "NETINC__PCHG_1Y", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 1, "tooltip": "Earnings Grw, Year-on-Year" },
    { "label": "Growth", "name": "Rev 1Y Grw", "field": "REV_TTM__PCHG_1Y", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 1, "tooltip": "Reveue Grw in past 1-Year" },
    { "label": "Growth", "name": "Rev 3Y Grw", "field": "REV_TTM__PCHG_3Y", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 1, "tooltip": "Reveue Grw in past 3-Years" },
    { "label": "Growth", "name": "Rev Grw YoY", "field": "REV__PCHG_1Y", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 1, "tooltip": "Reveue Grw Year-over-Year" },
    { "label": "Growth", "name": "BuyBack 1Y", "field": "SHR_OUTS__PCHG_1Y", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 1, "tooltip": "Buy Back Or Dilution in 1-Year" },
    { "label": "Growth", "name": "BuyBack 3Y", "field": "SHR_OUTS__PCHG_3Y", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 1, "tooltip": "Buy Back Or Dilution in 3-Years" },
    { "label": "Income Statement", "name": "Cost of Rev", "field": "COSTREV", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Cost of Revenue" },
    { "label": "Income Statement", "name": "Div Per Sh TTM", "field": "DIV_PS_TTM", "class": "text-right", "isSectorValue": false, "isPct": false, "mult": 1, "tooltip": "Cash dividends declared per share (Trailing 12-Months)" },
    { "label": "Income Statement", "name": "EBIT TTM", "field": "EBIT_TTM", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Earnings Before Interest and Taxes" },
    { "label": "Income Statement", "name": "EBITDA Margin", "field": "EBITDA_MGN", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 100, "tooltip": "Earnings Before Taxes, Interest, Dep. and Amort. Margin" },
    { "label": "Income Statement", "name": "EBITDA TTM", "field": "EBITDA_TTM", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Earnings Before Taxes, Interest, Dep. and Amort. (EBITDA)" },
    { "label": "Income Statement", "name": "EBT Margin", "field": "EBT_MGN", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 100, "tooltip": "Earnings Before Tax Margin" },
    { "label": "Income Statement", "name": "EBT TTM", "field": "EBT_TTM", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Earnings Before Tax (Last 12 Months)" },
    { "label": "Income Statement", "name": "EPS Basic", "field": "EPS", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Earnings Per Share, Basic" },
    { "label": "Income Statement", "name": "EPS Basic TTM", "field": "EPS_TTM", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Earnings Per Share, Basic (Last 12 Months)" },
    { "label": "Income Statement", "name": "Impair Goodwill", "field": "IMP_GWILL", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Goodwill Impairment" },
    { "label": "Income Statement", "name": "Inc Tax Rate", "field": "ITXR", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 100, "tooltip": "Income Tax Rate" },
    { "label": "Income Statement", "name": "Net Inc Mgn", "field": "NETINC_MGN", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 100, "tooltip": "Net Earnings Margin (Trailing 12-Months)" },
    { "label": "Income Statement", "name": "Net Inc TTM", "field": "NETINC_TTM", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Net income, TTM" },
    { "label": "Income Statement", "name": "Oper Margin", "field": "OPER_MGN", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 100, "tooltip": "Operating Margin" },
    { "label": "Income Statement", "name": "Opex TTM", "field": "OPEX_TTM", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Operational Expense TTM" },
    { "label": "Income Statement", "name": "Gross Profit", "field": "PROFIT_GROSS_TTM", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Gross Profit TTM" },
    { "label": "Income Statement", "name": "Oper Profit", "field": "PROFIT_OPER_TTM", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Operating Profit TTM" },
    { "label": "Income Statement", "name": "Revenues TTM", "field": "REV_TTM", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Net sales" },
    { "label": "Balance Sheet", "name": "Assets", "field": "ASSETS", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Total Assets" },
    { "label": "Balance Sheet", "name": "Curr Assets", "field": "ASSETS_CUR", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Current Assets" },
    { "label": "Balance Sheet", "name": "Assets 1Y Grw", "field": "ASSETS__PCHG_1Y", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 1, "tooltip": "Percentage change in total assets over last 1-Year" },
    { "label": "Balance Sheet", "name": "Cash Eqs", "field": "CASHEQ", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Cash and Cash Equivalents" },
    { "label": "Balance Sheet", "name": "LT Debt", "field": "DEBT_LT", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Long Term Debt" },
    { "label": "Balance Sheet", "name": "Goodwill", "field": "GWILL", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Goodwill" },
    { "label": "Balance Sheet", "name": "Asset Imp", "field": "IMP_ASSETS", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Impairment of Assets" },
    { "label": "Balance Sheet", "name": "Invy WD", "field": "INVWD", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Inventory Writedown" },
    { "label": "Balance Sheet", "name": "Inventory", "field": "INVY", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Inventory" },
    { "label": "Balance Sheet", "name": "Liabilities", "field": "LIABS", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Total Liabilities" },
    { "label": "Balance Sheet", "name": "Current Liabs", "field": "LIABS_CUR", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Current Liabilities" },
    { "label": "Balance Sheet", "name": "Net PPE", "field": "PPE_NET", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Net Property Plant and Equipment" },
    { "label": "Balance Sheet", "name": "Equity", "field": "SEQ", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Total shareholders equity" },
    { "label": "Cashflow Statement", "name": "Cash From Fin", "field": "CFFIN", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Cash From Financing Activities" },
    { "label": "Cashflow Statement", "name": "Cash From Fin TTM", "field": "CFFIN_TTM", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Cash From Financing Activities (TTM)" },
    { "label": "Cashflow Statement", "name": "Cash From Inv", "field": "CFINV", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Cash From Investing Activities" },
    { "label": "Cashflow Statement", "name": "Cash From Inv TTM", "field": "CFINV_TTM", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Cash From Investing Activities (TTM)" },
    { "label": "Cashflow Statement", "name": "Cash from Oper", "field": "CFOPER", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Cash From Operating Activities" },
    { "label": "Cashflow Statement", "name": "Cash from Oper TTM", "field": "CFOPER_TTM", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Cash From Operating Activities (TTM)" },
    { "label": "Cashflow Statement", "name": "Deprec TTM", "field": "DEPAMOR_TTM", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Depreciation and Amortization TTM" },
    { "label": "Cashflow Statement", "name": "Ret Earn", "field": "RETEARN", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Retained earnings" },
    { "label": "Cashflow Statement", "name": "Ret Earn 1Y Grw", "field": "RETEARN__PCHG_1Y", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 1, "tooltip": "Retained Earnings Grw in past 1-Year" },
    { "label": "Cashflow Statement", "name": "Ret Earn 3Y Grw", "field": "RETEARN__PCHG_3Y", "class": "text-right", "isSectorValue": false, "isPct": true, "mult": 1, "tooltip": "Retained Earnings Grw in past 3-Year" },
    { "label": "Cashflow Statement", "name": "Share Comp TTM", "field": "SHRCOMP_TTM", "class": "text-right", "isSectorValue": true, "isPct": false, "mult": 1, "tooltip": "Shares Based Compensation (TTM)" },
    { "label": "Operations", "name": "Assets To Sales", "field": "ASS_SAL", "class": "text-right", "isSectorValue": false, "isPct": false, "mult": 1, "tooltip": "Assets-to-Sales Ratio" },
    { "label": "Operations", "name": "Current Ratio", "field": "CR", "class": "text-right", "isSectorValue": false, "isPct": false, "mult": 1, "tooltip": "Current Ratio" },
    { "label": "Operations", "name": "Int To CF", "field": "INT_CFOPER", "class": "text-right", "isSectorValue": false, "isPct": false, "mult": 1, "tooltip": "Interest to Cashflow Ratio" },
    { "label": "Operations", "name": "Int Cov", "field": "INT_COV", "class": "text-right", "isSectorValue": false, "isPct": false, "mult": 1, "tooltip": "Interest Coverage Ratio" },
    { "label": "Operations", "name": "Sales To Inv", "field": "SAL_INV", "class": "text-right", "isSectorValue": false, "isPct": false, "mult": 1, "tooltip": "Sales To Inventory Ratio" }
  ],

  BOTS: [
    "googlebot",
    "bingbot",
    "slurp", // Yahoo! Slurp
    "duckduckbot",
    "yandexbot",
    "facebot", // Facebook
    "applebot",
    "baiduspider", // Baidu
    "msnbot", // MSN Bot
    "teoma", // Ask Jeeves
    "archive.org_bot", // Internet Archive
    "ia_archiver", // Alexa
    "linkedinbot", // LinkedIn
    "twitterbot", // Twitter
    "pinterestbot", // Pinterest
    "slackbot", // Slack
    "discordbot", // Discord
    "whatsapp", // WhatsApp
    "telegrambot", // Telegram
    "zoominfoobot", // ZoomInfo
    "ahrefsbot", // Ahrefs
    "semrushbot", // SEMrush
    "mj12bot", // Majestic
    "rogerbot", // Moz
    "dotbot", // DotBot
    "exabot", // ExaBot
    "feedfetcher-google", // Google Feedfetcher
    "slack-imgproxy", // Slack Imgproxy
    "outbrain", // Outbrain
    "mediapartners-google", // Google AdSense
    "adsbot-google", // Google AdWords
    "pingdom", // Pingdom
    "uptimerobot", // Uptime Robot
    "uptimebot", // UptimeBot
    "sentry", // Sentry
    "datadog", // Datadog
    "phantomjs", // PhantomJS
    "gtmetrix", // GTmetrix
    "nutch", // Apache Nutch
    "aolbuild", // AOL Build
    "photon", // Photon by Jetpack (WordPress)
    "crawl", // Crawl by Qwant
    "bingpreview", // Bing Preview
    "adidxbot", // ADIDX Bot
    "yandeximages", // Yandex.Images
    "facebookexternalhit", // Facebook External Hit
    "pinterest/0.", // Pinterest
    "redditbot", // Reddit
    "flipboard", // Flipboard
    "w3c_validator", // W3C Validator
    "validator.nu", // Nu HTML Checker
    "apple-pubsub", // Apple PubSub
    "fastly", // Fastly CDN
    "xenu link sleuth", // Xenu Link Sleuth
    "brave",
    "aol",
    "bitlybot",
    "shorte",
    "ezooms",
    "panscient",
    "smtbot",
    "synapse",
    "gigablast",
    "fr-crawler",
    "voilabot",
    "admantx",
    "serendeputy",
    "seznambot",
    "addthis",
    "pcore-http",
    "pocket",
    "curl",
    "google favicon",
    "calypso",
    "screaming frog",
    "seokicks",
    "hootsuite",
    "wotbox",
    "sogou",
    "wappalyzer",
    "zabbix",
    "box",
    "PetalBot"
  ],

  TOPMETRICS_TICKETSANDNAMES: [
    { "t": "DIA", "n": "Dow Jones 30" },
    { "t": "SPY", "n": "S&P 500" },
    { "t": "IWM", "n": "Russell 2000" },
    { "t": "TLT", "n": "20+ Yr Treasury" },
    { "t": "GLD", "n": "Gold" },
    { "t": "USO", "n": "Crude Oil" },
    { "t": "AAPL", "u": "/stock/AAPL", "n": "Apple Inc." },
    { "t": "AMZN", "u": "/stock/AMZN", "n": "Amazon.com Inc." },
    { "t": "GOOG", "u": "/stock/GOOG", "n": "Alphabet Inc." },
    { "t": "MSFT", "u": "/stock/MSFT", "n": "Microsoft" },
    { "t": "TSLA", "u": "/stock/TSLA", "n": "Tesla" },
    { "t": "NVDA", "u": "/stock/NVDA", "n": "NVIDIA" },
    { "t": "NFLX", "u": "/stock/NFLX", "n": "Netflix" },
    { "t": "PYPL", "u": "/stock/PYPL", "n": "PayPal" },
    { "t": "ADBE", "u": "/stock/ADBE", "n": "Adobe Inc." },
    { "t": "AMD", "u": "/stock/AMD", "n": "AMD" },
    // { "t": "PLTR", "n": "Palantir Technologies Inc." },
    { "t": "BA", "u": "/stock/BA", "n": "The Boeing Company" },
    // { "t": "NKE", "n": "Nike" },
    // { "t": "ULTA", "n": "Ulta Beauty" },
    // { "t": "AMC", "n": "AMC Entertainment Holdings Inc." },
    // { "t": "CRWD", "n": "CrowdStrike Holdings, Inc." },
    // { "t": "AAL", "n": "American Airlines Group Inc." },
    // { "t": "LULU", "n": "Lululemon Athletica Inc." },
    // { "t": "ETN", "n": "Eaton Corporation plc" },
    // { "t": "PEP", "n": "PepsiCo, Inc." },
    // { "t": "ACN", "n": "Accenture plc" },
    // { "t": "ANF", "n": "Abercrombie & Fitch Co." },
    // { "t": "ATGE", "n": "Adtalem Global Education Inc." },
    // { "t": "EWBC", "n": "East West Bancorp, Inc." },
    // { "t": "MOH", "n": "Molina Healthcare, Inc." },
    // { "t": "SMR", "n": "Sumitomo Rubber Industries, Ltd." },
    // { "t": "RDFN", "n": "Redfin Corporation" },
    // { "t": "CELH", "n": "Celsius Holdings, Inc." },
    // { "t": "EME", "n": "EMCOR Group, Inc." },
    // { "t": "CRK", "n": "Comstock Resources, Inc." },
    // { "t": "BX", "n": "The Blackstone Group Inc." },
    // { "t": "TTD", "n": "The Trade Desk, Inc." },
    // { "t": "LLY", "n": "Eli Lilly and Company" },
    // { "t": "NVCR", "n": "NovoCure Limited" },
    // { "t": "AXON", "n": "Axon Enterprise, Inc." },
    // { "t": "SNOW", "n": "Snowflake Inc." },
    // { "t": "SMCI", "n": "Super Micro Computer, Inc." },
    // { "t": "KULR", "n": "KULR Technology Group, Inc." },
    // { "t": "APA", "n": "APA Corporation" },
    // { "t": "MSTR", "n": "MicroStrategy Incorporated" },
    // { "t": "EOSE", "n": "Eos Energy Enterprises, Inc." },
    // { "t": "WLFC", "n": "Willis Lease Finance Corporation" }
  ],

  TOPMETRICS_TICKETS: [
    "DIA",
    "SPY",
    "IWM",
    "TLT",
    "GLD",
    "USO",
    "AAPL",
    "AMZN",
    "GOOG",
    "MSFT",
    "TSLA",
    "NVDA",
    "NFLX",
    "PYPL",
    "ADBE",
    "AMD",
    // "PLTR",
    "BA",
    // "NKE",
    // "ULTA",
    // "AMC",
    // "CRWD",
    // "AAL",
    // "LULU",
    // "ETN",
    // "PEP",
    // "ACN",
    // "ANF",
    // "ATGE",
    // "EWBC",
    // "MOH",
    // "SMR",
    // "RDFN",
    // "CELH",
    // "EME",
    // "CRK",
    // "BX",
    // "TTD",
    // "LLY",
    // "NVCR",
    // "AXON",
    // "SNOW",
    // "SMCI",
    // "KULR",
    // "APA",
    // "MSTR",
    // "EOSE",
    // "WLFC"
  ],

  HEATMAP_COLORS: [
    {
        "type": "PCHG365",
        "min": 50,
        "max": 10000000,
        "color": "#00EE00"
    },
    {
        "type": "PCHG365",
        "min": 30,
        "max": 50,
        "color": "#00BB00"
    },
    {
        "type": "PCHG365",
        "min": 15,
        "max": 30,
        "color": "#009900"
    },
    {
        "type": "PCHG365",
        "min": 7,
        "max": 15,
        "color": "#006600"
    },
    {
        "type": "PCHG365",
        "min": 0,
        "max": 7,
        "color": "#003300"
    },
    {
        "type": "PCHG365",
        "min": -7,
        "max": 0,
        "color": "#330000"
    },
    {
        "type": "PCHG365",
        "min": -15,
        "max": -7,
        "color": "#660000"
    },
    {
        "type": "PCHG365",
        "min": -30,
        "max": -15,
        "color": "#990000"
    },
    {
        "type": "PCHG365",
        "min": -50,
        "max": -30,
        "color": "#BB0000"
    },
    {
        "type": "PCHG365",
        "min": -1000,
        "max": -50,
        "color": "#EE0000"
    },
    {
        "type": "PCHG30",
        "min": 10,
        "max": 10000000,
        "color": "#00EE00"
    },
    {
        "type": "PCHG30",
        "min": 5,
        "max": 10,
        "color": "#00BB00"
    },
    {
        "type": "PCHG30",
        "min": 3,
        "max": 5,
        "color": "#009900"
    },
    {
        "type": "PCHG30",
        "min": 1,
        "max": 3,
        "color": "#006600"
    },
    {
        "type": "PCHG30",
        "min": 0,
        "max": 1,
        "color": "#003300"
    },
    {
        "type": "PCHG30",
        "min": -1,
        "max": 0,
        "color": "#330000"
    },
    {
        "type": "PCHG30",
        "min": -3,
        "max": -1,
        "color": "#660000"
    },
    {
        "type": "PCHG30",
        "min": -5,
        "max": -3,
        "color": "#990000"
    },
    {
        "type": "PCHG30",
        "min": -10,
        "max": -5,
        "color": "#BB0000"
    },
    {
        "type": "PCHG30",
        "min": -1000,
        "max": -10,
        "color": "#EE0000"
    },
    {
        "type": "PCHG7",
        "min": 7,
        "max": 10000000,
        "color": "#00EE00"
    },
    {
        "type": "PCHG7",
        "min": 4,
        "max": 7,
        "color": "#00BB00"
    },
    {
        "type": "PCHG7",
        "min": 2,
        "max": 4,
        "color": "#009900"
    },
    {
        "type": "PCHG7",
        "min": 1,
        "max": 2,
        "color": "#006600"
    },
    {
        "type": "PCHG7",
        "min": 0,
        "max": 1,
        "color": "#003300"
    },
    {
        "type": "PCHG7",
        "min": -1,
        "max": 0,
        "color": "#330000"
    },
    {
        "type": "PCHG7",
        "min": -2,
        "max": -1,
        "color": "#660000"
    },
    {
        "type": "PCHG7",
        "min": -4,
        "max": -2,
        "color": "#990000"
    },
    {
        "type": "PCHG7",
        "min": -7,
        "max": -4,
        "color": "#BB0000"
    },
    {
        "type": "PCHG7",
        "min": -1000,
        "max": -7,
        "color": "#EE0000"
    },
    {
        "type": "PCHG",
        "min": 5,
        "max": 10000000,
        "color": "#00EE00"
    },
    {
        "type": "PCHG",
        "min": 3,
        "max": 5,
        "color": "#00BB00"
    },
    {
        "type": "PCHG",
        "min": 2,
        "max": 3,
        "color": "#009900"
    },
    {
        "type": "PCHG",
        "min": 1,
        "max": 2,
        "color": "#006600"
    },
    {
        "type": "PCHG",
        "min": 0,
        "max": 1,
        "color": "#003300"
    },
    {
        "type": "PCHG",
        "min": -1,
        "max": 0,
        "color": "#330000"
    },
    {
        "type": "PCHG",
        "min": -2,
        "max": -1,
        "color": "#660000"
    },
    {
        "type": "PCHG",
        "min": -3,
        "max": -2,
        "color": "#990000"
    },
    {
        "type": "PCHG",
        "min": -5,
        "max": -3,
        "color": "#BB0000"
    },
    {
        "type": "PCHG",
        "min": -1000,
        "max": -5,
        "color": "#EE0000"
    }
],

// [
  //   { "name": "Ticker", "tooltip": "Ticker", "field": "ticker", "urlField": "ticker", "class": "text-left", sortFunc: (a, b) => a.localeCompare(b) },
  //   { "name": "Name", "tooltip": "Name", "field": "tickername", "class": "td-color", "urlField": "ticker" },
  //   { "label": "Price", "name": "Price", "field": "PRICE", "class": "text-center", "isSectorValue": "true", "tooltip": "Price" },
  //   { "label": "Fundamentals", "name": "Market Cap", "field": "MKTCAP", "class": "sorted", "isSectorValue": "true", "tooltip": "Market Capital" },
  //   { "label": "Fundamentals", "name": "Revenue", "field": "REV_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Net sales" },
  //   { "label": "Fundamentals", "name": "Net Inc", "field": "NETINC_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Net income" },
  //   { "label": "Fundamentals", "name": "Dividends Per Share", "field": "DIV_PS", "class": "text-center", "isSectorValue": "true", "tooltip": "Cash dividends declared per share" },
  //   { "label": "Fundamentals", "name": "Free Cashflow", "field": "FCF", "class": "text-center", "isSectorValue": "true", "tooltip": "Free Cashflow (FCF)" },
  //   { "label": "Fundamentals", "name": "Free Cashflow Per Share, TTM", "field": "FCF_PS_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Free Cashflow Per Share, Trailing 12-Months" },
  //   { "label": "Fundamentals", "name": "Free Cashflow, TTM", "field": "FCF_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Free Cashflow (FCF), Trailing 12-Months" },
  //   { "label": "Fundamentals", "name": "Float", "field": "FLOAT", "class": "text-center", "isSectorValue": "true", "tooltip": "Float" },
  //   { "label": "Fundamentals", "name": "Inst_ Holdings", "field": "INSTPCT", "class": "text-center", "isSectorValue": "true", "tooltip": "Institutional Ownership Percentage*" },
  //   { "label": "Fundamentals", "name": "Grufity Risk Level", "field": "RISKLEVEL__5Y", "class": "text-center", "isSectorValue": "true", "tooltip": "Grufity Risk Level Assessment" },
  //   { "label": "Fundamentals", "name": "Shares Outstanding", "field": "SHR_OUTS", "class": "text-center", "isSectorValue": "true", "tooltip": "Entity Common Stock, Shares Outstanding" },
  //   { "label": "Fundamentals", "name": "Shares Based Compensation", "field": "SHRCOMP", "class": "text-center", "isSectorValue": "true", "tooltip": "Shares Based Compensation*" },
  //   { "label": "Returns", "name": "Price %, 1W", "field": "PCHG__7", "class": "text-center", "isSectorValue": "true", "isPct": "true", "mult": "100", "tooltip": "1-Week Stock Price Percent Change" },
  //   { "label": "Returns", "name": "Price %, 1M", "field": "PCHG__30", "class": "text-center", "isSectorValue": "true", "isPct": "true", "mult": "100", "tooltip": "1-Month Stock Price Percent Change" },
  //   { "label": "Returns", "name": "Price %, 6M", "field": "PCHG__180", "class": "text-center", "isSectorValue": "true", "isPct": "true", "mult": "100", "tooltip": "6-Months Stock Price Percent Change" },
  //   { "label": "Returns", "name": "Price %, 1Y", "field": "PCHG__365", "class": "text-center", "isSectorValue": "true", "isPct": "true", "mult": "100", "dd": "PCHG", "tooltip": "Return, 1-Year" },
  //   { "label": "Returns", "name": "Returns, 3Y", "field": "RETURNS__1095", "class": "text-center", "isSectorValue": "true", "isPct": "true", "tooltip": "3-yr Rolling Returns" },
  //   { "label": "Returns", "name": "2-Mth , % Change", "field": "PCHG__60", "class": "text-center", "isSectorValue": "true", "isPct": "true", "tooltip": "2 Months Stock Price Percent Change" },
  //   { "label": "Returns", "name": "3-Mth , % Change", "field": "PCHG__90", "class": "text-center", "isSectorValue": "true", "isPct": "true", "tooltip": "3 Months Stock Price Percent Change" },
  //   { "label": "Returns", "name": "Monthly % Change", "field": "PCHG__M", "class": "text-center", "isSectorValue": "true", "isPct": "true", "tooltip": "Monthly Percent Change" },
  //   { "label": "Returns", "name": "Quarterly % Change", "field": "PCHG__Q", "class": "text-center", "isSectorValue": "true", "isPct": "true", "tooltip": "Quarterly Percent Change" },
  //   { "label": "Returns", "name": "Weekly % Change", "field": "PCHG__W", "class": "text-center", "isSectorValue": "true", "isPct": "true", "tooltip": "Weekly Percent Change" },
  //   { "label": "Returns", "name": "Yearly Return", "field": "PCHG__Y", "class": "text-center", "isSectorValue": "true", "isPct": "true", "tooltip": "Yearly Stock Return" },
  //   { "label": "Returns", "name": "5-yr Rolling Returns", "field": "RETURNS__1825", "class": "text-center", "isSectorValue": "true", "isPct": "true", "tooltip": "5-yr Rolling Returns" },
  //   { "label": "Returns", "name": "7-yr Rolling Returns", "field": "RETURNS__2555", "class": "text-center", "isSectorValue": "true", "isPct": "true", "tooltip": "7-yr Rolling Returns" },
  //   { "label": "Returns", "name": "1-yr Rolling Returns", "field": "RETURNS__365", "class": "text-center", "isSectorValue": "true", "isPct": "true", "tooltip": "1-yr Rolling Returns" },
  //   { "label": "Returns", "name": "10-yr Rolling Returns", "field": "RETURNS__3650", "class": "text-center", "isSectorValue": "true", "isPct": "true", "tooltip": "10-yr Rolling Returns" },
  //   { "label": "Valuation", "name": "EBT", "field": "EBT", "class": "text-center", "isSectorValue": "true", "tooltip": "Earnings Before Tax Ratio" },
  //   { "label": "Valuation", "name": "P/E", "field": "PE", "class": "text-center", "isSectorValue": "true", "tooltip": "Price to Earnings Ratio" },
  //   { "label": "Valuation", "name": "P/S", "field": "PS", "class": "text-center", "isSectorValue": "true", "tooltip": "Price to Sales Ratio" },
  //   { "label": "Valuation", "name": "Assets/Sales", "field": "ASS_SAL", "class": "text-center", "isSectorValue": "true", "tooltip": "Assets-to-Sales Ratio" },
  //   { "label": "Valuation", "name": "Debt/Cashflow", "field": "DEBT_CFOPER", "class": "text-center", "isSectorValue": "true", "tooltip": "Debt Cashflow Coverage" },
  //   { "label": "Valuation", "name": "Debt/Equity", "field": "DEBT_EQ", "class": "text-center", "isSectorValue": "true", "tooltip": "Debt To Equity Ratio" },
  //   { "label": "Valuation", "name": "Debt/Assets", "field": "DEBT_RAT", "class": "text-center", "isSectorValue": "true", "tooltip": "Debt to Assets Ratio" },
  //   { "label": "Valuation", "name": "Dividends Per Share (TTM)", "field": "DIV_PS_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Cash dividends declared per share (Trailing 12-Months)" },
  //   { "label": "Valuation", "name": "Dividend Yld", "field": "DIV_YIELD", "class": "text-center", "isSectorValue": "true", "tooltip": "Dividend Yield" },
  //   { "label": "Valuation", "name": "EV", "field": "EV", "class": "text-center", "isSectorValue": "true", "tooltip": "Enterprise Value" },
  //   { "label": "Valuation", "name": "EV/EBITDA", "field": "EV_EBITDA", "class": "text-center", "isSectorValue": "true", "tooltip": "EV to EBITDA Ratio" },
  //   { "label": "Valuation", "name": "FCF Yield", "field": "FCF_YIELD", "class": "text-center", "isSectorValue": "true", "tooltip": "Free Cashflow Yield" },
  //   { "label": "Valuation", "name": "Long-term Capital Ratio", "field": "LTCAP", "class": "text-center", "isSectorValue": "true", "tooltip": "Long-term Capital Ratio" },
  //   { "label": "Valuation", "name": "MktCap/EBT", "field": "MKTEBT", "class": "text-center", "isSectorValue": "true", "tooltip": "Market Capital to Earnings Before Tax Ratio" },
  //   { "label": "Valuation", "name": "Price/Cashflow", "field": "PCF", "class": "text-center", "isSectorValue": "true", "tooltip": "Price to Cashflow Ratio" },
  //   { "label": "Valuation", "name": "Price/FCF", "field": "PFCF", "class": "text-center", "isSectorValue": "true", "tooltip": "Price to Free Cash Flow (P/FCF)" },
  //   { "label": "Valuation", "name": "Return on Assets", "field": "ROA", "class": "text-center", "isSectorValue": "true", "tooltip": "Return on Assets" },
  //   { "label": "Growth", "name": "ROE (%)", "field": "ROE", "class": "text-center", "isSectorValue": "true", "isPct": "true", "tooltip": "Return on Equity" },
  //   { "label": "Growth", "name": "Rev 1-Yr", "field": "REV_TTM__PCHG_1Y", "class": "text-center", "isSectorValue": "true", "tooltip": "1-Year Revenue Growth" },
  //   { "label": "Growth", "name": "Inc 1-Yr", "field": "NETINC_TTM__PCHG_1Y", "class": "text-center", "isSectorValue": "true", "tooltip": "Net Income, 1-Year Percent Change" },
  //   { "label": "Growth", "name": "Net Income, TTM, Chg", "field": "NETINC_TTM__CHG", "class": "text-center", "isSectorValue": "true", "tooltip": "Change in Net-Income over the trailing twelve months (TTM)*" },
  //   { "label": "Growth", "name": "Net Income, TTM, 1-Year Chg", "field": "NETINC_TTM__CHG_1Y", "class": "text-center", "isSectorValue": "true", "tooltip": "Net Income, Trailing Twelve Months, 1-Year Change." },
  //   { "label": "Growth", "name": "Net Income, TTM, % Chg", "field": "NETINC_TTM__PCHG", "class": "text-center", "isSectorValue": "true", "isPct": "true", "tooltip": "Net Income, Trailing Twelve Months, Percentage Change." },
  //   { "label": "Growth", "name": "Net Income, Chg", "field": "NETINC__CHG", "class": "text-center", "isSectorValue": "true", "tooltip": "Net Income, Change" },
  //   { "label": "Growth", "name": "Net Income, 1-Year Chg", "field": "NETINC__CHG_1Y", "class": "text-center", "isSectorValue": "true", "tooltip": "Net Income, 1 Year Change" },
  //   { "label": "Growth", "name": "Net Income, % Chg", "field": "NETINC__PCHG", "class": "text-center", "isSectorValue": "true", "isPct": "true", "tooltip": "Net Income, Percent Change" },
  //   { "label": "Growth", "name": "Net Income, 1-Year % Chg", "field": "NETINC__PCHG_1Y", "class": "text-center", "isSectorValue": "true", "isPct": "true", "tooltip": "Net Income, 1-Year Percent Change" },
  //   { "label": "Growth", "name": "Revenues, TTM, Chg", "field": "REV_TTM__CHG", "class": "text-center", "isSectorValue": "true", "tooltip": "Revenues, Trailing Twelve Months, Change" },
  //   { "label": "Growth", "name": "Revenues, TTM, 1-Year Chg", "field": "REV_TTM__CHG_1Y", "class": "text-center", "isSectorValue": "true", "tooltip": "Revenues, Trailing Twelve Months, 1-Year Change" },
  //   { "label": "Growth", "name": "Revenues, TTM, % Chg", "field": "REV_TTM__PCHG", "class": "text-center", "isSectorValue": "true", "isPct": "true", "tooltip": "Revenues, Trailing Twelve Months, Percentage Change" },
  //   { "label": "Growth", "name": "Revenues, Chg", "field": "REV__CHG", "class": "text-center", "isSectorValue": "true", "tooltip": "Net sales, Change" },
  //   { "label": "Growth", "name": "Revenues, 1-Year Chg", "field": "REV__CHG_1Y", "class": "text-center", "isSectorValue": "true", "tooltip": "Net sales, 1 Year Change" },
  //   { "label": "Growth", "name": "Revenues, % Chg", "field": "REV__PCHG", "class": "text-center", "isSectorValue": "true", "isPct": "true", "tooltip": "Net sales, Percent Change" },
  //   { "label": "Growth", "name": "Revenues, 1-Year % Chg", "field": "REV__PCHG_1Y", "class": "text-center", "isSectorValue": "true", "isPct": "true", "tooltip": "Net sales, 1-Year Percent Change" },
  //   { "label": "Income Statement", "name": "Cost of Goods Sold", "field": "COGS", "class": "text-center", "isSectorValue": "true", "tooltip": "Cost of Goods Sold*" },
  //   { "label": "Income Statement", "name": "Comprehensive Income", "field": "COMPINC", "class": "text-center", "isSectorValue": "true", "tooltip": "Total Comprehensive Income" },
  //   { "label": "Income Statement", "name": "Comprehensive Income (TTM)", "field": "COMPINC_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Total Comprehensive Income" },
  //   { "label": "Income Statement", "name": "Cost of Revenue", "field": "COSTREV", "class": "text-center", "isSectorValue": "true", "tooltip": "Cost of Revenue*" },
  //   { "label": "Income Statement", "name": "Costs", "field": "COSTS", "class": "text-center", "isSectorValue": "true", "tooltip": "Costs" },
  //   { "label": "Income Statement", "name": "EBIT (TTM)", "field": "EBIT_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Earnings Before Taxes, Interest, Depreciation and Amortization (EBITDA)" },
  //   { "label": "Income Statement", "name": "EBITDA Margin", "field": "EBITDA_MGN", "class": "text-center", "isSectorValue": "true", "tooltip": "Earnings Before Taxes, Interest, Depreciation and Amortization Margin (EBITDA Margin)" },
  //   { "label": "Income Statement", "name": "EBITDA (TTM)", "field": "EBITDA_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Earnings Before Taxes, Interest, Depreciation and Amortization (EBITDA)" },
  //   { "label": "Income Statement", "name": "EBT Margin", "field": "EBT_MGN", "class": "text-center", "isSectorValue": "true", "tooltip": "Earnings Before Tax Margin" },
  //   { "label": "Income Statement", "name": "Earnings Before Tax (TTM)", "field": "EBT_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Income before provision for income taxes" },
  //   { "label": "Income Statement", "name": "EPS, Basic", "field": "EPS", "class": "text-center", "isSectorValue": "true", "tooltip": "Earnings Per Share, Basic" },
  //   { "label": "Income Statement", "name": "EPS, Diluted", "field": "EPS_DIL", "class": "text-center", "isSectorValue": "true", "tooltip": "Earnings Per Share, Diluted" },
  //   { "label": "Income Statement", "name": "EPS, Diluted (TTM)", "field": "EPS_DIL_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Earnings Per Share, Diluted (Trailing 12-Months)" },
  //   { "label": "Income Statement", "name": "EPS, Basic (TTM)", "field": "EPS_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Earnings Per Share, Basic (Trailing 12-Months)" },
  //   { "label": "Income Statement", "name": "Advertising Expense", "field": "EXP_ADV", "class": "text-center", "isSectorValue": "true", "tooltip": "Advertising expense" },
  //   { "label": "Income Statement", "name": "Advertising Expense (TTM)", "field": "EXP_ADV_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Advertising expense (Trailing 12-Months)" },
  //   { "label": "Income Statement", "name": "Interest Expense", "field": "EXP_INT", "class": "text-center", "isSectorValue": "true", "tooltip": "Interest expense" },
  //   { "label": "Income Statement", "name": "Interest Expense (TTM)", "field": "EXP_INT_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Interest expense (Trailing 12-Months)" },
  //   { "label": "Income Statement", "name": "Share-based Compensation Expense", "field": "EXP_SHCOMP", "class": "text-center", "isSectorValue": "true", "tooltip": "Share-based Compensation Expense*" },
  //   { "label": "Income Statement", "name": "Income Tax Rate", "field": "ITXR", "class": "text-center", "isSectorValue": "true", "tooltip": "Income Tax Rate*" },
  //   { "label": "Income Statement", "name": "Net Income", "field": "NETINC", "class": "text-center", "isSectorValue": "true", "tooltip": "Net income" },
  //   { "label": "Income Statement", "name": "Net Income Margin (TTM)", "field": "NETINC_MGN", "class": "text-center", "isSectorValue": "true", "tooltip": "Net Earnings Margin (Trailing 12-Months)" },
  //   { "label": "Income Statement", "name": "Operating Margin", "field": "OPER_MGN", "class": "text-center", "isSectorValue": "true", "tooltip": "Operating Margin" },
  //   { "label": "Income Statement", "name": "Operating Expense", "field": "OPEX", "class": "text-center", "isSectorValue": "true", "tooltip": "Total operating expenses" },
  //   { "label": "Income Statement", "name": "G&A Expense", "field": "OPEX_GA", "class": "text-center", "isSectorValue": "true", "tooltip": "General and Administrative Expense" },
  //   { "label": "Income Statement", "name": "R&D, Expense", "field": "OPEX_RND", "class": "text-center", "isSectorValue": "true", "tooltip": "Research and development" },
  //   { "label": "Income Statement", "name": "R&D, Expense (TTM)", "field": "OPEX_RND_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Research and development" },
  //   { "label": "Income Statement", "name": "SG&A, Expense", "field": "OPEX_SGA", "class": "text-center", "isSectorValue": "true", "tooltip": "Selling, general and administrative" },
  //   { "label": "Income Statement", "name": "SG&A, Expense (TTM)", "field": "OPEX_SGA_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Selling, general and administrative" },
  //   { "label": "Income Statement", "name": "Operating Expense (TTM)", "field": "OPEX_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Total operating expenses" },
  //   { "label": "Income Statement", "name": "Gross Profit", "field": "PROFIT_GROSS", "class": "text-center", "isSectorValue": "true", "tooltip": "Gross margin" },
  //   { "label": "Income Statement", "name": "Gross Profit (TTM)", "field": "PROFIT_GROSS_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Gross margin" },
  //   { "label": "Income Statement", "name": "Operating Profit", "field": "PROFIT_OPER", "class": "text-center", "isSectorValue": "true", "tooltip": "Operating income" },
  //   { "label": "Income Statement", "name": "Operating Profit (TTM)", "field": "PROFIT_OPER_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Operating income (TTM)" },
  //   { "label": "Income Statement", "name": "Revenues", "field": "REV", "class": "text-center", "isSectorValue": "true", "tooltip": "Net sales" },
  //   { "label": "Balance Sheet", "name": "Add'l Paid-in Cap", "tooltip": "Additional Paid-in Capital", "field": "ADDPAIDCAP", "class": "text-center", "isSectorValue": "true" },
  //   { "label": "Balance Sheet", "name": "Avail. For Sale Sec.", "tooltip": "Available For Sale Securities", "field": "AFS", "class": "text-center", "isSectorValue": "true" },
  //   { "label": "Balance Sheet", "name": "Assets", "field": "ASSETS", "class": "text-center", "isSectorValue": "true", "tooltip": "Total Assets" },
  //   { "label": "Balance Sheet", "name": "Assets, Current", "field": "ASSETS_CUR", "class": "text-center", "isSectorValue": "true", "tooltip": "Total Current Assets" },
  //   { "label": "Balance Sheet", "name": "Assets, Chg", "field": "ASSETS__CHG", "class": "text-center", "isSectorValue": "true", "tooltip": "Total Assets Change" },
  //   { "label": "Balance Sheet", "name": "Assets, 1-Year % Chg", "field": "ASSETS__PCHG_1Y", "class": "text-center", "isSectorValue": "true", "isPct": "true", "tooltip": "Percentage change in total assets over a one-year period" },
  //   { "label": "Balance Sheet", "name": "Cash equivalents", "field": "CASHEQ", "class": "text-center", "isSectorValue": "true", "tooltip": "Cash and Cash Equivalents" },
  //   { "label": "Balance Sheet", "name": "Contigencies", "field": "CNTG", "class": "text-center", "isSectorValue": "true", "tooltip": "Contingencies" },
  //   { "label": "Balance Sheet", "name": "Long Term Debt", "field": "DEBT_LT", "class": "text-center", "isSectorValue": "true", "tooltip": "Long Term Debt" },
  //   { "label": "Balance Sheet", "name": "Goodwill", "field": "GWILL", "class": "text-center", "isSectorValue": "true", "tooltip": "Goodwill" },
  //   { "label": "Balance Sheet", "name": "Impairment of Assets", "field": "IMP_ASSETS", "class": "text-center", "isSectorValue": "true", "tooltip": "Impairment of Assets*" },
  //   { "label": "Balance Sheet", "name": "Goodwill Impairment", "field": "IMP_GWILL", "class": "text-center", "isSectorValue": "true", "tooltip": "Goodwill impairment charges" },
  //   { "label": "Balance Sheet", "name": "Goodwill Impairment (TTM)", "field": "IMP_GWILL_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Goodwill impairment charges (TTM)" },
  //   { "label": "Balance Sheet", "name": "Inventory Writedown", "field": "INVWD", "class": "text-center", "isSectorValue": "true", "tooltip": "Inventory Writedown*" },
  //   { "label": "Balance Sheet", "name": "Inventory, Net", "field": "INVY", "class": "text-center", "isSectorValue": "true", "tooltip": "Inventories" },
  //   { "label": "Balance Sheet", "name": "Inventory, Change", "field": "INVY_CHG", "class": "text-center", "isSectorValue": "true", "tooltip": "Change in Inventories" },
  //   { "label": "Balance Sheet", "name": "Inventory, Change (TTM)", "field": "INVY_CHG_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Change in Inventories" },
  //   { "label": "Balance Sheet", "name": "Liabilities", "field": "LIABS", "class": "text-center", "isSectorValue": "true", "tooltip": "Total liabilities" },
  //   { "label": "Balance Sheet", "name": "Liabilities, Current", "field": "LIABS_CUR", "class": "text-center", "isSectorValue": "true", "tooltip": "Total current liabilities" },
  //   { "label": "Balance Sheet", "name": "Stock Eq and Liab.", "field": "LIABSEQ", "class": "text-center", "isSectorValue": "true", "tooltip": "Total liabilities and shareholders equity" },
  //   { "label": "Balance Sheet", "name": "Line of Credit", "field": "LOC", "class": "text-center", "isSectorValue": "true", "tooltip": "Line of Credit*" },
  //   { "label": "Balance Sheet", "name": "Minority Interest", "field": "MININT", "class": "text-center", "isSectorValue": "true", "tooltip": "Minority Interest*" },
  //   { "label": "Balance Sheet", "name": "Net PPE", "field": "NETPPE", "class": "text-center", "isSectorValue": "true", "tooltip": "Net Property, Plant, and Equipment" },
  //   { "label": "Balance Sheet", "name": "PPE, Gross", "field": "PPE_GROSS", "class": "text-center", "isSectorValue": "true", "tooltip": "Gross property, plant and equipment" },
  //   { "label": "Balance Sheet", "name": "PPE, Net", "field": "PPE_NET", "class": "text-center", "isSectorValue": "true", "tooltip": "Property, plant and equipment, net" },
  //   { "label": "Balance Sheet", "name": "Stockholders Equity", "field": "SEQ", "class": "text-center", "isSectorValue": "true", "tooltip": "Total shareholders equity" },
  //   { "label": "Balance Sheet", "name": "Shares Based Compensation (TTM)", "field": "SHRCOMP_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Shares Based Compensation (TTM)*" },
  //   { "label": "Cashflow Statement", "name": "Change in Cash Eq.", "field": "CASHEQ_CHG", "class": "text-center", "isSectorValue": "true", "tooltip": "Increase/(Decrease) in cash and cash equivalents" },
  //   { "label": "Cashflow Statement", "name": "Change in Cash Eq. (TTM)", "field": "CASHEQ_CHG_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Increase/(Decrease) in cash and cash equivalents" },
  //   { "label": "Cashflow Statement", "name": "Cash From Finance", "field": "CFFIN", "class": "text-center", "isSectorValue": "true", "tooltip": "Cash used in financing activities" },
  //   { "label": "Cashflow Statement", "name": "Cash From Finance (TTM)", "field": "CFFIN_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Cash used in financing activities" },
  //   { "label": "Cashflow Statement", "name": "Cash From Investments", "field": "CFINV", "class": "text-center", "isSectorValue": "true", "tooltip": "Cash generated by/(used in) investing activities" },
  //   { "label": "Cashflow Statement", "name": "Cash From Investments (TTM)", "field": "CFINV_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Cash generated by/(used in) investing activities" },
  //   { "label": "Cashflow Statement", "name": "Cash from Operations", "field": "CFOPER", "class": "text-center", "isSectorValue": "true", "tooltip": "Cash generated by operating activities" },
  //   { "label": "Cashflow Statement", "name": "Cash from Operations (TTM)", "field": "CFOPER_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Cash generated by operating activities" },
  //   { "label": "Cashflow Statement", "name": "Depreciation", "field": "DEPAMOR", "class": "text-center", "isSectorValue": "true", "tooltip": "Depreciation and amortization" },
  //   { "label": "Cashflow Statement", "name": "Depreciation (TTM)", "field": "DEPAMOR_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Depreciation and amortization (Trailing 12-Months)" },
  //   { "label": "Cashflow Statement", "name": "Payments for Business Acquisition", "field": "PMT_ACQ_BUS", "class": "text-center", "isSectorValue": "true", "tooltip": "Payments for Business Acquisition*" },
  //   { "label": "Cashflow Statement", "name": "Payments for Inventory Acquisition", "field": "PMT_ACQ_INV", "class": "text-center", "isSectorValue": "true", "tooltip": "Payments for Inventory Acquisition*" },
  //   { "label": "Cashflow Statement", "name": "Payments for Others Acquisitions", "field": "PMT_ACQ_OTR", "class": "text-center", "isSectorValue": "true", "tooltip": "Payments for Others Acquisitions*" },
  //   { "label": "Cashflow Statement", "name": "Dividends", "field": "PMT_DIV", "class": "text-center", "isSectorValue": "true", "tooltip": "Dividends" },
  //   { "label": "Cashflow Statement", "name": "Dividends", "field": "PMT_DIV_CS", "class": "text-center", "isSectorValue": "true", "tooltip": "Dividend Payments" },
  //   { "label": "Cashflow Statement", "name": "Dividends Common Shares (TTM)", "field": "PMT_DIV_CS_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Dividends Paid on Common Shares Over the Trailing Twelve Months" },
  //   { "label": "Cashflow Statement", "name": "Dividends (TTM)", "field": "PMT_DIV_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Dividends Paid Over the Trailing Twelve Months" },
  //   { "label": "Cashflow Statement", "name": "Stock Buy Backs", "field": "PMT_REP_SHR", "class": "text-center", "isSectorValue": "true", "tooltip": "Repurchases of common stock" },
  //   { "label": "Cashflow Statement", "name": "Stock Buy Backs (TTM)", "field": "PMT_REP_SHR_TTM", "class": "text-center", "isSectorValue": "true", "tooltip": "Repurchases of common stock" },
  //   { "label": "Cashflow Statement", "name": "Retained Earnings", "field": "RETEARN", "class": "text-center", "isSectorValue": "true", "tooltip": "Retained earnings" },
  //   { "label": "Cashflow Statement", "name": "Repayment of Long-term Date", "field": "RPY_LTD", "class": "text-center", "isSectorValue": "true", "tooltip": "Repayment of Long-term Date" },
  //   { "label": "Statistics", "name": "Drawdowns", "field": "DRAWDN", "class": "text-center", "isSectorValue": "true", "tooltip": "DrawDown" },
  //   { "label": "Statistics", "name": "Probability of 30% Drawdown", "field": "DRAWDN_PROB30__5Y", "class": "text-center", "isSectorValue": "true", "isPct": "true", "tooltip": "Probability of 30% Drawdown  in 1-year holding period" },
  //   { "label": "Statistics", "name": "Probability of 40% Drawdown", "field": "DRAWDN_PROB40__5Y", "class": "text-center", "isSectorValue": "true", "isPct": "true", "tooltip": "Probability of 40% Drawdown in 1-year holding period" },
  //   { "label": "Statistics", "name": "Yearly Volatility", "field": "VOL__Y", "class": "text-center", "isSectorValue": "true", "tooltip": "Yearly Volatility" },
  //   { "label": "Statistics", "name": "Watermarks", "field": "WATERMARK", "class": "text-center", "isSectorValue": "true", "tooltip": "Rolling Watermark" },
  //   { "label": "Operations", "name": "Current Ratio", "field": "CR", "class": "text-center", "isSectorValue": "true", "tooltip": "Current Ratio" },
  //   { "label": "Operations", "name": "Interest/Cashflow", "field": "INT_CFOPER", "class": "text-center", "isSectorValue": "true", "tooltip": "Interest to Cashflow Ratio" },
  //   { "label": "Operations", "name": "Interest Coverage", "field": "INT_COV", "class": "text-center", "isSectorValue": "true", "tooltip": "Interest Coverage Ratio" },
  //   { "label": "Operations", "name": "Collection Period", "field": "PER_AVGCOLL", "class": "text-center", "isSectorValue": "true", "tooltip": "Average Collection Period" },
  //   { "label": "Operations", "name": "Avg. Inventory Period", "field": "PER_AVGINVY", "class": "text-center", "isSectorValue": "true", "tooltip": "Average Inventory Period*" },
  //   { "label": "Operations", "name": "Sales/Inventory", "field": "SAL_INV", "class": "text-center", "isSectorValue": "true", "tooltip": "Sales to Investory Ratio" },
  //   { "label": "Operations", "name": "Revenues/Assets", "field": "TO_ASSET", "class": "text-center", "isSectorValue": "true", "tooltip": "Assets Turnover Ratio" },
  //   { "label": "Operations", "name": "Inventory Turnover", "field": "TO_INV", "class": "text-center", "isSectorValue": "true", "tooltip": "Inventory Turnover*" },
  //   { "label": "Operations", "name": "Payables Turnover", "field": "TO_PAYB", "class": "text-center", "isSectorValue": "true", "tooltip": "Payables Turnover*" },
  //   { "label": "Operations", "name": "Revenues/Receivables", "field": "TO_REC", "class": "text-center", "isSectorValue": "true", "tooltip": "Receivables Turnover Ratio" },
  // ],
  // CUST: { "id": "cus_MdT9bXutIqZFJS", "object": "customer", "address": { "city": "dev", "country": "US", "line1": "dev", "line2": null, "postal_code": "42424", "state": "dev" }, "balance": 0, "created": 1666085734, "currency": null, "default_currency": null, "default_source": null, "delinquent": false, "description": null, "discount": null, "email": "gimocah686@finaltest.xxx", "invoice_prefix": "34AA8274", "invoice_settings": { "custom_fields": null, "default_payment_method": "pm_1LuCCJCdnFB4AZOROPfWTDTs", "footer": null, "rendering_options": null }, "livemode": false, "metadata": {}, "name": "devtest", "next_invoice_sequence": 1, "phone": "555-555-5555", "preferred_locales": [], "shipping": null, "sources": { "object": "list", "data": [], "has_more": false, "total_count": 0, "url": "/v1/customers/cus_MdT9bXutIqZFJS/sources" }, "subscriptions": { "object": "list", "data": [], "has_more": false, "total_count": 0, "url": "/v1/customers/cus_MdT9bXutIqZFJS/subscriptions" }, "tax_exempt": "none", "tax_ids": { "object": "list", "data": [], "has_more": false, "total_count": 0, "url": "/v1/customers/cus_MdT9bXutIqZFJS/tax_ids" }, "tax_info": null, "tax_info_verification": null, "test_clock": null },
  // SUBS: {
  //   "id": "sub_1LuCCxCdnFB4AZORs2yBBhMB",
  //   "object": "subscription",
  //   "application": null,
  //   "application_fee_percent": null,
  //   "automatic_tax": {
  //     "enabled": false
  //   },
  //   "billing_cycle_anchor": 1666085735,
  //   "billing_thresholds": null,
  //   "cancel_at": null,
  //   "cancel_at_period_end": false,
  //   "canceled_at": null,
  //   "collection_method": "charge_automatically",
  //   "created": 1666085735,
  //   "currency": "inr",
  //   "current_period_end": 1668764135,
  //   "current_period_start": 1666085735,
  //   "customer": "cus_MdT9bXutIqZFJS",
  //   "days_until_due": null,
  //   "default_payment_method": null,
  //   "default_source": null,
  //   "default_tax_rates": [],
  //   "description": null,
  //   "discount": null,
  //   "ended_at": null,
  //   "invoice_customer_balance_settings": {
  //     "consume_applied_balance_on_void": true
  //   },
  //   "items": {
  //     "object": "list",
  //     "data": [
  //       {
  //         "id": "si_MdT9dOER5tE4Bu",
  //         "object": "subscription_item",
  //         "billing_thresholds": null,
  //         "created": 1666085736,
  //         "metadata": {},
  //         "plan": {
  //           "id": "price_1LsiQdCdnFB4AZORryoRdkxv",
  //           "object": "plan",
  //           "active": true,
  //           "aggregate_usage": null,
  //           "amount": 80000,
  //           "amount_decimal": "80000",
  //           "billing_scheme": "per_unit",
  //           "created": 1665732935,
  //           "currency": "inr",
  //           "interval": "month",
  //           "interval_count": 1,
  //           "livemode": false,
  //           "metadata": {},
  //           "nickname": "Monthly 800INR 10USD 10GBP 10EUR",
  //           "product": "prod_MbwJSX86fK2ydt",
  //           "tiers": null,
  //           "tiers_mode": null,
  //           "transform_usage": null,
  //           "trial_period_days": null,
  //           "usage_type": "licensed"
  //         },
  //         "price": {
  //           "id": "price_1LsiQdCdnFB4AZORryoRdkxv",
  //           "object": "price",
  //           "active": true,
  //           "billing_scheme": "per_unit",
  //           "created": 1665732935,
  //           "currency": "inr",
  //           "custom_unit_amount": null,
  //           "livemode": false,
  //           "lookup_key": null,
  //           "metadata": {},
  //           "nickname": "Monthly 800INR 10USD 10GBP 10EUR",
  //           "product": "prod_MbwJSX86fK2ydt",
  //           "recurring": {
  //             "aggregate_usage": null,
  //             "interval": "month",
  //             "interval_count": 1,
  //             "trial_period_days": null,
  //             "usage_type": "licensed"
  //           },
  //           "tax_behavior": "unspecified",
  //           "tiers_mode": null,
  //           "transform_quantity": null,
  //           "type": "recurring",
  //           "unit_amount": 80000,
  //           "unit_amount_decimal": "80000"
  //         },
  //         "quantity": 1,
  //         "subscription": "sub_1LuCCxCdnFB4AZORs2yBBhMB",
  //         "tax_rates": []
  //       }
  //     ],
  //     "has_more": false,
  //     "total_count": 1,
  //     "url": "/v1/subscription_items?subscription=sub_1LuCCxCdnFB4AZORs2yBBhMB"
  //   },
  //   "latest_invoice": {
  //     "id": "in_1LuCCxCdnFB4AZORez2sv8Db",
  //     "object": "invoice",
  //     "account_country": "IN",
  //     "account_name": "Knowtilus Technologies Private Limited",
  //     "account_tax_ids": null,
  //     "amount_due": 80000,
  //     "amount_paid": 80000,
  //     "amount_remaining": 0,
  //     "application": null,
  //     "application_fee_amount": null,
  //     "attempt_count": 1,
  //     "attempted": true,
  //     "auto_advance": false,
  //     "automatic_tax": {
  //       "enabled": false,
  //       "status": null
  //     },
  //     "billing_reason": "subscription_create",
  //     "charge": "ch_1LuCCyCdnFB4AZORhDrb0ceH",
  //     "collection_method": "charge_automatically",
  //     "created": 1666085735,
  //     "currency": "inr",
  //     "custom_fields": null,
  //     "customer": "cus_MdT9bXutIqZFJS",
  //     "customer_address": {
  //       "city": "dev",
  //       "country": "US",
  //       "line1": "dev",
  //       "line2": null,
  //       "postal_code": "42424",
  //       "state": "dev"
  //     },
  //     "customer_email": "gimocah686@finaltest.xxx",
  //     "customer_name": "devtest",
  //     "customer_phone": "555-555-5555",
  //     "customer_shipping": null,
  //     "customer_tax_exempt": "none",
  //     "customer_tax_ids": [],
  //     "default_payment_method": null,
  //     "default_source": null,
  //     "default_tax_rates": [],
  //     "description": "Thank you for choosing Grufity!",
  //     "discount": null,
  //     "discounts": [],
  //     "due_date": null,
  //     "ending_balance": 0,
  //     "footer": "If you have any questions, contact Grufity at contact@grufity.com.",
  //     "from_invoice": null,
  //     "hosted_invoice_url": "https://invoice.stripe.com/i/acct_1FbQ93CdnFB4AZOR/test_YWNjdF8xRmJROTNDZG5GQjRBWk9SLF9NZFQ5Y1ZDZlpnaklDTWN6S3ZJb3R1Q2xVYUl0ejFpLDU2NjI2NTM40200Q3R4b9Zz?s=ap",
  //     "invoice_pdf": "https://pay.stripe.com/invoice/acct_1FbQ93CdnFB4AZOR/test_YWNjdF8xRmJROTNDZG5GQjRBWk9SLF9NZFQ5Y1ZDZlpnaklDTWN6S3ZJb3R1Q2xVYUl0ejFpLDU2NjI2NTM40200Q3R4b9Zz/pdf?s=ap",
  //     "last_finalization_error": null,
  //     "latest_revision": null,
  //     "lines": {
  //       "object": "list",
  //       "data": [
  //         {
  //           "id": "sli_162cf2CdnFB4AZORadeb1cf1",
  //           "object": "line_item",
  //           "amount": 80000,
  //           "amount_excluding_tax": 80000,
  //           "currency": "inr",
  //           "description": "1 × Premium_v2 (at ₹800.00 / month)",
  //           "discount_amounts": [],
  //           "discountable": true,
  //           "discounts": [],
  //           "livemode": false,
  //           "metadata": {},
  //           "period": {
  //             "end": 1668764135,
  //             "start": 1666085735
  //           },
  //           "plan": {
  //             "id": "price_1LsiQdCdnFB4AZORryoRdkxv",
  //             "object": "plan",
  //             "active": true,
  //             "aggregate_usage": null,
  //             "amount": 80000,
  //             "amount_decimal": "80000",
  //             "billing_scheme": "per_unit",
  //             "created": 1665732935,
  //             "currency": "inr",
  //             "interval": "month",
  //             "interval_count": 1,
  //             "livemode": false,
  //             "metadata": {},
  //             "nickname": "Monthly 800INR 10USD 10GBP 10EUR",
  //             "product": "prod_MbwJSX86fK2ydt",
  //             "tiers": null,
  //             "tiers_mode": null,
  //             "transform_usage": null,
  //             "trial_period_days": null,
  //             "usage_type": "licensed"
  //           },
  //           "price": {
  //             "id": "price_1LsiQdCdnFB4AZORryoRdkxv",
  //             "object": "price",
  //             "active": true,
  //             "billing_scheme": "per_unit",
  //             "created": 1665732935,
  //             "currency": "inr",
  //             "custom_unit_amount": null,
  //             "livemode": false,
  //             "lookup_key": null,
  //             "metadata": {},
  //             "nickname": "Monthly 800INR 10USD 10GBP 10EUR",
  //             "product": "prod_MbwJSX86fK2ydt",
  //             "recurring": {
  //               "aggregate_usage": null,
  //               "interval": "month",
  //               "interval_count": 1,
  //               "trial_period_days": null,
  //               "usage_type": "licensed"
  //             },
  //             "tax_behavior": "unspecified",
  //             "tiers_mode": null,
  //             "transform_quantity": null,
  //             "type": "recurring",
  //             "unit_amount": 80000,
  //             "unit_amount_decimal": "80000"
  //           },
  //           "proration": false,
  //           "proration_details": {
  //             "credited_items": null
  //           },
  //           "quantity": 1,
  //           "subscription": "sub_1LuCCxCdnFB4AZORs2yBBhMB",
  //           "subscription_item": "si_MdT9dOER5tE4Bu",
  //           "tax_amounts": [],
  //           "tax_rates": [],
  //           "type": "subscription",
  //           "unique_id": "il_1LuCCxCdnFB4AZORKbrKNi6y",
  //           "unit_amount_excluding_tax": "80000"
  //         }
  //       ],
  //       "has_more": false,
  //       "total_count": 1,
  //       "url": "/v1/invoices/in_1LuCCxCdnFB4AZORez2sv8Db/lines"
  //     },
  //     "livemode": false,
  //     "metadata": {},
  //     "next_payment_attempt": null,
  //     "number": "34AA8274-0001",
  //     "on_behalf_of": null,
  //     "paid": true,
  //     "paid_out_of_band": false,
  //     "payment_intent": {
  //       "id": "pi_1LuCCyCdnFB4AZORWL4dg3It",
  //       "object": "payment_intent",
  //       "amount": 80000,
  //       "amount_capturable": 0,
  //       "amount_details": {
  //         "tip": {}
  //       },
  //       "amount_received": 80000,
  //       "application": null,
  //       "application_fee_amount": null,
  //       "automatic_payment_methods": null,
  //       "canceled_at": null,
  //       "cancellation_reason": null,
  //       "capture_method": "automatic",
  //       "charges": {
  //         "object": "list",
  //         "data": [
  //           {
  //             "id": "ch_1LuCCyCdnFB4AZORhDrb0ceH",
  //             "object": "charge",
  //             "amount": 80000,
  //             "amount_captured": 80000,
  //             "amount_refunded": 0,
  //             "application": null,
  //             "application_fee": null,
  //             "application_fee_amount": null,
  //             "balance_transaction": "txn_1LuCCzCdnFB4AZORdmUMOiL3",
  //             "billing_details": {
  //               "address": {
  //                 "city": "dev",
  //                 "country": "US",
  //                 "line1": "dev",
  //                 "line2": null,
  //                 "postal_code": "42424",
  //                 "state": "dev"
  //               },
  //               "email": "gimocah686@finaltest.xxx",
  //               "name": "devtest",
  //               "phone": "555-555-5555"
  //             },
  //             "calculated_statement_descriptor": "GRUFITY.COM",
  //             "captured": true,
  //             "created": 1666085736,
  //             "currency": "inr",
  //             "customer": "cus_MdT9bXutIqZFJS",
  //             "description": "Subscription creation",
  //             "destination": null,
  //             "dispute": null,
  //             "disputed": false,
  //             "failure_balance_transaction": null,
  //             "failure_code": null,
  //             "failure_message": null,
  //             "fraud_details": {},
  //             "invoice": "in_1LuCCxCdnFB4AZORez2sv8Db",
  //             "livemode": false,
  //             "metadata": {},
  //             "on_behalf_of": null,
  //             "order": null,
  //             "outcome": {
  //               "network_status": "approved_by_network",
  //               "reason": null,
  //               "risk_level": "normal",
  //               "risk_score": 35,
  //               "seller_message": "Payment complete.",
  //               "type": "authorized"
  //             },
  //             "paid": true,
  //             "payment_intent": "pi_1LuCCyCdnFB4AZORWL4dg3It",
  //             "payment_method": "pm_1LuCCJCdnFB4AZOROPfWTDTs",
  //             "payment_method_details": {
  //               "card": {
  //                 "brand": "visa",
  //                 "checks": {
  //                   "address_line1_check": "pass",
  //                   "address_postal_code_check": "pass",
  //                   "cvc_check": "pass"
  //                 },
  //                 "country": "US",
  //                 "exp_month": 4,
  //                 "exp_year": 2024,
  //                 "fingerprint": "ix4QaPHePlqrWvg6",
  //                 "funding": "credit",
  //                 "installments": null,
  //                 "last4": "4242",
  //                 "mandate": null,
  //                 "network": "visa",
  //                 "three_d_secure": null,
  //                 "wallet": null
  //               },
  //               "type": "card"
  //             },
  //             "receipt_email": "gimocah686@finaltest.xxx",
  //             "receipt_number": null,
  //             "receipt_url": "https://pay.stripe.com/receipts/invoices/CAcaFwoVYWNjdF8xRmJROTNDZG5GQjRBWk9SKOrmuZoGMgZ0TXm5blk6LBa--CC7nlpMsIMB15BIqOwazR-ywC3n3SuC34ZdWv_dRohtME6bbfZ9zp3-?s=ap",
  //             "refunded": false,
  //             "refunds": {
  //               "object": "list",
  //               "data": [],
  //               "has_more": false,
  //               "total_count": 0,
  //               "url": "/v1/charges/ch_1LuCCyCdnFB4AZORhDrb0ceH/refunds"
  //             },
  //             "review": null,
  //             "shipping": null,
  //             "source": null,
  //             "source_transfer": null,
  //             "statement_descriptor": null,
  //             "statement_descriptor_suffix": null,
  //             "status": "succeeded",
  //             "transfer_data": null,
  //             "transfer_group": null
  //           }
  //         ],
  //         "has_more": false,
  //         "total_count": 1,
  //         "url": "/v1/charges?payment_intent=pi_1LuCCyCdnFB4AZORWL4dg3It"
  //       },
  //       "client_secret": "pi_1LuCCyCdnFB4AZORWL4dg3It_secret_LxiWo0ExHc31FlY9PhypHJnLp",
  //       "confirmation_method": "automatic",
  //       "created": 1666085736,
  //       "currency": "inr",
  //       "customer": "cus_MdT9bXutIqZFJS",
  //       "description": "Subscription creation",
  //       "invoice": "in_1LuCCxCdnFB4AZORez2sv8Db",
  //       "last_payment_error": null,
  //       "livemode": false,
  //       "metadata": {},
  //       "next_action": null,
  //       "on_behalf_of": null,
  //       "payment_method": "pm_1LuCCJCdnFB4AZOROPfWTDTs",
  //       "payment_method_options": {
  //         "card": {
  //           "installments": null,
  //           "mandate_options": null,
  //           "network": null,
  //           "request_three_d_secure": "automatic"
  //         }
  //       },
  //       "payment_method_types": [
  //         "card"
  //       ],
  //       "processing": null,
  //       "receipt_email": "gimocah686@finaltest.xxx",
  //       "review": null,
  //       "setup_future_usage": "off_session",
  //       "shipping": null,
  //       "source": null,
  //       "statement_descriptor": null,
  //       "statement_descriptor_suffix": null,
  //       "status": "succeeded",
  //       "transfer_data": null,
  //       "transfer_group": null
  //     },
  //     "payment_settings": {
  //       "default_mandate": null,
  //       "payment_method_options": null,
  //       "payment_method_types": null
  //     },
  //     "period_end": 1666085735,
  //     "period_start": 1666085735,
  //     "post_payment_credit_notes_amount": 0,
  //     "pre_payment_credit_notes_amount": 0,
  //     "quote": null,
  //     "receipt_number": null,
  //     "rendering_options": null,
  //     "starting_balance": 0,
  //     "statement_descriptor": null,
  //     "status": "paid",
  //     "status_transitions": {
  //       "finalized_at": 1666085735,
  //       "marked_uncollectible_at": null,
  //       "paid_at": 1666085735,
  //       "voided_at": null
  //     },
  //     "subscription": "sub_1LuCCxCdnFB4AZORs2yBBhMB",
  //     "subtotal": 80000,
  //     "subtotal_excluding_tax": 80000,
  //     "tax": null,
  //     "tax_percent": null,
  //     "test_clock": null,
  //     "total": 80000,
  //     "total_discount_amounts": [],
  //     "total_excluding_tax": 80000,
  //     "total_tax_amounts": [],
  //     "transfer_data": null,
  //     "webhooks_delivered_at": 1666085735
  //   },
  //   "livemode": false,
  //   "metadata": {},
  //   "next_pending_invoice_item_invoice": null,
  //   "pause_collection": null,
  //   "payment_settings": {
  //     "payment_method_options": null,
  //     "payment_method_types": null,
  //     "save_default_payment_method": "off"
  //   },
  //   "pending_invoice_item_interval": null,
  //   "pending_setup_intent": null,
  //   "pending_update": null,
  //   "plan": {
  //     "id": "price_1LsiQdCdnFB4AZORryoRdkxv",
  //     "object": "plan",
  //     "active": true,
  //     "aggregate_usage": null,
  //     "amount": 80000,
  //     "amount_decimal": "80000",
  //     "billing_scheme": "per_unit",
  //     "created": 1665732935,
  //     "currency": "inr",
  //     "interval": "month",
  //     "interval_count": 1,
  //     "livemode": false,
  //     "metadata": {},
  //     "nickname": "Monthly 800INR 10USD 10GBP 10EUR",
  //     "product": "prod_MbwJSX86fK2ydt",
  //     "tiers": null,
  //     "tiers_mode": null,
  //     "transform_usage": null,
  //     "trial_period_days": null,
  //     "usage_type": "licensed"
  //   },
  //   "quantity": 1,
  //   "schedule": null,
  //   "start_date": 1666085735,
  //   "status": "active",
  //   "tax_percent": null,
  //   "test_clock": null,
  //   "transfer_data": null,
  //   "trial_end": null,
  //   "trial_start": null
  // }
});
